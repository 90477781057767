import React, { useEffect, useState } from "react";
import EntityPage from "../components/EntityPage";
import { Box } from "@mui/material";
import BasicTabs, { CustomTabPanel } from "../common/components/Tabs";
import { CUSTOMER_LIST_TABS } from "../constants";
import { useLocation, useNavigate } from "react-router-dom";
import IndividualCustomerList from "../components/CustomerList/Individual";
import CorporateCustomerList from "../components/CustomerList/Corporate";

const CustomerList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const initialTab = searchParams.get("tab") || CUSTOMER_LIST_TABS[0].value;
  const [currentTab, setCurrentTab] = useState(initialTab);

  const handleTabChange = (newValue) => {
    const newTab = CUSTOMER_LIST_TABS[newValue].value;
    setCurrentTab(newTab);
    searchParams.set("tab", newTab);
    navigate({ search: searchParams.toString() });
  };

  return (
    <EntityPage title="Customer List" breadcrumbs>
      <Box>
        <BasicTabs
          list={CUSTOMER_LIST_TABS.map((tab) => tab.label)}
          setValue={handleTabChange}
          value={CUSTOMER_LIST_TABS.findIndex(
            (tab) => tab.value === currentTab
          )}
        />
        <CustomTabPanel
          value={CUSTOMER_LIST_TABS.findIndex(
            (tab) => tab.value === currentTab
          )}
          index={0}
        >
          <IndividualCustomerList />
        </CustomTabPanel>
        <CustomTabPanel
          value={CUSTOMER_LIST_TABS.findIndex(
            (tab) => tab.value === currentTab
          )}
          index={1}
        >
          <CorporateCustomerList />
        </CustomTabPanel>
      </Box>
    </EntityPage>
  );
};

export default CustomerList;
