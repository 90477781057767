import { CircleSharp } from "@mui/icons-material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MenuButton from "../components/Button/Menu";
import Capsule from "../components/Capsule";
import { IconRight } from "../components/CustomerDetails/MessageHistory";
import { USER_ROLES } from "../constants";
import { ORDERS_DETAILS } from "../constants/FrontendRoutes";
import { addToast } from "../redux/toastSlice";
import { mapAssignees } from "../utils/assignees";

import {
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import { fetchStoreUsers, getOrdersInfo } from "../api/assignees";
import UpdateStatus from "../common/components/StatusCapsule";
import CollapsibleRow from "../components/Order/CollapsibleRow";
import UsersRow from "../components/UsersRow";
import { selectCurrentStore } from "../redux/storeSlice";
import { calculateDaysRemaining, convertDateFormat } from "../utils";

const AssignedOrder = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [allOrders, setAllOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [sortingCriteria, setSortingCriteria] = useState("Order Date");
  const [daysRemainingByOrder, setDaysRemainingByOrder] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentStore = useSelector(selectCurrentStore);
  const storeId = currentStore?.store_id;

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const fetchOrders = async () => {
    try {
      if (currentStore) {
        const fields =
          "user.id,user.name,user.avatar_url,order_line_item.id,order_line_item.unique_identifier,order_line_item.details,order_line_item.line_item_type";
        const includes =
          "user,order_line_items,order_line_items.order_assignees.user,shipping_labels";
        const data = await getOrdersInfo(includes, fields, storeId);
        setAllOrders(data);
      }
    } catch (err) {
      dispatch(addToast(err.message || "Something went Wrong!"));
      console.error(err);
    }
  };

  const handleSortingAssignedOrders = (selectedAssigneeName) => {
    const selectedUser = usersData.find(
      (user) => user.name === selectedAssigneeName
    );
    if (selectedUser) {
      setSelectedAssignee(selectedUser.id);
    } else {
      setSelectedAssignee(null);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [currentStore]);

  useEffect(() => {
    if (allOrders.length > 0) {
      const now = new Date();
      const currentMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);
      const currentMonthEnd = new Date(
        now.getFullYear(),
        now.getMonth() + 1,
        0
      );
      const nextWeekStart = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);
      const nextWeekEnd = new Date(now.getTime() + 14 * 24 * 60 * 60 * 1000);

      let filtered = [];

      if (selectedTab === 0) {
        filtered = allOrders;
      } else if (selectedTab === 1) {
        filtered = allOrders.filter((order) => {
          const estimatedCompletion = new Date(order?.estimated_completion);
          return (
            estimatedCompletion >= currentMonthStart &&
            estimatedCompletion <= currentMonthEnd
          );
        });
      } else if (selectedTab === 2) {
        filtered = allOrders.filter((order) => {
          const estimatedCompletion = new Date(order?.estimated_completion);
          return (
            estimatedCompletion >= nextWeekStart &&
            estimatedCompletion <= nextWeekEnd
          );
        });
      }

      if (selectedAssignee) {
        filtered = filtered.filter((order) => {
          const orderAssignees = mapAssignees(order?.order_line_items);
          return orderAssignees.some((asg) =>
            asg.user_ids.includes(selectedAssignee)
          );
        });
      }

      const sortOrders = (sortingCriteria) => {
        return filtered.sort((a, b) => {
          let dateA, dateB;

          if (sortingCriteria === "Est. completion Date") {
            dateA = a?.estimated_completion
              ? new Date(a?.estimated_completion)
              : null;
            dateB = b?.estimated_completion
              ? new Date(b?.estimated_completion)
              : null;
          } else if (sortingCriteria === "Order Date") {
            dateA = a?.created_at ? new Date(a?.created_at) : null;
            dateB = b?.created_at ? new Date(b?.created_at) : null;
          }

          if (dateA === null && dateB === null) return 0;
          if (dateA === null) return 1;
          if (dateB === null) return -1;

          return dateA - dateB;
        });
      };

      const sortedOrders = sortOrders(sortingCriteria);

      const daysRemainingMap = {};
      sortedOrders.forEach((order) => {
        const remainingDays = calculateDaysRemaining(
          order?.estimated_completion
        );
        daysRemainingMap[order?.id] = remainingDays;
      });

      setDaysRemainingByOrder(daysRemainingMap);
      setFilteredOrders(sortedOrders);
    }
  }, [allOrders, selectedTab, selectedAssignee, sortingCriteria]);

  useEffect(() => {
    const fetchData = async () => {
      const roles = [USER_ROLES.MANAGER, USER_ROLES.REPAIR_TEAM];
      const data = await fetchStoreUsers(storeId, roles);
      setUsersData(data);
    };

    fetchData();
  }, []);

  const tableHeaderclasses = "font-questrial text-xs";

  const setOrder = (order) => {
    setAllOrders((prev) =>
      prev.map((o) =>
        o.id === order?.id ? { ...o, status: order?.status || o.status } : o
      )
    );
  };

  const displayCustomerInfo = (order) => {
    return (
      <div className="flex flex-col border-l border-gray-300 first:border-none pl-4">
        <div className="text-sm text-[#929291] mb-2">
          Customer #{order?.user?.id}
        </div>
        <div className="font-small">{order?.user?.name}</div>
      </div>
    );
  };

  const displayCreatedDate = (order) => {
    return (
      <div className="flex flex-col border-l border-gray-300 first:border-none pl-4">
        <div className="text-sm text-[#929291] mb-2">Created on</div>
        <div className="font-small">{convertDateFormat(order?.created_at)}</div>
      </div>
    );
  };

  const displayEstimatedDate = (order) => {
    const daysRemaining = daysRemainingByOrder[order?.id];
    return (
      <div className="flex flex-col lg:border-l lg:border-gray-300 pl-4 lg:first:border-none">
        <div className="text-sm text-[#939291] mb-2">Est. completion date:</div>
        {order?.estimated_completion && (
          <div className="flex font-small">
            {moment(order?.estimated_completion).format("MM/DD/YYYY")}
            {daysRemaining !== null && daysRemaining < 7 && (
              <div className={"text-sm ml-3 mt-1 text-red-600 "}>
                Due in {daysRemaining} day
                {daysRemaining !== 1 ? "s" : ""}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const displayAssignees = (order) => {
    const orderAssignees = mapAssignees(order?.order_line_items);
    const uniqueUsers = [
      ...new Map(
        orderAssignees
          .flatMap((item) => item.users)
          .map((user) => [user.id, user])
      ).values(),
    ];
    return (
      <div className="flex flex-col border-l border-gray-300 first:border-none pl-4">
        <div className="text-sm text-[#929291] mb-2">Assignee</div>
        <div className="font-small">
          <UsersRow users={uniqueUsers} checkCompletion={false} />
        </div>
      </div>
    );
  };

  return (
    <div className="bg-[#F8F8F8]">
      <div className="flex flex-col gap-5 max-w-full md:max-w-[1440px] mx-auto py-5">
        <div className="flex justify-between items-center px-5">
          <div className="text-zinc-900 text-2xl md:text-3xl font-bold font-['Montserrat'] leading-[33.60px]">
            Assigned Orders
          </div>
          <IconButton>
            <NotificationsNoneIcon sx={{ color: "#939291" }} />
          </IconButton>
        </div>

        <Paper className="w-full p-5 items-center justify-between">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{ marginBottom: 2 }}
          >
            <Tab label="All orders" />
            <Tab label="This month" />
            <Tab label="Next week" />
          </Tabs>

          <div className="flex gap-3 flex-wrap w-full">
            <MenuButton
              title="Sort by"
              variant="outlinedSecondary"
              options={["Order Date", "Est. completion Date"]}
              onChange={(value) => setSortingCriteria(value)}
              mobileIcon={false}
              desktopIconShow={true}
            />
            <MenuButton
              title="Assignee"
              variant="outlinedSecondary"
              options={["All", ...usersData.map((user) => user.name)]}
              onChange={(value) => handleSortingAssignedOrders(value)}
              mobileIcon={false}
              desktopIconShow={true}
              desktopIcon={<LocationOnOutlinedIcon />}
              mobileCustomIcon={<LocationOnOutlinedIcon />}
            />
          </div>

          {filteredOrders.length > 0 ? (
            filteredOrders.map((order) => {
              const daysRemaining = daysRemainingByOrder[order?.id];
              const orderAssignees = mapAssignees(order?.order_line_items);
              const uniqueUsers = [
                ...new Map(
                  orderAssignees
                    .flatMap((item) => item.users)
                    .map((user) => [user.id, user])
                ).values(),
              ];
              return (
                <div
                  key={order?.id}
                  className="border flex flex-col md:flex-row justify-between my-8 p-4 md:p-8 relative"
                >
                  <div className="min-w-[100%] md:min-w-[75%]">
                    <div className="flex space-x-5 mt-5 ml-5">
                      <Typography
                        variant="text-[#272523]"
                        className="text-lg md:text-xl font-semibold"
                      >
                        <span className="text-[#939291]">Order</span> #
                        {order?.id}
                      </Typography>
                      <UpdateStatus order={order} setOrder={setOrder} />
                      {order?.placement_type === "online" && (
                        <Capsule
                          textColor="text-[#4968A6]"
                          capsuleColor="bg-[#D8E1F8]"
                          text="Online order"
                          LeftIcon={
                            <CircleSharp
                              style={{ fontSize: "0.5rem" }}
                              className="text-[#4968A6]"
                            />
                          }
                        />
                      )}
                    </div>

                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-1 p-5 w-full">
                      {displayCustomerInfo(order)}
                      {displayCreatedDate(order)}
                      {displayEstimatedDate(order)}
                      {displayAssignees(order)}
                    </div>
                    <TableContainer className="border rounded-lg px-5">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className={tableHeaderclasses}>
                              <span className="text-[#939291]">
                                Assigned items
                              </span>
                            </TableCell>
                            <TableCell className={tableHeaderclasses}>
                              <span className="text-[#939291]">Assignees</span>
                            </TableCell>
                            <TableCell className={tableHeaderclasses}>
                              <span className="text-[#939291]">Mark Done</span>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order?.order_line_items?.map((item, index) => {
                            const isProduct = item.line_item_type === "product";
                            const {
                              name,
                              category,
                              categoryType,
                              description,
                              services,
                              options,
                            } = item.details;

                            const commonCollapsibleRowProps = {
                              index,
                              isProduct,
                              name,
                              category,
                              categoryType,
                              services,
                              options,
                              description,
                              assignments: mapAssignees(
                                order?.order_line_items
                              ),
                              lineItemId: item.id,
                              fetchOrder: fetchOrders,
                              collapsible: false,
                              orderId: order.id,
                              users: usersData,
                            };
                            return (
                              <React.Fragment key={item.id}>
                                <TableRow>
                                  <TableCell>
                                    <CollapsibleRow
                                      {...commonCollapsibleRowProps}
                                      showUsersRow={false}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <CollapsibleRow
                                      {...commonCollapsibleRowProps}
                                      showContent={false}
                                      displayIcons={false}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <CollapsibleRow
                                      {...commonCollapsibleRowProps}
                                      showContent={false}
                                      displayIcons={false}
                                      displayMarkDone={true}
                                    />
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>

                  <div className="flex items-center justify-center min-w-[100%] md:min-w-[25%]">
                    <div
                      onClick={() =>
                        navigate(ORDERS_DETAILS.replace(":id", order?.id))
                      }
                      className="cursor-pointer flex py-[16px] px-[10px] items-center gap-[8px] font-['Questrial'] text-[16px] font-normal underline text-[#272523]"
                    >
                      <div className="text-nowrap">View Details</div>
                      <IconRight />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="my-5 py-5">No orders found for this filter.</div>
          )}
        </Paper>
      </div>
    </div>
  );
};

export default AssignedOrder;
