export const OrderStatus = {
  QUOTE: "quote",
  NEW_ORDER: "new_order",
  SHIPPING: "shipping",
  IN_PROGRESS: "in_progress",
  DELAYED: "delayed",
  REPAIRED: "repaired",
  DELIVERED: "delivered",
};

export const OrderStatusMap = {
  [OrderStatus.QUOTE]: "Quote",
  [OrderStatus.NEW_ORDER]: "New Order",
  [OrderStatus.SHIPPING]: "Shipping",
  [OrderStatus.IN_PROGRESS]: "In Progress",
  [OrderStatus.DELAYED]: "Delayed",
  [OrderStatus.REPAIRED]: "Repaired",
  [OrderStatus.DELIVERED]: "Delivered",
};

export const initialStatuses = Object.keys(OrderStatus)
  .filter((key) => key !== "QUOTE")
  .map((key) => ({
    status: OrderStatus[key],
    subStatuses: [],
  }));

export const DEFAULT_STYLES = {
  bgColor: "gray-200",
  textColor: "gray-500",
  text: "Default",
};

export const STATUS_STYLES = {
  [OrderStatus.DELAYED]: {
    bgColor: "#f8e2c9",
    textColor: "#ca8a68",
    text: "Delayed",
  },
  [OrderStatus.IN_PROGRESS]: {
    bgColor: "#d8ede0",
    textColor: "#5a8a6e",
    text: "In progress",
  },
  [OrderStatus.SHIPPING]: {
    bgColor: "#e8daf8",
    textColor: "#6a549e",
    text: "Shipping",
  },
  [OrderStatus.NEW_ORDER]: {
    bgColor: "#f3f0e8",
    textColor: "#bf975f",
    text: "New Order",
  },
  [OrderStatus.REPAIRED]: {
    bgColor: "#e8e8e8",
    textColor: "#272523",
    text: "Repaired",
  },
  [OrderStatus.QUOTE]: {
    bgColor: "#e8e8e8",
    textColor: "#272523",
    text: "Quote",
  },
  [OrderStatus.DELIVERED]: {
    bgColor: "#e8e8e8",
    textColor: "#272523",
    text: "Delivered",
  },
  early_adopters_price: {
    bgColor: "orange-100",
    textColor: "orange-500",
    text: "Early adopters price!",
  },
  new_customer: {
    bgColor: "#D8EDE0",
    textColor: "#5A8A6E",
    text: "New customer",
  },
  open: {
    bgColor: "#D8EDE0",
    textColor: "#5A8A6E",
    text: "Unpaid",
  },
  paid: {
    bgColor: "#D8EDE0",
    textColor: "#5A8A6E",
    text: "Paid",
  },
  overdue: {
    bgColor: "#D8EDE0",
    textColor: "#5A8A6E",
    text: "Past due",
  },
};
