import { isEmpty } from "lodash";
import assigneesColors from "../constants/AssigneesColors";

const mapAssignableItems = (items, assignableType, orderAssignees) => {
  return items.map((item) => {
    const assignees = orderAssignees.filter(
      (assignee) =>
        assignee.assignable_type === assignableType &&
        assignee.assignable_id === item.id
    );

    const user_ids = assignees.map((assignee) => assignee.user.id);
    const users = assignees.map((assignee) => ({
      id: assignee.user.id,
      name: assignee.user.name,
      avatar_url: assignee.user.avatar_url,
      is_completed: assignee.is_completed,
      orderAssigneeId: assignee.id,
    }));

    return {
      id: item.id,
      user_ids,
      users,
    };
  });
};

const mapProducts = (details, orderAssignees) => {
  const product = {
    id: details.id,
  };

  const assignees = orderAssignees.filter(
    (assignee) =>
      assignee.assignable_type === "Product" &&
      assignee.assignable_id === details.id
  );

  const user_ids = assignees.map((assignee) => assignee.user.id);

  return [
    {
      ...product,
      user_ids,
    },
  ];
};

const assignColors = (data) => {
  const totalColors = assigneesColors.length;
  const userColorsMap = {};

  const allUsers = data.flatMap(item => [
    ...(item.users || []),
    ...(item.services || []).flatMap(service => service.users || []),
    ...(item.options || []).flatMap(option => option.users || []),
    ...(item.products || []).flatMap(product => product.users || []),
  ]);

  const uniqueUserIds = [...new Set(allUsers.map(user => user.id))].sort((a, b) => a - b);

  uniqueUserIds.forEach((userId, index) => {
    userColorsMap[userId] = assigneesColors[index % totalColors];
  });

  allUsers.forEach((user) => {
    const colorPair = userColorsMap[user.id];
    user.backgroundColor = colorPair.background;
    user.color = colorPair.color;
  });

  return data;
};

export const mapAssignees = (data) => {
  const assignees = data?.map((orderLineItem) => {
    const {
      id: order_line_item_id,
      line_item_type,
      details,
      order_assignees,
    } = orderLineItem;
    const type = details.type;

    let mappedServices = [];
    let mappedOptions = [];
    let mappedProducts = [];

    const line_item_user_ids = [
      ...new Set(order_assignees.map((assignee) => assignee.user.id)),
    ];

    const userMap = new Map();

    order_assignees.forEach((item) => {
      const userId = item.user.id;
      const assignment = {
        assignable_id: item.assignable_id,
        assignable_type: item.assignable_type,
        id: item.id,
        is_completed: item.is_completed,
      };

      if (!userMap.has(userId)) {
        userMap.set(userId, {
          id: item.user.id,
          name: item.user.name,
          avatar_url: item.user.avatar_url,
          is_completed: item.is_completed,
          assignments: [assignment],
        });
      } else {
        const userEntry = userMap.get(userId);
        userEntry.is_completed = userEntry.is_completed && item.is_completed;
        userEntry.assignments.push(assignment);
      }
    });

    const assignees = Array.from(userMap.values());

    if (line_item_type === "service") {
      const { services = [], options = [] } = details;

      mappedServices = mapAssignableItems(services, "Service", order_assignees);

      mappedOptions = mapAssignableItems(options, "Option", order_assignees);
    } else if (line_item_type === "product") {
      mappedProducts = mapProducts(details, order_assignees);
    }

    return {
      users: assignees,
      order_line_item_id,
      user_ids: line_item_user_ids,
      type,
      services: mappedServices,
      options: mappedOptions,
      products: mappedProducts,
    };
  });
  const updatedData = assignColors(assignees);
  return updatedData;
};

export const getUserIds = (orderLineItem, type, itemId) => {
  if (!orderLineItem) return [];
  let itemsArray = [];
  if (type === "service") {
    itemsArray = orderLineItem.services || [];
  } else if (type === "option") {
    itemsArray = orderLineItem.options || [];
  } else if (type === "product") {
    itemsArray = orderLineItem.products || [];
  } else {
    return [];
  }

  const item = itemsArray.find((item) => item.id === itemId);
  if (!item) {
    return [];
  }

  return item.user_ids;
};

export const updateAssignments = (
  assignments,
  orderLineItemId,
  type,
  assignableId,
  selectedUsers
) => {
  const updateAssignableItems = (items, selectedUsers) => {
    return items?.map((item) => ({ ...item, user_ids: selectedUsers })) || [];
  };

  const updateOrderLineItem = (orderLineItem, selectedUsers) => {
    return {
      ...orderLineItem,
      user_ids: selectedUsers,
      services: updateAssignableItems(orderLineItem.services, selectedUsers),
      options: updateAssignableItems(orderLineItem.options, selectedUsers),
      products: updateAssignableItems(orderLineItem.products, selectedUsers),
    };
  };

  const collectUserIdsFromItems = (
    orderLineItem,
    updatedTypeKey,
    updatedItems
  ) => {
    const lineItemUserIds = new Set();

    ["services", "options", "products"].forEach((key) => {
      if (orderLineItem[key]) {
        const items =
          key === updatedTypeKey ? updatedItems : orderLineItem[key];
        items.forEach((item) => {
          item.user_ids.forEach((userId) => lineItemUserIds.add(userId));
        });
      }
    });

    return Array.from(lineItemUserIds);
  };

  const updateAssignableItem = (
    orderLineItem,
    typeKey,
    assignableId,
    selectedUsers
  ) => {
    if (!orderLineItem[typeKey]) {
      return orderLineItem;
    }

    const updatedItems = orderLineItem[typeKey].map((item) =>
      item.id !== assignableId ? item : { ...item, user_ids: selectedUsers }
    );

    return {
      ...orderLineItem,
      [typeKey]: updatedItems,
      user_ids: collectUserIdsFromItems(orderLineItem, typeKey, updatedItems),
    };
  };

  if (type === "order") {
    return assignments.map((orderLineItem) =>
      updateOrderLineItem(orderLineItem, selectedUsers)
    );
  }

  return assignments.map((orderLineItem) => {
    if (orderLineItem.order_line_item_id !== orderLineItemId) {
      return orderLineItem;
    }

    if (type === "order_line_item") {
      return updateOrderLineItem(orderLineItem, selectedUsers);
    }

    const typeKey = `${type}s`;
    return updateAssignableItem(
      orderLineItem,
      typeKey,
      assignableId,
      selectedUsers
    );
  });
};

export const mapUsers = (users, currentUserId) => {
  const mappedUsers = users?.map((user) => {
    return {
      id: user?.id,
      label: user?.id === currentUserId ? `${user?.name} (You)` : user?.name,
    };
  });
  return mappedUsers;
};

export const getUniqueAssignees = (data) => {
  return data.reduce((accumulator, current) => {
    if (!accumulator.some((user) => user.id === current.user.id)) {
      accumulator.push(current.user);
    }
    return accumulator;
  }, []);
};

export const filterAssignees = (orders, selectedAssignee) => {
  return orders?.filter((order) => {
    const orderAssignees = getUniqueAssignees(order?.order_assignees);
    return orderAssignees.some((os) => os?.id === selectedAssignee?.id);
  });
};

export const cleanAssignmentsForPosting = (assignments) => {
  return assignments.map((assignment) => {
    const { user_ids, users, ...rest } = assignment;

    const cleanServices = assignment.services?.map((service) => {
      const { users, ...serviceRest } = service;
      return serviceRest;
    });

    const cleanOptions = assignment.options?.map((option) => {
      const { users, ...optionRest } = option;
      return optionRest;
    });

    const cleanProducts = assignment.products?.map((product) => {
      const { users, ...productRest } = product;
      return productRest;
    });

    return {
      ...rest,
      services: cleanServices,
      options: cleanOptions,
      products: cleanProducts,
    };
  });
};

const compareUserIds = (prevUserIds = [], currUserIds = []) => {
  const userIdsToAdd = currUserIds.filter((id) => !prevUserIds.includes(id));
  const userIdsToRemove = prevUserIds.filter((id) => !currUserIds.includes(id));
  return { userIdsToAdd, userIdsToRemove };
};

export const generateAssignmentsDifferences = (
  previousOrderAssignments = [],
  currentOrderAssignments = []
) => {
  const prevAssignmentsMap = previousOrderAssignments.reduce(
    (map, assignment) => {
      map[assignment.order_line_item_id] = assignment;
      return map;
    },
    {}
  );

  const currAssignmentsMap = currentOrderAssignments.reduce(
    (map, assignment) => {
      map[assignment.order_line_item_id] = assignment;
      return map;
    },
    {}
  );

  const allOrderLineItemIds = new Set([
    ...Object.keys(prevAssignmentsMap),
    ...Object.keys(currAssignmentsMap),
  ]);

  const result = [];

  for (const orderId of allOrderLineItemIds) {
    const prevAssignment = prevAssignmentsMap[orderId] || {
      services: [],
      options: [],
      products: [],
      type: null,
    };
    const currAssignment = currAssignmentsMap[orderId] || {
      services: [],
      options: [],
      products: [],
      type: null,
    };

    const newAssignment = {
      order_line_item_id: parseInt(orderId),
      type: currAssignment.type || prevAssignment.type,
      services: [],
      options: [],
      products: [],
    };

    const buildIdMap = (items) =>
      items.reduce((map, item) => {
        map[item.id] = item.user_ids;
        return map;
      }, {});

    const prevServicesMap = buildIdMap(prevAssignment.services);
    const currServicesMap = buildIdMap(currAssignment.services);
    const allServiceIds = new Set([
      ...Object.keys(prevServicesMap),
      ...Object.keys(currServicesMap),
    ]);

    for (const serviceId of allServiceIds) {
      const prevUserIds = prevServicesMap[serviceId] || [];
      const currUserIds = currServicesMap[serviceId] || [];
      const { userIdsToAdd, userIdsToRemove } = compareUserIds(
        prevUserIds,
        currUserIds
      );

      if (!isEmpty(userIdsToAdd) || !isEmpty(userIdsToRemove)) {
        const serviceDiff = { id: parseInt(serviceId) };
        if (!isEmpty(userIdsToAdd)) {
          serviceDiff.user_ids_to_add = userIdsToAdd;
        }
        if (!isEmpty(userIdsToRemove)) {
          serviceDiff.user_ids_to_remove = userIdsToRemove;
        }
        newAssignment.services.push(serviceDiff);
      }
    }

    const prevOptionsMap = buildIdMap(prevAssignment.options);
    const currOptionsMap = buildIdMap(currAssignment.options);
    const allOptionIds = new Set([
      ...Object.keys(prevOptionsMap),
      ...Object.keys(currOptionsMap),
    ]);

    for (const optionId of allOptionIds) {
      const prevUserIds = prevOptionsMap[optionId] || [];
      const currUserIds = currOptionsMap[optionId] || [];
      const { userIdsToAdd, userIdsToRemove } = compareUserIds(
        prevUserIds,
        currUserIds
      );

      if (!isEmpty(userIdsToAdd) || !isEmpty(userIdsToRemove)) {
        const optionDiff = { id: parseInt(optionId) };
        if (!isEmpty(userIdsToAdd)) {
          optionDiff.user_ids_to_add = userIdsToAdd;
        }
        if (!isEmpty(userIdsToRemove)) {
          optionDiff.user_ids_to_remove = userIdsToRemove;
        }
        newAssignment.options.push(optionDiff);
      }
    }

    const prevProductsMap = buildIdMap(prevAssignment.products);
    const currProductsMap = buildIdMap(currAssignment.products);
    const allProductIds = new Set([
      ...Object.keys(prevProductsMap),
      ...Object.keys(currProductsMap),
    ]);

    for (const productId of allProductIds) {
      const prevUserIds = prevProductsMap[productId] || [];
      const currUserIds = currProductsMap[productId] || [];
      const { userIdsToAdd, userIdsToRemove } = compareUserIds(
        prevUserIds,
        currUserIds
      );

      if (!isEmpty(userIdsToAdd) || !isEmpty(userIdsToRemove)) {
        const productDiff = { id: parseInt(productId) };
        if (!isEmpty(userIdsToAdd)) {
          productDiff.user_ids_to_add = userIdsToAdd;
        }
        if (!isEmpty(userIdsToRemove)) {
          productDiff.user_ids_to_remove = userIdsToRemove;
        }
        newAssignment.products.push(productDiff);
      }
    }

    if (
      !isEmpty(newAssignment.services) ||
      !isEmpty(newAssignment.options) ||
      !isEmpty(newAssignment.products)
    ) {
      result.push(newAssignment);
    }
  }

  return result;
};
