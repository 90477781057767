import React from "react";
import { Link } from "react-router-dom";
import { Divider, Typography } from "@mui/material";
import { CircleSharp } from "@mui/icons-material";
import Capsule from "../Capsule";
import { ORDERS_DETAILS } from "../../constants/FrontendRoutes";
import theme from "../../theme";
import { RushOrderIcon } from "../../common/icons";

const OrderDetailsCard = ({
  order = {},
  isShipping = false,
  shippingLabels,
}) => {
  const user = order.user;

  const getCategoryAndLineItemCount = (order) => {
    return order.line_item_summary;
  };

  return (
    <Link to={ORDERS_DETAILS.replace(":id", order?.id)}>
      <div className="border rounded-md">
        <div className="p-4 flex flex-col gap-2 shadow-md">
          {order.placement_type === "online" && (
            <Capsule
              textColor="text-[#4968A6]"
              capsuleColor="bg-[#D8E1F8]"
              text="Online order"
              LeftIcon={
                <CircleSharp
                  style={{ fontSize: "0.5rem" }}
                  className="text-[#4968A6]"
                />
              }
            />
          )}
          <div className="flex flex-row gap-2">
            {order.rush_fee && order.rush_fee !== 0 ? <RushOrderIcon /> : <></>}
            <Typography style={theme.typography.info}>
              Order ID: {order.id}
            </Typography>
          </div>
          <Typography style={theme.typography.info}>
            Name: {user.name}
          </Typography>
          <Typography style={theme.typography.info}>
            Items: {getCategoryAndLineItemCount(order)}
          </Typography>
          <Divider className="px-2" />
          {isShipping ? (
            <div className="flex flex-col">
              <Typography style={theme.typography.info}>Tracking #</Typography>
              <Typography style={theme.typography.info}>
                {shippingLabels?.length > 0
                  ? shippingLabels[shippingLabels?.length - 1]?.tracking_number
                  : "-------"}
              </Typography>
            </div>
          ) : (
            <div className="w-full flex flex-row justify-between">
              <Typography style={theme.typography.info}>Est. total:</Typography>
              <Typography style={theme.typography.info}>
                ${order.net_total}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default OrderDetailsCard;
