import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    borderRadius: "8px",
    padding: "8px 12px",
    border: "1px solid #e0e0e0",
  },
  [`& .MuiTooltip-arrow`]: {
    color: "#fff",
    "&:before": {
      border: "1px solid #e0e0e0",
    },
  },
}));

export default CustomTooltip;
