import { Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import EntityPage from "../components/EntityPage";
import AutoCompleteInput from "../components/Form/Field/AutoComplete";
import TextInput from "../components/Form/Field/TextInput";
import FieldGroup from "../components/Form/FieldGroup";
import FieldItem from "../components/Form/FieldItem";
import FormPage from "../components/Form/FormPage";

import { getRequest, updateRequest } from "../api";
import { COMMON_STATUS } from "../constants";

import { addToast } from "../redux/toastSlice";
import { selectAllCategories, selectCurrentStore } from "../redux/storeSlice";

const EditCategoryType = () => {
  const { id } = useParams();
  const [categoryType, setCategoryType] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentStore = useSelector(selectCurrentStore);
  const allCategories = useSelector(selectAllCategories);

  useEffect(() => {
    if (currentStore) fetchCategoryTypeData();
  }, [currentStore]);

  const fetchCategoryTypeData = async () => {
    try {
      const res = await getRequest(
        `stores/${currentStore?.store_id}/sub_categories/${id}`
      );
      if (res) setCategoryType(res);
    } catch (error) {
      dispatch(
        addToast(error || "Failed to fetch category type data.")
      );
    }
  };

  const handleInputChange = (name, value) => {
    setCategoryType((prevCategoryType) => ({
      ...prevCategoryType,
      [name]: value,
    }));
  };

  const handleUpdateCategoryType = async () => {
    try {
      const { category, name, status } = categoryType;
      const res = await updateRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stores/${currentStore?.store_id}/sub_categories/${id}`,
        { sub_category: { category, name, status } }
      );

      if (res.status === 200) {
        navigate(-1);
        dispatch(addToast("Category type updated successfully."));
      } else {
        dispatch(
          addToast("Failed to update category type.")
        );
      }
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  if (!categoryType) return null;

  return (
    <EntityPage title="Edit Category Type" breadcrumbs stickyBottomBar>
      <FormPage>
        <FieldGroup label="Category Type Details">
          <FieldItem>
            <Typography width="30%" variant="body">
              Main Category
            </Typography>
            <AutoCompleteInput
              label="Please select"
              name="category"
              options={allCategories?.map((item) => ({
                title: item?.name,
                value: item?.name,
              }))}
              value={{
                title: categoryType.category,
                value: categoryType.category,
              }}
              onChange={(_e, value) =>
                handleInputChange("category", value.value)
              }
            />
          </FieldItem>

          <FieldItem>
            <Typography width="30%" variant="body">
              Category Type Name
            </Typography>
            <TextInput
              name="name"
              value={categoryType.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
          </FieldItem>

          <FieldItem>
            <Typography width="30%" variant="body">
              Status
            </Typography>
            <AutoCompleteInput
              label="Please select"
              options={Object.entries(COMMON_STATUS).map(([key, value]) => ({
                title: value,
                value: key,
              }))}
              value={{
                title: COMMON_STATUS[categoryType.status],
                value: categoryType.status,
              }}
              onChange={(_e, value) => handleInputChange("status", value.value)}
            />
          </FieldItem>
        </FieldGroup>

        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "16px 0",
            display: "flex",
            flexDirection: "row-reverse",
            gap: "28px",
          }}
          elevation={8}
        >
          <div className="flex flex-row gap-3 mx-5">
            <Button variant="outlinedPrimary" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              variant="containedPrimary"
              onClick={handleUpdateCategoryType}
            >
              Update
            </Button>
          </div>
        </Paper>
      </FormPage>
    </EntityPage>
  );
};

export default EditCategoryType;
