import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { Button, Paper, Typography, Checkbox } from "@mui/material";

import EntityPage from "../components/EntityPage";
import FormPage from "../components/Form/FormPage";
import FieldItem from "../components/Form/FieldItem";
import TextInput from "../components/Form/Field/TextInput";
import FieldGroup from "../components/Form/FieldGroup";
import AutoCompleteInput from "../components/Form/Field/AutoComplete";
import AddressSection from "../components/Form/Address";
import {
  ADDRESS_TYPE_ENUM,
  BILLING_CYCLE,
  BILLING_METHOD_OPTIONS,
  USER_ROLES_ENUM,
} from "../constants";
import { isObjectEmpty, isObjectFieldsFilled, isObjectPartiallyFilled } from "../utils";
import { addToast } from "../redux/toastSlice";
import { setLoading } from "../redux/loadingSlice"
import { postRequest } from "../api";
import { useSelector } from "react-redux";
import { selectCurrentStore } from "../redux/storeSlice";
import { transformAddress } from "./AddNewCustomer";


const INITIAL_CORPORATION = {
  name: "",
  email: "",
  shipping_address: {
    line: "",
    zipcode: "",
    city: "",
    state: "",
    country: "",
    address_type: ADDRESS_TYPE_ENUM.shipping,
  },
  contact_person: {
    name: "",
    email: "",
    phone_number: "",
  },
  billing_setup: {
    billing_cycle: "",
    billing_method: "",
    only_completed_orders: false,
    additional_notes: "",
  },
};

const transformCorporateInput = (corporateInput) => {
  const { name, email, shipping_address, contact_person } = corporateInput;
  const transformedInput = { name, email, role: USER_ROLES_ENUM.corporate };
  if (isObjectFieldsFilled(contact_person))
    transformedInput.contacts_attributes = [{ ...contact_person }];
  if (isObjectFieldsFilled(shipping_address))
    transformedInput.addresses_attributes = [
      transformAddress(shipping_address, "shipping"),
    ];

  return transformedInput;
};


const AddNewCorporation = () => {
  const [corporation, setCorporation] = useState({ ...INITIAL_CORPORATION });

  const dispatch = useDispatch();
  const currentStore = useSelector(selectCurrentStore);
  const navigate = useNavigate();

  const handleInputChange = (section, name, value) => {
    if (section === "basic") {
      setCorporation((prevCorporation) => ({
        ...prevCorporation,
        [name]: value,
      }));
    } else {
      setCorporation((prevCorporation) => {
        const updatedCorporation = {
          ...prevCorporation,
          [section]: {
            ...prevCorporation[section],
            [name]: value,
          },
        };

        if (name === "country") {
          updatedCorporation[section].zipcode = "";
          updatedCorporation[section].city = "";
          updatedCorporation[section].state = "";
        }

        return updatedCorporation;
      });
    }
  };

  const isFormInvalid = () => {
    const { name, email, shipping_address, billing_setup, contact_person } = corporation;

    if (
      !isObjectFieldsFilled({
        name,
        email,
        shipping_address,
        billing_setup: {
          billing_cycle: billing_setup.billing_cycle,
          payment_method: billing_setup.billing_method,
        },
      }) ||
      isObjectPartiallyFilled(contact_person)
    )
      return true;
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    try {
      const { status, data } = await createCorporateUser();
      if (status === 201) {
        dispatch(addToast("Corporate user added successfully."));
        await createBillingPreference(data?.id);
      }
    } catch (error) {
      dispatch(addToast(error.message || "An error occurred."));
    } finally {
      dispatch(setLoading(false));
      navigate(-1);
    }
  };

  const createCorporateUser = async () => {
    return await postRequest(`stores/${currentStore?.store_id}/users`, {
      user: transformCorporateInput(corporation),
    });
  };

  const createBillingPreference = async (userId) => {
    const { status } = await postRequest(
      `stores/${currentStore?.store_id}/users/${userId}/billing_preferences`,
      { billing_preference: corporation.billing_setup }
    );
    if (status === 201) dispatch(addToast("Billing preference added successfully."));
  };


  return (
    <EntityPage title="Add a new corporation" breadcrumbs stickyBottomBar>
      <FormPage onSubmit={handleSubmit}>
        <FieldGroup label="Basic Information" required>
          <FieldItem>
            <Typography width="30%" variant="body">
              Corporation name
            </Typography>
            <TextInput
              value={corporation.name}
              onChange={(e) =>
                handleInputChange("basic", "name", e.target.value)
              }
              placeholder="Please enter"
              required
            />
          </FieldItem>
          <FieldItem>
            <Typography width="30%" variant="body">
              Corporation email
            </Typography>
            <TextInput
              value={corporation.email}
              onChange={(e) =>
                handleInputChange("basic", "email", e.target.value)
              }
              placeholder="Please enter"
              required
              type="email"
            />
          </FieldItem>
          <AddressSection
            section="shipping_address"
            address={corporation.shipping_address}
            handleInputChange={handleInputChange}
            required
          />
        </FieldGroup>

        <hr />

        <FieldGroup label="Contact Information">
          <FieldItem>
            <Typography width="30%" variant="body">
              Contact person
            </Typography>
            <TextInput
              value={corporation?.contact_person?.name}
              onChange={(e) =>
                handleInputChange("contact_person", "name", e.target.value)
              }
              placeholder="John Doe"
            />
          </FieldItem>
          <FieldItem>
            <Typography width="30%" variant="body">
              Email address
            </Typography>
            <TextInput
              value={corporation?.contact_person?.email}
              onChange={(e) =>
                handleInputChange("contact_person", "email", e.target.value)
              }
              placeholder="john.doe@example.com"
              type="email"
            />
          </FieldItem>
          <FieldItem>
            <Typography width="30%" variant="body">
              Phone number
            </Typography>
            <InputMask
              mask="(999) 999-9999"
              value={corporation?.contact_person?.phone_number}
              onChange={(e) =>
                handleInputChange(
                  "contact_person",
                  "phone_number",
                  e.target.value
                )
              }
            >
              {() => (
                <TextInput
                  id="phone_number"
                  name="phone_number"
                  type="tel"
                  placeholder="(123) 456-7890"
                  variant="outlined"
                />
              )}
            </InputMask>
          </FieldItem>
        </FieldGroup>

        <hr />

        <FieldGroup label="Billing Details" required>
          <FieldItem>
            <Typography width="30%" variant="body">
              Billing cycle
            </Typography>
            <AutoCompleteInput
              required
              label="Select cycle"
              options={BILLING_CYCLE}
              value={
                BILLING_CYCLE.find(
                  (cycle) =>
                    cycle?.value === corporation?.billing_setup?.billing_cycle
                ) || null
              }
              onChange={(_e, obj) =>
                handleInputChange("billing_setup", "billing_cycle", obj?.value)
              }
            />
          </FieldItem>
          <FieldItem>
            <Typography width="30%" variant="body">
              Payment method
            </Typography>
            <AutoCompleteInput
              required
              label="Select method"
              options={BILLING_METHOD_OPTIONS}
              value={
                BILLING_METHOD_OPTIONS.find(
                  (method) =>
                    method?.value ===
                    corporation?.billing_setup?.billing_method
                ) || null
              }
              onChange={(_e, obj) =>
                handleInputChange(
                  "billing_setup",
                  "billing_method",
                  obj?.value
                )
              }
            />
          </FieldItem>
          <FieldItem>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Checkbox
                sx={{
                  ml: { lg: "150px", xs: "0px" },
                }}
                checked={corporation?.billing_setup?.only_completed_orders}
                onChange={(e) =>
                  handleInputChange(
                    "billing_setup",
                    "only_completed_orders",
                    e.target.checked
                  )
                }
                color="primary"
              />
              <Typography>Charge for Completed Orders Only</Typography>
            </div>
          </FieldItem>
        </FieldGroup>

        <hr />

        <FieldGroup>
          <FieldItem>
            <Typography width="30%" variant="body">
              Notes
            </Typography>
            <TextInput
              value={corporation?.billing_setup?.additional_notes}
              onChange={(e) =>
                handleInputChange(
                  "billing_setup",
                  "additional_notes",
                  e.target.value
                )
              }
              placeholder="Enter any notes"
              multiline
            />
          </FieldItem>
        </FieldGroup>

        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "16px 0",
            display: "flex",
            flexDirection: "row-reverse",
            gap: "28px",
          }}
          elevation={8}
        >
          <div className="flex flex-row gap-3 mx-5">
            <Button variant="outlinedPrimary" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              variant="containedPrimary"
              disabled={isFormInvalid()}
              type="submit"
            >
              Create
            </Button>
          </div>
        </Paper>
      </FormPage>
    </EntityPage>
  );
};

export default AddNewCorporation;
