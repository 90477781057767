import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../../../images/Logo.svg";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { CUSTOMER_DASHBOARD_TAB_LIST } from "../../../constants";
import { logout } from "../../../redux/authActions";
import { useDispatch } from "react-redux";
import { CUSTOMER_ROUTES } from "../../../constants/FrontendRoutes";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = (open) => () => setIsOpen(open);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const list = () => (
    <div className="w-full" role="presentation" onKeyDown={toggleDrawer(false)}>
      <div className="flex items-center justify-between p-4">
        <img alt="logo" src={Logo} className="w-7 lg:w-8 h-auto" />
        <IconButton onClick={toggleDrawer(false)} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <List>
        {CUSTOMER_DASHBOARD_TAB_LIST.map((tab, index) => (
          <Link
            key={index}
            to={tab.route}
            onClick={toggleDrawer(false)}
            className="block"
          >
            <ListItem
              className={`text-base font-normal font-['Questrial'] leading-snug ${
                location.pathname === tab.route
                  ? "text-[#4C8C4A]"
                  : "text-[#272523]"
              }`}
            >
              <ListItemText primary={tab.label} />
            </ListItem>
          </Link>
        ))}
        <ListItem className="text-base font-normal font-['Questrial'] leading-snug">
          <ListItemText
            primary="Setting"
            onClick={() => {
              toggleDrawer(false)();
              navigate(CUSTOMER_ROUTES.MY_ACCOUNT);
            }}
          />
        </ListItem>
        <ListItem className="text-base font-normal font-['Questrial'] leading-snug">
          <ListItemText primary="Logout" onClick={() => dispatch(logout())} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <IconButton onClick={toggleDrawer(true)} aria-label="open">
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: "100%",
          },
        }}
      >
        {list()}
      </Drawer>
    </div>
  );
};

export default Sidebar;
