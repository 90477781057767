import {
  Box,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
  getRequest,
} from "../api";
import BasicTabs, { CustomTabPanel } from "../common/components/Tabs";
import { TruckIcon } from "../common/icons";
import { CUSTOMER_ORDER_DETAILS_TAB_LIST } from "../constants";
import {
  ORDERS,
  STORES
} from "../constants/BackendRoutes";
import { selectCurrentUser } from "../redux/authSlice";
import { addToast } from "../redux/toastSlice";
import {
  formatDate,
  capitalize
} from "../utils";
import OrderStatus from "../components/CustomerDetails/Orders/OrderStatus";
import OrderDetails from "../components/CustomerDetails/Orders/Details";
import Capsule from "../components/Capsule";
import { STATUS_STYLES } from "../constants/OrderStatus";

const CustomerOrderDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const [order, setOrder] = useState({});
  const [currentTab, setCurrentTab] = useState(CUSTOMER_ORDER_DETAILS_TAB_LIST[0].value);

  const storeId = currentUser?.stores_as_customer?.[0]?.store_id;
  const ordersBase = `${STORES}/${storeId}${ORDERS}`;

  const fetchOrder = async () => {
    try {
      const fields =
        "store.phone_number,order_line_item.id,order_line_item.unique_identifier,order_line_item.details,order_line_item.line_item_type";
      const data = await getRequest(
        `${ordersBase}/${id}`,
        {},
        `sub_statuses,store,address,discount,user.addresses,order_line_items,shipping_labels&fields=${fields}`
      );
      setOrder(data);
    } catch (error) {
      dispatch(addToast(error.message || "Something went Wrong!"));
      console.error("Error fetching order:", error);
    }
  };

  const handleTabChange = (newValue) => {
    const newTab = CUSTOMER_ORDER_DETAILS_TAB_LIST[newValue].value;
    setCurrentTab(newTab);
  };

  useEffect(() => {
    if (currentUser) {
      fetchOrder();
    }
  }, [currentUser]);
  return (
    <Box className="flex flex-col items-center gap-7">
      <div className="flex flex-col max-w-[1550px] w-full p-5 gap-5 md:bg-transparent bg-white">
        <div className="flex gap-2">
          <Typography variant="text-[#272523] font-montserrat text-lg font-semibold">
            Order #{order?.id}
          </Typography>
          <Capsule
            text={capitalize(order?.status || '')}
            capsuleColor={`bg-[${STATUS_STYLES[order?.status]?.bgColor}]`}
            textColor={`text-[${STATUS_STYLES[order?.status]?.textColor}]`}
            />
          {order?.sub_statuses?.filter(ss => ss?.order_status === order?.status)?.map((subStatus) => (
            <Capsule
              key={subStatus?.id}
              textColor="text-[#272523]"
              capsuleColor="bg-[#E8E8E8]"
              text={subStatus?.name}
            />
          ))}
        </div>
        <hr className="md:hidden inline"></hr>
        <div className="flex flex-col md:flex-row gap-5">
          <div>
            <span className="font-questrial text-[16px] font-normal leading-[22.4px] text-left text-[#939291]">Order Date: </span> 
            <span className="font-['Montserrat'] text-[18px] font-semibold leading-[25.2px] text-left text-[#1D2939]">{formatDate(order.created_at)}</span>
          </div>
          {(order?.status !== 'quote' && order?.status !== 'delivered') && <span className="hidden md:inline text-[#939291]">|</span>}
          {(order?.status !== 'quote' && order?.status !== 'delivered') && <div className="flex flex-row gap-2">
            <TruckIcon style={{
              marginTop: "2px"
            }}/>
            <div className="text-[#12B76A]">Estimated delivery: {formatDate(order.estimated_completion)}</div>
          </div>}
        </div>
      </div>
      <div className="bg-white md:bg-transparent max-w-[1550px] w-full">
        <BasicTabs
          list={CUSTOMER_ORDER_DETAILS_TAB_LIST.map((tab) => tab.label )}
          setValue={handleTabChange}
          value={
            CUSTOMER_ORDER_DETAILS_TAB_LIST.findIndex(
            (tab) => tab.value === currentTab
          )}
        />
        <CustomTabPanel
          value={CUSTOMER_ORDER_DETAILS_TAB_LIST.findIndex(
            (tab) => tab.value === currentTab
          )}
          index={0}
        >
          <OrderStatus order={order}/>
          <OrderDetails order={order}/>
        </CustomTabPanel>
      </div>
    </Box>
  );
};

export default CustomerOrderDetailsPage;
