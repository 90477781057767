import { createSlice } from "@reduxjs/toolkit";
import { fetchCustomizeTaxes, fetchOrganizationData, fetchOrganizationSubscription } from "./organizationAction";
import { logout } from "./authActions";

export const initialOrganizationState = {
  organization: null,
  subscription: null,
  customizeTaxes: null,
};

const OrganizationSlice = createSlice({
  name: "organization",
  initialState: initialOrganizationState,
  reducers: {
    updateOrganization: (state, action) => {
      state.customizeTaxes = action.payload;
    },
    updateCustomizeTaxes: (state, action) => {
      state.customizeTaxes = action.payload;
    },
    updateSubscription: (state, action) => {
      state.subscription = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizationData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchOrganizationData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.organization = action.payload;
      })
      .addCase(fetchOrganizationData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchCustomizeTaxes.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchCustomizeTaxes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.customizeTaxes = action.payload;
      })
      .addCase(fetchCustomizeTaxes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchOrganizationSubscription.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchOrganizationSubscription.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.subscription = action.payload;
      })
      .addCase(fetchOrganizationSubscription.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(logout.pending, (state, _action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state, _action) => {
        state.organization = null;
        state.subscription = null;
        state.customizeTaxes = null;
        state.loading = false;
        state.error = null;
      })
      .addCase(logout.rejected, (state, _action) => {
        state.organization = null;
        state.subscription = null;
        state.customizeTaxes = null;
        state.loading = false;
        state.error = null;
      });
  },
});

export const { updateOrganization, updateCustomizeTaxes, updateSubscription } = OrganizationSlice.actions;
export const selectCurrentOrganization = (state) => state?.organization?.organization;
export const selectSubscription = (state) => state?.organization?.subscription;
export const selectCustomizeTaxes = (state) => state?.organization?.customizeTaxes;

export default OrganizationSlice.reducer;