import React, { useState, useEffect } from "react";
import { Button, Modal } from "@mui/material";
import { selectCurrentUser, setCurrentUser } from "../../redux/authSlice";
import { useSelector } from "react-redux";
import { Add } from "@mui/icons-material";
import ActionMenu from "../Button/ActionMenu";
import CustomTable from "../Table";
import { formatDate } from "../../utils";
import { deleteRequest, postRequest, updateRequest } from "../../api";
import { useDispatch } from "react-redux";
import { addToast } from "../../redux/toastSlice";
import AddressModal from "../Modal/AddressModal";
import { capitalize } from "lodash";
import { ADDRESSES, USERS } from "../../constants/BackendRoutes";

const columns = [
  {
    field: "human_readable",
    label: "Address",
    width: "400px",
  },
  {
    field: "address_type",
    label: "Type",
    width: "200px",
  },
  {
    field: "last_update",
    label: "Last update",
    width: "300px",
  },
  {
    field: "actions",
    label: "",
    width: "100px",
    component: ActionMenu,
  },
];

const Address = ({}) => {
  const userData = useSelector(selectCurrentUser);
  const [isModalOpen, setModalOpen] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState(null);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  const transformAddresses = (addresses, handleEditModalOpen, handleRemove) => {
    return addresses?.map((item) => ({
      id: item.id,
      address_type: capitalize(item.address_type),
      human_readable: item.human_readable,
      last_update: formatDate(item.updated_at),
      actionsProps: {
        listOfActions: [
          { label: "Edit", handler: handleEditModalOpen },
          { label: "Remove", handler: handleRemove },
        ],
      },
    }));
  };

  const handleSubmit = async (address) => {
    try {
      const isEditRequest = !!addressToEdit;
      const url = isEditRequest
        ? `${ADDRESSES}/${address?.id}`
        : `${USERS}/${userData?.id}${ADDRESSES}`;
      const requestMethod = isEditRequest ? updateRequest : postRequest;
      const response = await requestMethod(url, { address });

      if (response) {
        dispatch(
          setCurrentUser({
            ...userData,
            addresses: isEditRequest
              ? userData.addresses.map((addr) =>
                  addr.id === address?.id ? { ...addr, ...response.data } : addr
                )
              : [...userData.addresses, response.data],
          })
        );

        dispatch(addToast("Address updated successfully."));
      }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setAddressToEdit(null);
    setModalOpen(false);
  }

  const handleEditModalOpen = (id) => {
    const address = userData?.addresses?.find((address) => address?.id == id);
    setAddressToEdit(address);
    setModalOpen(true);
  };

  const handleRemove = async (id) => {
    try {
      await deleteRequest(`addresses/${id}`);
      setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      dispatch(addToast("Address removed successfully."));
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  useEffect(() => {
    if (userData?.addresses) {
      setRows(transformAddresses(userData?.addresses, handleEditModalOpen, handleRemove));
    }
  }, [userData?.addresses]);

  return (
    <div className="flex flex-col gap-6 p-8 bg-white rounded-lg w-full">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between">
        <span className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
          Address information
        </span>
        <Button
          variant="textSecondary"
          onClick={() => setModalOpen(true)}
          size="small"
          startIcon={<Add />}
          sx={{
            margin: 0,
            justifyContent: "start",
          }}
        >
          Add a new address
        </Button>
      </div>
      <CustomTable columns={columns} rows={rows} />
      <AddressModal
        open={isModalOpen}
        close={handleModalClose}
        onSubmit={handleSubmit}
        address={addressToEdit}
        editable={!!addressToEdit}
      />
    </div>
  );
};

export default Address;
