import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./DonutChart.css"; // Import the CSS file
import { useNavigate } from "react-router-dom";
import { groupByEnums } from "../../../utils";
import { ORDER_STATUS_ENUM } from "../../../constants";

Chart.register(ArcElement, Tooltip, Legend);

const DonutChart = ({ title, orders, navigateTo }) => {
  const [newCount, setNewCount] = useState(0);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [delayedCount, setDelayedCount] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (orders.length > 0) {
      const {
        in_progress,
        new_order,
        delayed,
      } = groupByEnums(orders, ORDER_STATUS_ENUM, "status");

      setNewCount(new_order.length);
      setInProgressCount(in_progress.length);
      setDelayedCount(delayed.length);
    } else {
      setNewCount(0);
      setInProgressCount(0);
      setDelayedCount(0);
    }
  }, [orders])

  let data = {
    labels: ["In Progress", "New", "Delayed"],
    datasets: [
      {
        label: "Colors",
        data: [inProgressCount, newCount, delayedCount],
        backgroundColor: ["#4c8c4a", "#cdae83", "#f3f0e8"],
      },
    ],
  };

  const allZero = data.datasets[0].data.every((value) => value === 0);

  if (allZero) {
    data = {
      labels: ["No Data"],
      datasets: [
        {
          label: "Colors",
          data: [1],
          backgroundColor: ["#d3d3d3"],
        },
      ],
    };
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="flex flex-col gap-5 p-8 shadow-lg rounded-2xl">
      <div className="flex justify-between items-center">
        <p className="text-neutral-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
          {title}
        </p>
        <IconButton onClick={() => navigate(navigateTo)}>
          <ChevronRightIcon />
        </IconButton>
      </div>
      <div className="flex flex-col sm:flex-row justify-between gap-5 sm:gap-0">
        <div className="w-full sm:w-3/5 lg:w-2/5">
          <div className="relative pb-1/2 md:pb-[75%] lg:pb-[100%]">
            <div className="md:absolute inset-0">
              <Doughnut data={data} options={options} />
            </div>
          </div>
        </div>
        <div className="w-full sm:w-2/5 flex flex-col gap-5 md:items-center sm:justify-center">
          <div className="text-stone-800 text-xl font-normal font-['Questrial'] leading-snug">
            Order status
          </div>
          <ul className="flex flex-col sm:gap-3 md:flex-col justify-start">
            {data.labels.map((label, index) => (
              <li key={index} className="flex items-center gap-2">
                <span
                  className="rounded-full w-3 h-3 mr-2"
                  style={{
                    backgroundColor: data.datasets[0].backgroundColor[index],
                  }}
                ></span>
                <div className="flex gap-2 items-end">
                  <span className="text-neutral-800 text-2xl font-bold font-['Montserrat'] leading-[33.60px]">
                    {data.datasets[0].data[index]}
                  </span>
                  <span className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                    {label}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
