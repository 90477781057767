import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectCurrentUser } from "../../redux/authSlice";
import { getRequest } from "../../api";
import { addToast } from "../../redux/toastSlice";
import Selector from "../Form/Field/AutoComplete";
import TextInput from "../Form/Field/TextInput";
import {
  BILLING_CYCLE,
  BILLING_METHOD,
  BILLING_METHOD_OPTIONS,
} from "../../constants";
import { Button } from "@mui/material";
import { PlusCircle } from "../../common/icons";

const useBillingPreferences = (userData) => {
  const [billingPreferences, setBillingPreferences] = useState([]);
  const [billingMethodOptions, setBillingMethodOptions] = useState([...BILLING_METHOD_OPTIONS]);

  const parseBillingPreferences = (billingPreferences) =>
    billingPreferences.map((billPref) => ({
      store_id: billPref?.store?.id,
      store_name: billPref?.store?.name,
      id: billPref.id,
      billing_cycle: billPref.billing_cycle,
      billing_method: billPref.billing_method,
      payment_method_id: billPref.payment_method_id,
      additional_notes: billPref.additional_notes,
      additional_notes_present: !!billPref.additional_notes,
      billing_method_option:
        billPref.billing_method === BILLING_METHOD.PAY_WITH_CARD_ON_FILE &&
        billPref.payment_method_id
          ? billPref.payment_method_id
          : billPref.billing_method,
    }));

  useEffect(() => {
    if (userData?.payment_methods?.length > 0) {
      const cardOptions = userData.payment_methods.map((card) => ({
        title: `Pay with card (ending ${card?.last4})`,
        isCardNetwork: true,
        value: card.id,
      }));
      setBillingMethodOptions([...BILLING_METHOD_OPTIONS, ...cardOptions]);
    }
  }, [userData?.payment_methods]);

  useEffect(() => {
    const fetchBillingPreferences = async () => {
      try {
        const response = await getRequest(
          "customer/billing_preferences",
          {},
          "store"
        );
        if (response.length > 0) {
          setBillingPreferences(parseBillingPreferences(response));
        }
      } catch (error) {
        console.error("Error fetching billing preferences:", error);
      }
    };
    fetchBillingPreferences();
  }, []);

  return {
    billingPreferences,
    setBillingPreferences,
    billingMethodOptions,
  };
};

const BillingPreferenceItem = ({
  preference,
  billingMethodOptions,
  onChange,
  onNoteToggle,
}) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange(preference?.id, { [name]: value });
  };

  const handleSelectorChange = (field) => (_, value) => {
    if (field == "billing_method_option") {
      if (value?.isCardNetwork) {
        onChange(preference?.id, {
          billing_method: BILLING_METHOD.PAY_WITH_CARD_ON_FILE,
          payment_method_id: value?.value,
          [field]: value?.value,
        });
      } else {
        onChange(preference?.id, {
          billing_method: value?.value,
          payment_method_id: null,
          [field]: value?.value,
        });
      }
    } else {
      onChange(preference?.id, { [field]: value?.value });
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col lg:flex-row gap-3 w-full">
        <div className="flex flex-col w-full lg:w-[25%]">
          <span className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            {preference?.store_name}
          </span>
          <span className="text-stone-400 text-xs font-normal font-['Questrial'] leading-snug">
            (Repair Store)
          </span>
        </div>
        <div className="w-full lg:w-[75%] flex flex-col lg:flex-row gap-2">
          <div className="flex flex-col gap-1 lg:gap-2 w-full">
            <span className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              Billing cycle
            </span>
            <Selector
              size="large"
              name="billing_cycle"
              label="Please select"
              options={BILLING_CYCLE}
              onChange={handleSelectorChange("billing_cycle")}
              value={BILLING_CYCLE.find(
                (cycle) => cycle.value === preference.billing_cycle
              )}
            />
          </div>
          <div className="flex flex-col gap-1 lg:gap-2 w-full">
            <span className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              Payment method
            </span>
            <Selector
              size="large"
              name="billing_method_option"
              label="Please select"
              options={billingMethodOptions}
              value={billingMethodOptions.find(
                (m) => m.value === preference.billing_method_option
              )}
              onChange={handleSelectorChange("billing_method_option")}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full lg:w-[calc(75%-10px)] lg:ml-auto gap-2">
        {preference.additional_notes_present ? (
          <TextInput
            size="large"
            name="additional_notes"
            placeholder="Enter notes"
            value={preference.additional_notes}
            onChange={handleInputChange}
          />
        ) : (
          <div className="flex justify-end">
            <Button
              variant="textPrimary"
              startIcon={<PlusCircle fill={"green"} />}
              onClick={() => onNoteToggle(preference.id)}
            >
              Add Notes
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const BillingPreference = ({ setBillingPreferenceChanges }) => {
  const userData = useSelector(selectCurrentUser);
  const { billingPreferences, setBillingPreferences, billingMethodOptions } =
    useBillingPreferences(userData);

  const handlePreferenceChange = useCallback((id, changes) => {
      setBillingPreferences((prev) =>
        prev.map((preference) =>
          preference.id === id ? { ...preference, ...changes } : preference
        )
      );

      setBillingPreferenceChanges((prev) => {
        const updatedPreferences = [...prev];
        const existingIndex = updatedPreferences.findIndex(
          (pref) => pref.id === id
        );

        const { billing_cycle, billing_method, additional_notes, payment_method_id } = {
          ...billingPreferences.find((preference) => preference.id === id),
          ...changes,
        };

        const updatedPreference = {
          id,
          billing_cycle,
          billing_method,
          additional_notes,
          payment_method_id,
        };

        if (existingIndex !== -1) {
          updatedPreferences[existingIndex] = updatedPreference;
        } else {
          updatedPreferences.push(updatedPreference);
        }

        return updatedPreferences;
      });
    },
    [setBillingPreferences, setBillingPreferenceChanges, billingPreferences]
  );

  const handleNoteToggle = useCallback((id) => {
    setBillingPreferences((prev) =>
      prev.map((preference) =>
        preference.id === id
          ? { ...preference, additional_notes_present: true }
          : preference
      )
    );
  }, []);

  return (
    <div className="flex flex-col gap-6 p-8 bg-white rounded-lg w-full">
      <span className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Billing Preferences
      </span>
      {billingPreferences.map((preference) => (
        <BillingPreferenceItem
          key={preference.id}
          preference={preference}
          billingMethodOptions={billingMethodOptions}
          onChange={handlePreferenceChange}
          onNoteToggle={handleNoteToggle}
        />
      ))}
    </div>
  );
};

export default BillingPreference;
