import React, { useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import Pagination from "@mui/material/Pagination";
import { Box, MenuItem, Typography } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

function CustomPaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const totalPages = Math.ceil(count / rowsPerPage);

  const handlePageChange = (event, newPage) => {
    onPageChange(event, newPage);
  };

  return (
    <Box
      sx={{
        "& .MuiPagination-root": {
          borderRadius: "8px",
          padding: "0px",
          border: "1px solid #dfdfdf",
        },
        "& .MuiPaginationItem-root": {
          width: {
            xs: "32px",
            sm: "35px",
          },
          height: "41px",
          padding: "12px 16px",
          color: "#272523",
          fontSize: "12px",
          fontFamily: "Questrial",
          borderRight: "1px solid rgba(243, 240, 232, 0.5)",
          borderRadius: "0px",
          margin: "0px",
        },
        "& .Mui-selected": {
          backgroundColor: "rgba(76, 140, 74, 0.1)",
          color: "#4c8c4a",
          borderLeft: "1px solid rgba(243, 240, 232, 0.5)",
          borderRight: "1px solid rgba(243, 240, 232, 0.5)",
        },
        "& .MuiPagination-ul": {
          justifyContent: "center",
        },
      }}
    >
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        siblingCount={1}
        boundaryCount={1}
        shape="rounded"
      />
    </Box>
  );
}

export default function CustomPagination({ paginationMeta, setCurrentPage, setPerPage }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [total, setTotal] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPage(newPage);
  }
  const handleChangeRowsPerPage = (event) => {
    const perPage = parseInt(event.target.value, 10);
    setRowsPerPage(perPage);
    setPerPage(perPage);
    setPage(1);
    setCurrentPage(1);
  };

  const PaginationMeta = () => {
    return (
      <>
        <Typography variant="body2" color="text.secondary">
          {`${(page - 1) * rowsPerPage + 1} – ${
            (page - 1) * rowsPerPage > total ? total : (page) * rowsPerPage
          } of ${total}`}
        </Typography>
      </>
    );
  }

  useEffect(() => {
    if (paginationMeta) {
      setPage(paginationMeta?.current_page || 0);
      setRowsPerPage(paginationMeta?.per_page || 0);
      setTotal(paginationMeta?.total || 0);
    }
  }, [paginationMeta]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginTop={2}
      marginBottom={2}
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        gap: {
          xs: "8px",
          sm: "0px",
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: { xs: "100%", sm: "auto" } }}
      >
        <TablePagination
          component="div"
          count={8700}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Per Page"
          ActionsComponent={() => null}
          labelDisplayedRows={() => ""}
          rowsPerPageOptions={[10, 25, 50]}
          slotProps={{
            select: {
              IconComponent: () => (open ? <ArrowDropUp /> : <ArrowDropDown />),
              renderValue: (value) => `${value} per page`,
              onOpen: handleOpen,
              onClose: handleClose,
            },
          }}
          sx={{
            "& .MuiToolbar-root": {
              borderRadius: "8px",
              border: "1px solid #dfdfdf",
              display: "flex",
              alignItems: "center",
              flexDirection: "row-reverse",
              padding: "0px",
              minHeight: "41px",
            },
            "& .MuiTablePagination-input": {
              marginRight: "0px",
              color: "#939291",
              fontSize: "12px",
              fontFamily: "Questrial",
              paddingRight: "16px",
            },
            "& .MuiTablePagination-selectLabel": {
              display: "none",
            },
            "& .MuiSelect-icon": {
              display: "none",
            },
          }}
        />
        <Box sx={{ display: { xs: "block", sm: "none" }, }}>
          <PaginationMeta />
        </Box>
      </Box>
      <CustomPaginationActions
        count={total}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
      />
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "block",
          },
        }}
      >
        <PaginationMeta />
      </Box>
    </Box>
  );
}
