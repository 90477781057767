import { createSlice } from "@reduxjs/toolkit";

const currentsStepSlice = createSlice({
  name: "currentStep",
  initialState: 0,
  reducers: {
    updateCurrentStep: (state, action) => {
      return action.payload;
    },
  },
})

export const { updateCurrentStep } = currentsStepSlice.actions;
export const selectCurrentStep = (state) => state?.currentStep;

export default currentsStepSlice.reducer;
