import React, { useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import MenuButton from "../../../Button/Menu";
import NumberInput from "../../../Form/NumberInput";
import ServiceItem from "./ServiceItem";
import ProductItem from "./ProductItem";
import CartBadge from "../CartBadge";
import { generateRandomString } from "../../../../utils";
import { POS_NEW_ORDER_STEPS } from "../../../../constants";
import { useDispatch } from "react-redux";
import { updateCurrentStep } from "../../../../redux/currentStepSlice";
import { useMediaQuery } from "react-responsive";

const ITEM_OPTIONS = {
  service_list: "Service List",
  product_list: "Product List",
};

const AddItem = ({
  replica,
  setReplica,
  editMode,
  setEditMode,
  duplicateMode,
  setDuplicateMode,
  products,
  subCategories,
  addServiceLineItem,
  addProductLineItem,
}) => {
  const [itemType, setItemType] = useState(ITEM_OPTIONS.service_list);

  const [quantity, setQuantity] = useState(1);
  const [currentServices, setCurrentServices] = useState([]);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [currentProduct, setCurrentProduct] = useState();
  const [currentCategoryType, setCurrentCategoryType] = useState();

  const [showDescription, setShowDescription] = useState(false);
  const [description, setDescription] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1024px)" });

  const resetSelections = () => {
    setCurrentServices([]);
    setCurrentOptions([]);
    setCurrentProduct();
    setCurrentCategoryType();
    setDescription("");
    setShowDescription(false);
  };

  const handleCurrentCategoryType = (cT) => {
    resetSelections();
    setCurrentCategoryType(cT);
  };

  const isButtonDisabled = () => {
    if (itemType === ITEM_OPTIONS.service_list) {
      return currentOptions.length < 1 && currentServices.length < 1;
    } else {
      return !currentProduct;
    }
  };

  const handleLineItems = () => {
    if (isButtonDisabled()) return;

    if (itemType === ITEM_OPTIONS.service_list && currentCategoryType) {
      addServiceLineItem({
        category: currentCategoryType.category,
        categoryType: currentCategoryType.name,
        services: currentServices,
        options: currentOptions,
        uniqStr: generateRandomString(),
        quantity,
        description,
      });
    } else if (itemType === ITEM_OPTIONS.product_list) {
      addProductLineItem({
        ...currentProduct,
        uniqStr: generateRandomString(),
        quantity,
        description,
      });
    }

    if (duplicateMode) setDuplicateMode(false);
    if (editMode) setEditMode(false);

    setReplica(null);
    setQuantity(1);
    resetSelections();
    if (!isDesktopScreen) dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.ORDER_CART));
  };

  const handleCancel = () => {
    if (replica) {
      if (itemType === ITEM_OPTIONS.service_list && !duplicateMode) {
        addServiceLineItem({
          category: replica.category,
          categoryType: replica.categoryType,
          services: replica.services,
          options: replica.options,
          uniqStr: replica?.uniqStr,
          quantity: replica?.quantity,
          description: replica?.description,
        });
      } else if (itemType === ITEM_OPTIONS.product_list) {
        addProductLineItem({
          id: replica?.id,
          name: replica?.name,
          price: replica?.price,
          category: replica?.category,
          uniqStr: replica?.uniqStr,
          quantity: replica?.quantity,
          description: replica?.description,
        });
      }
    } else {
      navigate(-1);
    }

    if (duplicateMode) setDuplicateMode(false);
    if (editMode) setEditMode(false);

    setQuantity(1);
    setReplica(null);
    resetSelections();
    if (!isDesktopScreen) dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.ORDER_CART));
  };

  useEffect(() => {
    if (replica) {
      setItemType(
        replica?.services?.length > 0 || replica?.options?.length > 0
          ? ITEM_OPTIONS.service_list
          : ITEM_OPTIONS.product_list,
      );
      setQuantity(replica?.quantity || 1);
      if (replica?.description) {
        setShowDescription(true);
        setDescription(replica?.description);
      }
    }
  }, [replica]);

  return (
    <div className="flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <div className="overflow-y-auto p-0 lg:px-5 lg:pt-5">
        <CartBadge replica={replica} duplicateMode={duplicateMode} />
        <div className="flex flex-col gap-2 lg:gap-4 items-start">
          {replica ? (
            isDesktopScreen && (
              <p className="text-xl lg:text-2xl font-[Montserrat] font-semibold lg:font-bold leading-[25.20px]">
                {duplicateMode ? "Duplicate Item" : "Edit Item"}
              </p>
            )
          ) : (
            <MenuButton
              options={Object.values(ITEM_OPTIONS)}
              variant="outlinedTertiary"
              onChange={(e) => {
                setItemType(e);
                setQuantity(1);
              }}
            />
          )}

          {itemType === ITEM_OPTIONS.service_list ? (
            <ServiceItem
              replica={replica}
              subCategories={subCategories}
              currentServices={currentServices}
              setCurrentServices={setCurrentServices}
              currentOptions={currentOptions}
              setCurrentOptions={setCurrentOptions}
              currentCategoryType={currentCategoryType}
              setCurrentCategoryType={handleCurrentCategoryType}
              resetSelections={resetSelections}
            />
          ) : (
            <ProductItem
              replica={replica}
              products={products}
              currentProduct={currentProduct}
              setCurrentProduct={setCurrentProduct}
            />
          )}
        </div>

        {showDescription && (
          <div className="flex flex-col mb-4">
            <p className="text-lg font-bold my-4">Description</p>
            <TextField
              id="outlined-multiline"
              placeholder="Description"
              multiline
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={4}
            />
          </div>
        )}
      </div>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: showDescription ? "flex-end" : "space-between",
          alignItems: "center",
          gap: 2,
          borderBottomLeftRadius: "lg",
          borderBottomRightRadius: "lg",
        }}
        className="pt-4 lg:px-5 lg:pb-2"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 4,
            width: { xs: "100%", sm: "auto" },
          }}
        >
          {!showDescription && (
            <Button
              variant="textSecondary"
              startIcon={<Add />}
              sx={{
                margin: { xs: "0px", sm: "16px" },
              }}
              disabled={isButtonDisabled()}
              onClick={() => setShowDescription(true)}
            >
              Additional order details
            </Button>
          )}

          <Box
            sx={{
              display: { xs: "inline-flex", sm: "none" },
            }}
          >
            {(currentServices.length > 0 || currentOptions.length > 0 || currentProduct) && (
              <NumberInput quantity={quantity} setQuantity={setQuantity} />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 1,
            width: { xs: "100%", sm: "auto" },
            justifyContent: "flex-end",
            alignItems: { lg: "center" },
          }}
        >
          <Box
            sx={{
              display: { xs: "none", sm: "inline-flex" },
            }}
          >
            {(currentServices.length > 0 || currentOptions.length > 0 || currentProduct) && (
              <NumberInput quantity={quantity} setQuantity={setQuantity} />
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column-reverse", sm: "row" },
              gap: 1,
            }}
          >
            {replica && (
              <Button
                variant="outlinedSecondary"
                onClick={handleCancel}
                sx={{
                  width: { xs: "100%", sm: "fit-content" },
                }}
              >
                Cancel
              </Button>
            )}

            <Button
              variant="containedPrimary"
              disabled={isButtonDisabled()}
              onClick={handleLineItems}
              sx={{
                width: { xs: "100%", sm: "fit-content" },
              }}
            >
              {replica && !duplicateMode ? "Update" : "Add Item"}
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default AddItem;
