import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { UpDownArrowIcon } from "../../common/icons";

export default function MenuButton({
  title,
  options,
  onChange,
  variant,
  mobileIcon = false,
  desktopIconShow = false,
  desktopIcon = null,
  mobileCustomIcon = null,
  isObject = false,
  optionKey
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(options[0]);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, item) => {
    if (item !== "backdropClick") {
      setSelectedItem(item);
    }
    setAnchorEl(null);
  };

  const iconToShow = desktopIcon || (desktopIconShow ? <UpDownArrowIcon /> : null);

  useEffect(() => {
    if (!(selectedItem || onChange)) return;
    onChange(selectedItem);
  }, [selectedItem]);

  return (
    <React.Fragment>
      {mobileIcon && (
        <Button
          onClick={handleClick}
          variant={variant}
          sx={{
            display: { xs: "flex", md: "none" },
            padding: 0,
            minWidth: 36,
            height: 36,
            color: open ? "#4C8C4A" : "#272523",
          }}
        >
           {mobileCustomIcon ? mobileCustomIcon : <UpDownArrowIcon />}
        </Button>
      )}
      <Button
        onClick={handleClick}
        variant={variant}
        startIcon={iconToShow}
        endIcon={<KeyboardArrowDownIcon />}
        className={mobileIcon && "hidden md:flex"}
        sx={{
          display: mobileIcon ? { xs: "none", md: "flex" } : "flex",
          gap: 1,
          height: "41px",
          justifyContent: "flex-start",
          color: open ? "#4C8C4A" : "#272523",
          "@media (max-width: 640px)": {
            width: "100%",
            fontSize: "18px",
          },
        }}
      >
        {title && <span className="text-stone-400">{title}</span>}
        <span>{isObject ? selectedItem[optionKey] : selectedItem}</span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            width: "auto",
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            onClick={(event) => handleClose(event, option)}
            className="w-full"
            sx={{
              "&:hover": {
                color: "#4C8C4A",
              },
            }}
          >
            {isObject ? option[optionKey] : option}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
