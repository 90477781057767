import { Box, Button, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../api";
import { CrossCircle, PlusCircle } from "../common/icons";
import EntityPage from "../components/EntityPage";
import Selector from "../components/Form/Field/AutoComplete";
import TextInput from "../components/Form/Field/TextInput";
import FieldGroup from "../components/Form/FieldGroup";
import FieldItem from "../components/Form/FieldItem";
import FormPage from "../components/Form/FormPage";
import { addToast } from "../redux/toastSlice";
import { selectAllCategories, selectCurrentStore } from "../redux/storeSlice";
import { fetchMainCategories } from "../redux/storeAction";

const INITIAL_CATEGORY_TYPE = {
  mainCategory: null,
  categoryType: "",
};

const CreateCategoryType = () => {
  const [categoryTypes, setCategoryTypes] = useState([{ ...INITIAL_CATEGORY_TYPE }]);
  const [hoverIndex, setHoverIndex] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentStore = useSelector(selectCurrentStore);
  const allCategories = useSelector(selectAllCategories);

  const handleAddMore = () => {
    setCategoryTypes([{ ...INITIAL_CATEGORY_TYPE }, ...categoryTypes]);
  };

  const handleRemove = (index) => {
    const updatedCategoryTypes = categoryTypes.filter((_, i) => i !== index);
    setCategoryTypes(updatedCategoryTypes);
  };

  const handleInputChange = (index, name, value) => {
    const updatedCategoryTypes = [...categoryTypes];
    updatedCategoryTypes[index][name] = value;
    setCategoryTypes(updatedCategoryTypes);
  };

  const handleCreateCategory = async (categoryName) => {
    try {
      const res = await postRequest(
        `stores/${currentStore?.store_id}/categories`,
        {
          category: {
            name: categoryName,
          },
        }
      );

      if (res.status === 201) {
        dispatch(addToast("Category(s) added successfully."));
        dispatch(fetchMainCategories(currentStore?.store_id));
      } else {
        dispatch(addToast("Failed to add Main Category."));
      }
    } catch (error) {
      dispatch(addToast(error));
    }
  }

  const handleCreateCategoryType = async () => {
    const payload = categoryTypes.map((item) => ({
      category: item.mainCategory.value,
      name: item.categoryType,
    }));

    try {
      const res = await postRequest(
        `stores/${currentStore?.store_id}/sub_categories`,
        { sub_categories: payload }
      );

      if (res.status === 201) {
        dispatch(addToast("Category Type(s) added successfully."));
        navigate(-1);
      } else {
        dispatch(addToast("Failed to add category type(s).", { appearance: "error" }));
      }
    } catch (error) {
      dispatch(addToast(`Error: ${error.message}`, { appearance: "error" }));
    }
  };

  const isAllFieldsFilled = categoryTypes.every((categoryType) =>
    Object.values(categoryType).every((value) =>
      value && typeof value === "string" ? value.trim() !== "" : value
    )
  );

  return (
    <EntityPage title="Add Category Type" breadcrumbs stickyBottomBar>
      <FormPage>
        <Box className="flex justify-between items-center my-4">
          <Typography variant="h6">Category type Details</Typography>
          <Button
            variant="textPrimary"
            startIcon={<PlusCircle fill={isAllFieldsFilled ? "green" : "gray"} />}
            onClick={handleAddMore}
            disabled={!isAllFieldsFilled}
          >
            Add more
          </Button>
        </Box>

        {categoryTypes.map((categoryType, index) => (
          <React.Fragment key={index}>
            <FieldGroup>
              <FieldItem>
                <Typography width="30%" variant="body">
                  Main Category
                </Typography>
                <Selector
                  label="Please select"
                  options={allCategories?.map((item) => ({
                    title: item?.name,
                    value: item?.name,
                  }))}
                  value={categoryType.mainCategory}
                  onChange={(_e, value) => handleInputChange(index, "mainCategory", value)}
                  newItemTitle="Add new main category"
                  handleNewItemClick={(e) => handleCreateCategory(e)}
                />
              </FieldItem>

              <FieldItem>
                <Typography width="30%" variant="body">
                  Category Type(s)
                </Typography>
                <TextInput
                  name="categoryType"
                  value={categoryType.categoryType}
                  onChange={(e) => handleInputChange(index, "categoryType", e.target.value)}
                />
              </FieldItem>
            </FieldGroup>
            <Box className="flex justify-end items-center my-4" sx={{ position: 'relative' }}>
              {index !== categoryTypes.length - 1 && (
                <Button
                  variant="textPrimary"
                  startIcon={<CrossCircle fill={hoverIndex === index ? "green" : "gray"} />}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  onClick={() => handleRemove(index)}
                >
                  Remove
                </Button>
              )}
            </Box>
            {index < categoryTypes.length - 1 && <hr className="my-10" />}
          </React.Fragment>
        ))}

        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "16px 0",
            display: "flex",
            flexDirection: "row-reverse",
            gap: "28px",
          }}
          elevation={8}
        >
          <div className="flex flex-row gap-3 mx-5">
            <Button variant="outlinedPrimary" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              variant="containedPrimary"
              disabled={!isAllFieldsFilled}
              onClick={handleCreateCategoryType}
            >
              Create
            </Button>
          </div>
        </Paper>
      </FormPage>
    </EntityPage>
  );
};

export default CreateCategoryType;
