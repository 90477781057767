import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BasicTabs, { CustomTabPanel } from "../../../common/components/Tabs";

const SummaryInTabs = ({
  summaryTitle,
  summaryTabList,
  SummaryComponent,
  groupedItems,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const initialTab = searchParams.get("tab") || summaryTabList[0].value;
  const [currentTab, setCurrentTab] = useState(initialTab);

  const handleTabChange = (newValue) => {
    const newTab = summaryTabList[newValue].value;
    setCurrentTab(newTab);
    searchParams.set("tab", newTab);
    navigate({ search: searchParams.toString() });
  };

  const renderPanelItems = (items) =>
    items?.length > 0 ? (
      <div className="flex flex-col gap-[24px]">
        {items.map((item) => (
          <SummaryComponent key={item.id} item={item} />
        ))}
      </div>
    ) : (
      <p className="px-3 py-10 text-center text-[#837d77]">
        No item(s) to display
      </p>
    );

  return (
    <section className="bg-white px-5 lg:px-8 py-[24px] flex flex-col gap-[16px] rounded-[8px] w-full">
      <div className="flex flex-row items-center justify-between md:justify-start gap-[16px]">
        <div className="font-['Montserrat'] text-[24px] font-[700] text-[#272523]">
          {summaryTitle}
        </div>
      </div>
      <BasicTabs
        list={summaryTabList.map((tab) => tab.label)}
        setValue={handleTabChange}
        value={summaryTabList.findIndex((tab) => tab.value === currentTab)}
      />
      {summaryTabList.map((tab, index) => (
        <CustomTabPanel
          key={tab.value}
          value={summaryTabList.findIndex((t) => t.value === currentTab)}
          index={index}
        >
          {renderPanelItems(groupedItems?.[currentTab])}
        </CustomTabPanel>
      ))}
    </section>
  );
};

export default SummaryInTabs;
