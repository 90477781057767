import { Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest, updateRequest } from "../api";
import EntityPage from "../components/EntityPage";
import AutoCompleteInput from "../components/Form/Field/AutoComplete";
import TextInput from "../components/Form/Field/TextInput";
import FieldGroup from "../components/Form/FieldGroup";
import FieldItem from "../components/Form/FieldItem";
import FormPage from "../components/Form/FormPage";
import { COMMON_STATUS } from "../constants";
import { addToast } from "../redux/toastSlice";
import { selectAllCategories, selectCurrentStore } from "../redux/storeSlice";

const EditProduct = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentStore = useSelector(selectCurrentStore);
  const allCategories = useSelector(selectAllCategories)

  useEffect(() => {
    if (currentStore) fetchProductData();
  }, [currentStore]);

  const fetchProductData = async () => {
    try {
      const fetchedProduct = await getRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stores/${currentStore?.store_id}/products/${id}`
      );
      setProduct({
        ...fetchedProduct,
        category: { title: fetchedProduct.category, value: fetchedProduct.category },
        status: { title: COMMON_STATUS[fetchedProduct.status], value: fetchedProduct.status },
      });
    } catch (error) {
      dispatch(addToast("Failed to fetch product data."));
    }
  };

  const handleInputChange = (name, value) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const transformProductInput = (product) => {
    const { status, price, category } = product;

    return {
      ...product,
      status: status?.value || status,
      price: parseFloat(price),
      category: category?.value || category,
    };
  };

  const handleUpdateProduct = async () => {
    try {
      const res = await updateRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stores/${currentStore?.store_id}/products/${id}`,
        { product: transformProductInput(product) }
      );

      if (res.status === 200) {
        dispatch(addToast("Product updated successfully."));
        navigate(-1);
      }
    } catch (error) {
      dispatch(addToast("Failed to update product."));
    }
  };

  if (!product) return null;

  return (
    <EntityPage title="Edit Product" breadcrumbs stickyBottomBar>
      <FormPage>
        <FieldGroup label="Category Details">
          <FieldItem>
            <Typography width="30%" variant="body">
              Main Category
            </Typography>
            <AutoCompleteInput
              label="Please select"
              options={allCategories?.map((item) => ({
                title: item?.name,
                value: item?.name,
              }))}
              value={product.category}
              onChange={(_e, value) => handleInputChange("category", value)}
            />
          </FieldItem>
        </FieldGroup>

        <hr className="my-10" />

        <FieldGroup label="Product Details">
          <FieldItem>
            <Typography width="30%" variant="body">
              Name
            </Typography>
            <TextInput
              value={product.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
          </FieldItem>

          <FieldItem>
            <Typography width="30%" variant="body">
              Product ID
            </Typography>
            <TextInput
              value={product.product_id}
              onChange={(e) => handleInputChange("product_id", e.target.value)}
            />
          </FieldItem>

          <FieldItem>
            <Typography width="30%" variant="body">
              Brand
            </Typography>
            <TextInput
              value={product.brand}
              onChange={(e) => handleInputChange("brand", e.target.value)}
            />
          </FieldItem>

          <FieldItem>
            <Typography width="30%" variant="body">
              Status
            </Typography>
            <AutoCompleteInput
              label="Please select"
              options={Object.entries(COMMON_STATUS).map(([key, value]) => ({
                title: value,
                value: key,
              }))}
              value={product.status}
              onChange={(_e, value) => handleInputChange("status", value)}
            />
          </FieldItem>
          <FieldItem>
            <Typography width="30%" variant="body">
              Cost per item
            </Typography>
            <TextInput
              type="number"
              value={product.cost_per_item}
              onChange={(e) =>
                handleInputChange("cost_per_item", e.target.value)
              }
            />
          </FieldItem>

          <FieldItem>
            <Typography width="30%" variant="body">
              Price
            </Typography>
            <TextInput
              type="number"
              value={product.price}
              onChange={(e) => handleInputChange("price", e.target.value)}
            />
          </FieldItem>

          <FieldItem>
            <Typography width="30%" variant="body">
              Stock
            </Typography>
            <TextInput
              type="number"
              value={product.stock}
              onChange={(e) => handleInputChange("stock", e.target.value)}
            />
          </FieldItem>

          <FieldItem>
            <Typography width="30%" variant="body">
              Reorder level
            </Typography>
            <TextInput
              type="number"
              value={product.reorder_level}
              onChange={(e) =>
                handleInputChange("reorder_level", e.target.value)
              }
            />
          </FieldItem>
        </FieldGroup>

        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "16px 0",
            display: "flex",
            flexDirection: "row-reverse",
            gap: "28px",
          }}
          elevation={8}
        >
          <div className="flex flex-row gap-3 mx-5">
            <Button variant="outlinedPrimary" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button variant="containedPrimary" onClick={handleUpdateProduct}>
              Update
            </Button>
          </div>
        </Paper>
      </FormPage>
    </EntityPage>
  );
};

export default EditProduct;
