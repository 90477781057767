import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SetupPaymentMethod from "../stripe/SetupPaymentMethod";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/authSlice";

export default function SetupPaymentMethodModal({
  open,
  close,
  handlePaymentMethodAttached,
}) {
  const userData = useSelector(selectCurrentUser);

  return (
    <Dialog open={open} onClose={() => close()} fullWidth={true} maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={() => close()}
        sx={{
          position: "absolute",
          top: "20px",
          right: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <SetupPaymentMethod
        stripeCustomerID={userData?.cust_id}
        onPaymentMethodAttached={handlePaymentMethodAttached}
      />
    </Dialog>
  );
}
