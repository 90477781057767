import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/authSlice";
import { selectCurrentStore } from "../../redux/storeSlice";
import { setLoading } from "../../redux/loadingSlice";
import { addToast } from "../../redux/toastSlice";
import { mapUsers } from "../../utils/assignees";
import { handleAssignees } from "../../api/assignees";
import MultiSelectDropdown from "../Form/MultiSelectDropdown";

const AssignItemModal = ({
  show,
  handleClose,
  lineItemId,
  itemId,
  fetchOrder,
  name,
  type,
  orderId,
  users
}) => {
  const [options, setOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [defaultAssignees, setDefaultAssignees] = useState([]);

  const userData = useSelector(selectCurrentUser);
  const currentStore = useSelector(selectCurrentStore);
  const currentUserId = userData?.id;
  const storeId = currentStore?.store_id;

  const dispatch = useDispatch();

  const handleAssignData = async () => {
    try {
      dispatch(setLoading(true));
      const response = await handleAssignees(
        storeId,
        orderId,
        lineItemId,
        itemId,
        selectedUsers,
        type
      );
      if (response?.data?.success) {
        dispatch(addToast(response?.data?.message));
        handleClose();
        setSelectedUsers([]);
        setDefaultAssignees([]);
        return response;
      }
    } catch (error) {
      dispatch(addToast("Something went wrong!"));
    } finally {
      fetchOrder();
      dispatch(setLoading(false));
    }
  };

  const handleButtonClick = () => {
    setDefaultAssignees(selectedUsers);
  };

  const fetchSelectedOnOpen = () => {
    setSelectedUsers(defaultAssignees);
  };

  useEffect(() => {
    if (!isEmpty(users)) {
      const mappedUsers = mapUsers(users, currentUserId);
      setOptions(mappedUsers);
    }
  }, [users]);

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: 599,
          maxHeight: 599,
          border: "1px solid #ddd",
          borderRadius: "8px",
          padding: "16px",
          margin: "0 auto",
        },
      }}
      fullWidth
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontFamily: "Montserrat, sans-serif",
          fontSize: "24px",
          fontWeight: 700,
          textAlign: "left",
        }}
      >
        Assign Order to Employee
        <IconButton onClick={handleClose} sx={{ color: "grey.600" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          padding: "20px",
          maxHeight: "400px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        <p
          style={{
            fontFamily: "Questrial, sans-serif",
            fontSize: "16px",
            marginBottom: "16px",
          }}
        >
          You can now assign employees to an order, an item, or a repair.
        </p>

        <Box
          sx={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "16px",
            margin: "0 auto",
            maxWidth: "800px",
          }}
        >
          <Paper elevation={0}>
            <Table aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontFamily: "Questrial, sans-serif",
                      fontSize: "14px",
                      fontWeight: 400,
                      width: 250,
                    }}
                  >
                    Item
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Questrial, sans-serif",
                      fontSize: "14px",
                      fontWeight: 400,
                      width: "auto",
                    }}
                  >
                    Assignee
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow role="checkbox" tabIndex={-1}>
                  <TableCell
                    sx={{
                      padding: "16px",
                      fontFamily: "Questrial, sans-serif",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    Item Name: {name}
                  </TableCell>
                  <TableCell sx={{ padding: "16px", textAlign: "right" }}>
                    <MultiSelectDropdown
                      options={options}
                      selectedValues={selectedUsers}
                      onSelectionChange={setSelectedUsers}
                      enableSearch={false}
                      placeholder="Select employees"
                      allItemsLabel="All employees"
                      itemLabel={(item) => item.label}
                      displayButton={true}
                      handleButtonClick={handleButtonClick}
                      fetchSelectedOnOpen={fetchSelectedOnOpen}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box
          sx={{ display: "flex", gap: "8px", width: "328px", height: "42px" }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{
              width: "160px",
              height: "42px",
              padding: "10px 16px",
              gap: "8px",
              borderRadius: "30",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleAssignData}
            sx={{
              width: "160px",
              height: "42px",
              padding: "10px 16px",
              gap: "8px",
              borderRadius: "30",
              backgroundColor: "#4C8C4A",
            }}
            disabled={isEmpty(defaultAssignees) || isEmpty(options)}
          >
            Assign
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AssignItemModal;
