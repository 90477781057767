import React, { useState, useEffect, useCallback } from "react";
import { InputAdornment, TextField } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import InputMask from "react-input-mask";
import debounce from "lodash.debounce";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/authSlice";

const MyAccount = ({ setAccountDetailChanges }) => {
  const userData = useSelector(selectCurrentUser);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [accountDetail, setAccountDetail] = useState({
    name: "",
    email: "",
    phone_number: "",
    password_mask: "",
    existing_password: "",
    new_password: "",
    confirm_new_password: "",
  });

  const debouncedSetChanges = useCallback(
    debounce((updatedChanges) => setAccountDetailChanges(updatedChanges), 300),
    []
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAccountDetail((prevDetail) => {
      const updatedAccountDetail = { ...prevDetail, [name]: value };

      if (value !== userData[name]) {
        debouncedSetChanges((prevChanges) => ({
          ...prevChanges,
          [name]: value,
        }));
      } else {
        debouncedSetChanges((prevChanges) => {
          const updatedChanges = { ...prevChanges };
          delete updatedChanges[name];
          return updatedChanges;
        });
      }

      return updatedAccountDetail;
    });
  };

  useEffect(() => {
    if (userData) {
      setAccountDetail((prev) => ({
        ...prev,
        name: userData?.name || "",
        email: userData?.email || "",
        phone_number: userData?.phone_number || "",
        password_mask: userData?.pswd_mask || "",
      }));
    }
  }, [userData]);

  return (
    <div className="flex flex-col gap-6 p-8 bg-white rounded-lg w-full">
      <div className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Basic information
      </div>
      <div className="flex flex-col lg:flex-row gap-16 justify-between">
        <div className="flex flex-col gap-5 w-full">
          <div className="flex flex-col gap-2">
            <div className="w-[480px] h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              Name
            </div>
            <TextField
              id="name"
              placeholder="Mattew Paker"
              variant="outlined"
              name="name"
              value={accountDetail.name}
              onChange={handleInputChange}
            />
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Password
              </div>
              <div
                className="text-right text-green-700 text-xs font-normal font-['Questrial'] underline leading-none cursor-pointer"
                onClick={() => setIsEditingPassword(true)}
              >
                Edit password
              </div>
            </div>
            <TextField
              id="password"
              type="password"
              variant="outlined"
              value={accountDetail.password_mask}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <BlockIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Contact email
              </div>
              <TextField
                id="contactEmail"
                placeholder="Please enter"
                variant="outlined"
                name="email"
                value={accountDetail.email}
                onChange={handleInputChange}
              />
            </div>

            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Phone number
              </div>
              <InputMask
                mask="(999) 999-9999"
                value={accountDetail.phone_number}
                onChange={handleInputChange}
              >
                {() => (
                  <TextField
                    id="phone_number"
                    name="phone_number"
                    type="tel"
                    placeholder="(123) 456-7890"
                    className="flex-1"
                    variant="outlined"
                  />
                )}
              </InputMask>
            </div>
          </div>

          {isEditingPassword && (
            <>
              <div className="flex flex-col sm:flex-row gap-4">
                <div className="flex flex-col gap-2 w-full">
                  <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                    Existing Password
                  </div>
                  <TextField
                    id="existing-password"
                    type="password"
                    variant="outlined"
                    name="existing_password"
                    value={accountDetail.existing_password}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                    New Password
                  </div>
                  <TextField
                    id="new-password"
                    type="password"
                    variant="outlined"
                    name="new_password"
                    value={accountDetail.new_password}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <div className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                    Confirm New Password
                  </div>
                  <TextField
                    id="confirm-password"
                    type="password"
                    variant="outlined"
                    name="confirm_new_password"
                    value={accountDetail.confirm_new_password}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              {accountDetail.new_password !==
                accountDetail.confirm_new_password && (
                <p className="text-red-400 text-xs font-normal font-['Questrial']">
                  Password mismatch!
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
