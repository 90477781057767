import React from "react";
import Chip from "./Chip";

const OrderDetail = ({
  orderId,
  customer,
  items,
  estCompletionDate,
  timeLeft,
  status,
}) => {

  return (
    <div className="flex justify-between items-center gap-4 p-4">
      <div className="flex flex-col gap-2 items-start">
        <Chip status={status} />
        <p className="w-[100px] text-zinc-600 text-xs font-normal font-['Questrial'] leading-none">
          Order ID: {orderId}
        </p>
        <p className="self-stretch text-zinc-600 text-xs font-normal font-['Questrial'] leading-none">
          Customer: {customer}
        </p>
        <p className="self-stretch text-zinc-600 text-xs font-normal font-['Questrial'] leading-none">
          Item: {items}
        </p>
      </div>
      <div className="flex flex-col gap-2 items-start border-l-2 pl-4 border-stone-300">
        <p className="text-zinc-600 text-xs font-normal font-['Questrial'] leading-none">
          Est. completion date:
        </p>
        <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
          {estCompletionDate}
        </p>
        {timeLeft && (
          <p className="text-red-500 text-xs font-normal font-['Questrial'] leading-none">
            {timeLeft}
          </p>
        )}
      </div>
    </div>
  );
};

export default OrderDetail;
