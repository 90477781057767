import { Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import EntityPage from "../components/EntityPage";
import TextInput from "../components/Form/Field/TextInput";
import FieldGroup from "../components/Form/FieldGroup";
import FieldItem from "../components/Form/FieldItem";
import FormPage from "../components/Form/FormPage";

import { getRequest, updateRequest } from "../api";

import { selectCurrentStore } from "../redux/storeSlice";
import { addToast } from "../redux/toastSlice";
import { fetchMainCategories } from "../redux/storeAction";
import { POS } from "../constants/FrontendRoutes";

const EditCategory = () => {
  const { id } = useParams();
  const [service, setService] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentStore = useSelector(selectCurrentStore);

  useEffect(() => {
    if (currentStore) fetchServiceTypeData();
  }, [currentStore]);

  const fetchServiceTypeData = async () => {
    try {
      const res = await getRequest(
        `stores/${currentStore?.store_id}/categories/${id}`
      );
      if (res) setService(res);
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  const handleInputChange = (name, value) => {
    setService((prevService) => ({
      ...prevService,
      [name]: value,
    }));
  };

  const handleUpdateCategory = async () => {
    try {
      const { name } = service;
      const res = await updateRequest(
        `stores/${currentStore?.store_id}/categories/${id}`,
        { category: { name } }
      );

      if (res.status === 200) {
        navigate(POS);
        dispatch(fetchMainCategories(currentStore?.store_id));
        dispatch(addToast("Category updated successfully."));
      }
    } catch (error) {
      dispatch(addToast("Failed to update category."));
    }
  };

  if (!service) return null;

  return (
    <EntityPage title="Edit" breadcrumbs stickyBottomBar>
      <FormPage>
        <FieldGroup label="Main Category Details">
          <FieldItem>
            <Typography width="30%" variant="body">
              Main Category
            </Typography>
            <TextInput
              name="name"
              value={service.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
          </FieldItem>
        </FieldGroup>

        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "16px 0",
            display: "flex",
            flexDirection: "row-reverse",
            gap: "28px",
          }}
          elevation={8}
        >
          <div className="flex flex-row gap-3 mx-5">
            <Button variant="outlinedPrimary" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              variant="containedPrimary"
              onClick={handleUpdateCategory}
            >
              Update
            </Button>
          </div>
        </Paper>
      </FormPage>
    </EntityPage>
  );
};

export default EditCategory;
