import { Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import EntityPage from "../components/EntityPage";
import AutoCompleteInput from "../components/Form/Field/AutoComplete";
import TextInput from "../components/Form/Field/TextInput";
import FieldGroup from "../components/Form/FieldGroup";
import FieldItem from "../components/Form/FieldItem";
import FormPage from "../components/Form/FormPage";

import { getRequest, updateRequest } from "../api";
import { COMMON_STATUS, SERVICE_TYPE } from "../constants";

import { selectCurrentStore } from "../redux/storeSlice";
import { addToast } from "../redux/toastSlice";
import { fetchServiceTypes } from "../redux/storeAction";

const EditServiceType = () => {
  const { id } = useParams();
  const [service, setService] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentStore = useSelector(selectCurrentStore);
  

  useEffect(() => {
    if (currentStore) {
      fetchServiceTypeData();
    }
  }, [currentStore]);

  const fetchServiceTypeData = async () => {
    try {
      const res = await getRequest(
        `stores/${currentStore?.store_id}/service_types/${id}`
      );
      if (res) setService(res);
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  const handleInputChange = (name, value) => {
    setService((prevService) => ({
      ...prevService,
      [name]: value,
    }));
  };

  const handleUpdateServiceType = async () => {
    try {
      const { name } = service;

      const res = await updateRequest(
        `stores/${currentStore?.store_id}/service_types/${id}`,
        { service_type: { name } }
      );

      if (res.status === 200) {
        navigate(-1);
        dispatch(fetchServiceTypes(currentStore?.store_id));
        dispatch(addToast("Service Type(s) updated successfully."));
      }
    } catch (error) {
      dispatch(addToast("Failed to update service."));
    }
  };

  if (!service) return null;

  return (
    <EntityPage title="Edit Service Type" breadcrumbs stickyBottomBar>
      <FormPage>
        <FieldGroup label="Service Type Details">
          <FieldItem>
            <Typography width="30%" variant="body">
              Type Name
            </Typography>
            <TextInput
              name="name"
              value={service.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
          </FieldItem>
        </FieldGroup>

        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "16px 0",
            display: "flex",
            flexDirection: "row-reverse",
            gap: "28px",
          }}
          elevation={8}
        >
          <div className="flex flex-row gap-3 mx-5">
            <Button variant="outlinedPrimary" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button variant="containedPrimary" onClick={handleUpdateServiceType}>
              Update
            </Button>
          </div>
        </Paper>
      </FormPage>
    </EntityPage>
  );
};

export default EditServiceType;
