import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { IconButton, TextField, Button, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Selector from "../Form/Field/AutoComplete";
import {
    ADDRESS_TYPE_ENUM,
  COUNTRIES,
  CUSTOMER_ADDRESS_TYPE_OPTIONS,
  STATES_PER_COUNTRY,
} from "../../constants";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import { postRequest } from "../../api";
import { useSelector } from "react-redux";
import { selectCurrentOrganization } from "../../redux/organizationSlice";
import { setLoading } from "../../redux/loadingSlice";
import { addToast } from "../../redux/toastSlice";
import { fetchUserData } from "../../redux/authActions";
import { selectCurrentUser } from "../../redux/authSlice";

const INITIAL_STATE = {
  store_name: "",
  email: "",
  phone_number: "",
  country: COUNTRIES[0]?.value,
  line: "",
  city: "",
  state: "",
  zipcode: "",
};
export default function StoreModal({
  open,
  close,
  onSubmit,
}) {
  const [formState, setFormState] = useState();
  const currentOrganization = useSelector(selectCurrentOrganization);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectorChange = (field) => (_, value) => {
    setFormState((prevState) => {
      const updatedState = { ...prevState, [field]: value?.value };

      if (field === "country") {
        return {
          ...updatedState,
          line: "",
          city: "",
          state: "",
          zipcode: "",
        };
      }

      return updatedState;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      dispatch(setLoading(true));
      const addressAttributes = (formState, addressType) => ({
        line: formState?.line,
        zipcode: formState?.zipcode,
        city: formState?.city,
        state: formState?.state,
        country: formState?.country,
        address_type: addressType,
      });

      const response = await postRequest(`/stores`, {
        store: {
          name: formState?.store_name,
          phone_number: formState?.phone_number,
          email: formState?.email,
          organization_id: currentOrganization?.id,
          addresses_attributes: [
            addressAttributes(formState, ADDRESS_TYPE_ENUM.shop),
            addressAttributes(formState, ADDRESS_TYPE_ENUM.shipping),
          ],
        },
      });

      if (response?.status === 201)
        dispatch(fetchUserData({ id: currentUser.id }));
    } catch(err){
      dispatch(addToast(err));
    } finally{
      dispatch(setLoading(false));
      handleClose();
    }
  };

  const handleClose = () => {
    close(false);
    setFormState(INITIAL_STATE);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: "20px",
          right: "8px",
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <div className="flex flex-col gap-5 w-full bg-white justify-between">
        <div className="flex flex-col gap-2 mx-8 mt-8">
          <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
            Add New Store
          </p>
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Add a new Store Location by filling in the required details below
          </p>
        </div>
        <form className="flex flex-col gap-2 mx-8 mb-8" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-1">
            <label className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              Store name
            </label>
            <TextField
              placeholder="Please enter"
              variant="outlined"
              name="store_name"
              required={true}
              value={formState?.store_name}
              onChange={handleOnChange}
            />
          </div>

          <div className="flex flex-col md:flex-row gap-4 md:gap-1">
            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Email
              </div>
              <TextField
                name="email"
                placeholder="example@.com"
                type="email"
                variant="outlined"
                value={formState?.email}
                onChange={handleOnChange}
                required
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Phone number
              </div>
              <InputMask
                mask="(999) 999-9999"
                value={formState?.phone_number}
                onChange={handleOnChange}
              >
                {() => (
                  <TextField
                    id="phone_number"
                    name="phone_number"
                    type="tel"
                    placeholder="(123) 456-7890"
                    className="flex-1"
                    variant="outlined"
                  />
                )}
              </InputMask>
            </div>
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row gap-2 md:gap-1 w-full">
            <div className="flex flex-col gap-1 basis-1/3">
              <label className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Country
              </label>
              <Selector
                name="country"
                label="Please select"
                size="large"
                required={true}
                options={COUNTRIES}
                value={COUNTRIES.find(
                  (country) => country?.value === formState?.country
                )}
                onChange={handleSelectorChange("country")}
              />
            </div>
            <div className="flex flex-col gap-1 basis-2/3">
              <label className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Address Line
              </label>
              <TextField
                placeholder="Please enter"
                variant="outlined"
                name="line"
                required={true}
                value={formState?.line}
                onChange={handleOnChange}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-4 md:gap-1">
            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                City
              </div>
              <TextField
                name="city"
                placeholder="New York"
                variant="outlined"
                value={formState?.city}
                onChange={handleOnChange}
                required
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                State
              </div>
              <Selector
                name="state"
                label="Please select"
                size="large"
                required={true}
                onChange={handleSelectorChange("state")}
                options={STATES_PER_COUNTRY[formState?.country] || []}
                value={
                  STATES_PER_COUNTRY[formState?.country]?.find(
                    (state) => state?.value === formState?.state
                  ) || null
                }
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Zip/Postal Code
              </div>
              <InputMask
                mask={formState?.country === "CA" ? "a9a 9a9" : "99999"}
                value={formState?.zipcode}
                onChange={handleOnChange}
              >
                {() => (
                  <TextField
                    type="text"
                    name="zipcode"
                    placeholder="12345"
                    className="flex-1"
                    variant="outlined"
                    required
                  />
                )}
              </InputMask>
            </div>
          </div>

          <div className="flex flex-col gap-2 md:flex-row w-full mt-5">
            <Button variant="outlinedSecondary" fullWidth onClick={handleClose}>
              Back
            </Button>
            <Button variant="containedPrimary" fullWidth type="submit">
              Save
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
}
