import React, { useState, useEffect, useRef } from "react";
import { Divider } from "@mui/material";
import { groupedBy } from "../../../../utils";
import MenuOptionButton from "../../../Button/MenuOptionButton";
import EditableServicePrice from "./EditableServicePrice";
import { useMediaQuery } from "react-responsive";
import { POS_NEW_ORDER_STEPS } from "../../../../constants";
import { useDispatch } from "react-redux";
import { selectCurrentCart, updateCart } from "../../../../redux/cartSlice";
import { useSelector } from "react-redux";
import { selectCurrentStep, updateCurrentStep } from "../../../../redux/currentStepSlice";

const renderPrice = (item, isEditable, trackingCallback) => {
  return isEditable ? (
    <EditableServicePrice value={item?.price} onChange={trackingCallback} />
  ) : (
    <span>${parseFloat(item?.price).toFixed(2)}</span>
  );
};

const ServiceItem = ({
  item,
  index,
  setReplica,
  editMode,
  setEditMode,
  setDuplicateMode,
  editingId,
  setEditingId,
  setTrackServiceItemPrices,
}) => {
  const { category, categoryType, services, options, quantity, description } = item;
  const groupedServices = groupedBy(services, "type");
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1024px)" });

  const dispatch = useDispatch();
  const cart = useSelector(selectCurrentCart);
  const currentStep = useSelector(selectCurrentStep);

  const removeServiceFromCart = (uniqStr) => {
    dispatch(
      updateCart({
        ...cart,
        serviceLineItems: cart.serviceLineItems.filter(
          (serviceItem) => serviceItem.uniqStr !== uniqStr,
        ),
      }),
    );
  };

  const handlePriceChange = (newPrice, itemId, itemType) => {
    const key = itemType === "service" ? "services" : "options";

    setTrackServiceItemPrices((prevState) => ({
      ...prevState,
      [key]: prevState[key].map((item) =>
        item.id === itemId ? { ...item, price: newPrice } : item,
      ),
    }));
  };

  const menuButtonConfig = [
    {
      name: "Edit",
      function: () => {
        setReplica(item);
        setDuplicateMode(false);
        removeServiceFromCart(item.uniqStr);
        if (!isDesktopScreen) dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.ADD_ITEM));
      },
    },
    {
      name: "Edit price",
      function: () => {
        setEditMode(true);
        setEditingId(item.uniqStr);
        setTrackServiceItemPrices(item);
      },
    },
    {
      name: "Duplicate",
      function: () => {
        setDuplicateMode(true);
        setReplica(item);
        if (!isDesktopScreen) dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.ADD_ITEM));
      },
    },
    {
      name: "Remove",
      function: () => removeServiceFromCart(item.uniqStr),
    },
  ];

  useEffect(() => {
    if (item) {
      setTrackServiceItemPrices(item);
    }
  }, [item]);

  return (
    <>
      <Divider />
      <div className="mx-4 my-2">
        <div className="flex justify-between items-center">
          <span>
            <h2 className="text-xs text-[#5D5C5A] mb-1">
              Item {index + 1} - {category}
            </h2>
            <p className="text-[#272523]">{categoryType}</p>
            {quantity && (
              <div className="text-[#5d5c5a] text-xs font-normal font-['Questrial'] leading-none mt-1">
                Quantity: {quantity}
              </div>
            )}
          </span>
          <span>
            {(currentStep === POS_NEW_ORDER_STEPS.ADD_ITEM ||
              currentStep === POS_NEW_ORDER_STEPS.ORDER_CART) && (
              <MenuOptionButton data={menuButtonConfig} />
            )}
          </span>
        </div>
        <Divider sx={{ my: 1 }} />
        {Object.keys(groupedServices).map((key) => (
          <React.Fragment key={key}>
            <div className="flex flex-col gap-2">
              <h2 className="text-xs text-[#5D5C5A]">{key}</h2>
              <div className="flex flex-col gap-1">
                {groupedServices[key].map((service, idx) => (
                  <div
                    className="flex justify-between items-center text-[#272523] w-full"
                    key={idx}
                  >
                    <p className="">{service?.name}</p>
                    {renderPrice(service, editMode && editingId === item?.uniqStr, (newPrice) =>
                      handlePriceChange(newPrice, service.id, "service"),
                    )}
                  </div>
                ))}
              </div>
            </div>
          </React.Fragment>
        ))}

        {!!options?.length > 0 && (
          <>
            <Divider sx={{ my: 1 }} />
            <div className="flex flex-col gap-2">
              <h3 className="text-xs text-[#5D5C5A]">Options</h3>
              <div className="flex flex-col gap-1">
                {options?.map((option, idx) => (
                  <div
                    className="flex justify-between items-center text-[#272523] w-full"
                    key={idx}
                  >
                    <p className="">{option?.name}</p>
                    {renderPrice(option, editMode && editingId === item?.uniqStr, (newPrice) =>
                      handlePriceChange(newPrice, option.id, "option"),
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        {!!description && (
          <>
            <Divider sx={{ my: 2 }} />
            <h3 className="text-xs text-[#5D5C5A] mb-1">Descriptions</h3>
            <p className="text-[#272523] mb-4">{description}</p>
          </>
        )}
      </div>
    </>
  );
};

export default ServiceItem;
