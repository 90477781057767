import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
  DETAILS,
  ONBOARDING,
  SCANNER,
} from "../../constants/FrontendRoutes";
import NavBar from "../NavBar";
import "./Layout.css";

const Layout = () => {
  const location = useLocation();

  const shouldShowNavBar = () => {
    const currentPath = location.pathname;
    const shouldShowNavBar = !(
      currentPath.includes(SCANNER) ||
      currentPath.includes(DETAILS) ||
      currentPath.includes(ONBOARDING)
    );
    return shouldShowNavBar;
  };

  return (
    <div>
      {shouldShowNavBar() && <NavBar />}
      <main
        className={shouldShowNavBar() ? "main-content" : "w-full"}
      >
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
