import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { LeftArrowIcon } from "../../../common/icons";
import { POS_NEW_ORDER_STEPS, USER_ROLES } from "../../../constants";
import Selector from "../../Form/Field/AutoComplete";
import CartBadge from "./CartBadge";
import { useDispatch } from "react-redux";
import { selectCurrentCart, updateCart } from "../../../redux/cartSlice";
import { useSelector } from "react-redux";
import {
  updateCurrentStep,
} from "../../../redux/currentStepSlice";
import { useNavigate } from "react-router-dom";
import { CORPORATE_NEW } from "../../../constants/FrontendRoutes";
import { getRequest } from "../../../api";
import { selectCurrentStore } from "../../../redux/storeSlice";
import { addToast } from "../../../redux/toastSlice";

const SelectCorporate = () => {
  const [allCorporate, setAllCorporate] = useState([]);
  const [currentCorporate, setCurrentCorporate] = useState();

  const currentStore = useSelector(selectCurrentStore);
  const cart = useSelector(selectCurrentCart);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1024px)" });

  const handleSelectorChange = (e) => {
    setCurrentCorporate(e);
    dispatch(updateCart({ ...cart, customer: e?.value }));
  }

  const handleAttachCorporate = () => {
    dispatch(
      updateCart({
        ...cart,
        customer: null,
        isCorporateCustomer: true,
        attached_customer: currentCorporate?.value,
      })
    );
    dispatch(
      updateCurrentStep(
        isDesktopScreen
          ? POS_NEW_ORDER_STEPS.ADD_ITEM
          : POS_NEW_ORDER_STEPS.ORDER_CART
      )
    );
  }

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getRequest(
          `stores/${currentStore?.store_id}/users`,
          {
            filters: { role: [USER_ROLES.CORPORATE] },
          }
        );

        if (data.length > 0) setAllCorporate(data);
      } catch (error) {
        dispatch(addToast(error))
      }
    })();
  }, []);

  return (
    <Box className="relative flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <MediaQuery minWidth={1024}>
        <Box
          className="absolute flex items-center gap-1 top-[2.5%] left-[2.5%] cursor-pointer"
          onClick={() => {
            dispatch(updateCart({ ...cart, payLater: false }));
            dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.SELECT_CUSTOMER));
          }}
        >
          <LeftArrowIcon width={18} height={19} />
          <span>Back</span>
        </Box>
      </MediaQuery>

      <Box className="flex h-full sm:h-auto flex-col justify-between bg-[#F8F8F8] lg:bg-white w-full sm:max-w-[70%] xl:max-w-[50%] sm:m-auto gap-6">
        <div className="flex flex-col">
          <CartBadge />
          <Box className="flex flex-col gap-4">
            <h1 className="text-lg md:text-2xl font-semibold md:font-bold font-['Montserrat'] leading-[25.20px]">
              Corporate customer
            </h1>
            <Typography>
              You're a partner corporation with us? Please select your
              corporation below
            </Typography>

            <Typography>Corporation</Typography>
            <Selector
              name="Corporation"
              label="Please select"
              newItemTitle="Add new corporation"
              options={allCorporate?.map((item) => ({
                title: item?.name,
                value: item,
              }))}
              value={currentCorporate}
              onChange={(_e, value) => handleSelectorChange(value)}
              handleNewItemClick={() => navigate(CORPORATE_NEW)}
            />
          </Box>
        </div>

        <Button
          variant="containedPrimary"
          disabled={!currentCorporate}
          onClick={handleAttachCorporate}
        >
          Attach
        </Button>
      </Box>
    </Box>
  );
};

export default SelectCorporate;
