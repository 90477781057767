import React, { useEffect, useState } from "react";
import Chip from "../Chip";

const Overview = ({ userData, currentStore }) => {
  const [orders, setOrders] = useState([]);

  const address = userData?.addresses?.find(
    (address) => address?.address_type === "shipping"
  );

  const amountSpent = () => {
    return `$${orders
      ?.filter((order) => order.status !== "quote")
      ?.reduce((sum, order) => sum + order.net_total, 0)
      ?.toFixed(2)}`;
  }

  const activeOrders = () => {
    return orders?.filter((order) =>
      ["new_order", "shipping", "in_progress", "delayed"].includes(
        order?.status
      )
    ).length;
  }

  useEffect(() => {
    if (currentStore?.id) {
      const filtered = (userData?.orders || []).filter(
        (order) => order?.store_id === currentStore?.id
      );
      setOrders(filtered);
    }
  }, [currentStore, userData?.orders]);

  return (
    <section className="bg-white px-[24px] py-[32px] flex flex-col gap-[16px] rounded-[8px]">
      <div className="flex flex-col gap-[4px]">
        <div className="text-[#939291] font-['Questrial'] font-normal text-[16px]">
          Customer #{userData?.id}
        </div>
        <div className="flex items-center gap-[8px]">
          <div className="text-[#272523] font-bold font-['Montserrat'] text-[24px]">
            {userData?.name}
          </div>
          <Chip status="new_customer" />
        </div>
      </div>
      <div className="h-[0.5px] bg-[#C9C8C8]" />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div className="font-['Questrial'] text-[16px] font-normal text-[#939291] flex flex-col">
          <span>Phone number:</span>
          <span className="text-[#272523]">
            {userData?.phone_number || "N/A"}
          </span>
        </div>
        <div className="font-['Questrial'] text-[16px] font-normal text-[#939291] flex flex-col">
          <span>Email address:</span>
          <span className="text-[#272523]">{userData?.email}</span>
        </div>
        <div className="font-['Questrial'] text-[16px] font-normal text-[#939291] flex flex-col">
          <span>Shipping address:</span>
          <span className="text-[#272523]">
            {address?.human_readable || "N/A"}
          </span>
        </div>
      </div>
      <div className="h-[0.5px] bg-[#C9C8C8]" />
      <div className="text-[#272523] font-['Montserrat'] font-[600] text-[18px] mb-[16px]">
        Customer Summary
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <div className="flex flex-col gap-2 ">
          <span className="text-2xl font-semibold">
            {orders?.length}
          </span>
          <span className="text-gray-500">Total orders</span>
        </div>
        <div className="sm:border-r-[0.5px] sm:border-r-[#c9c8c8] sm:border-l-[0.5px] sm:border-l-[#c9c8c8]">
          <div className="w-fit sm:mx-auto flex flex-col gap-2 ">
            <span className="text-2xl font-semibold">{activeOrders()}</span>
            <span className="text-gray-500">Active orders</span>
          </div>
        </div>
        <div className="flex flex-col gap-2 sm:mx-auto">
          <span className="text-2xl font-semibold">{amountSpent()}</span>
          <span className="text-gray-500">Total Spend</span>
        </div>
      </div>
    </section>
  );
};

export default Overview;
