import React, { useEffect, useRef, useState } from "react";
import isEqual from "lodash/isEqual";
import { Box } from "@mui/material";
import MediaQuery, { useMediaQuery } from "react-responsive";

import AddItem from "../components/POS/NewOrder/AddItem";
import CalculatePayment from "../components/POS/NewOrder/CalculatePayment";
import CreateCustomer from "../components/POS/NewOrder/CreateCustomer";
import EntityPage from "../components/EntityPage";
import NewDiscount from "../components/POS/NewOrder/NewDiscount";
import CartDetail from "../components/POS/NewOrder/CartDetail";
import PaymentMethod from "../components/POS/NewOrder/PaymentMethod";
import SelectDiscount from "../components/POS/NewOrder/SelectDiscount";
import SelectCustomer from "../components/POS/NewOrder/SelectCustomer";

import { POS_NEW_ORDER_STEPS } from "../constants";
import OrderSuccess from "../components/POS/NewOrder/OrderSuccess";
import { selectCurrentOrganization, selectCustomizeTaxes } from "../redux/organizationSlice";
import { selectCurrentStore } from "../redux/storeSlice";
import ManualCardPayment from "../components/POS/NewOrder/ManualCardPayment";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../redux/toastSlice";
import ScanCard from "../components/POS/NewOrder/ScanCard";
import AttachCustomer from "../components/POS/NewOrder/AttachCustomer";
import { selectCurrentCart, updateCart } from "../redux/cartSlice";
import { getPOS, getStateTaxes } from "../api/pos";
import {
  calculateDiscount,
  calculateProductSubtotal,
  calculateProductsSalesTax,
  calculateServiceSubtotal,
  calculateServicesSalesTax,
} from "../utils";
import PrintComponent from "../components/PrintComponent";
import { selectCurrentStep, updateCurrentStep } from "../redux/currentStepSlice";
import SelectCorporate from "../components/POS/NewOrder/SelectCorporate";
import { fetchCustomizeTaxes } from "../redux/organizationAction";

const NewOrder = () => {
  const [replica, setReplica] = useState();
  const [products, setProducts] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [previouslyDesktopScreen, setPreviouslyDesktopScreen] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [duplicateMode, setDuplicateMode] = useState(false);

  const currentStep = useSelector(selectCurrentStep);
  const cart = useSelector(selectCurrentCart);

  const prevCartRef = useRef(cart);
  const dispatch = useDispatch();
  const currentOrganization = useSelector(selectCurrentOrganization);
  const customizeTaxes = useSelector(selectCustomizeTaxes);
  const currentStore = useSelector(selectCurrentStore);
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1024px)" });

  const addLineItem = (newItem, itemType) => {
    const updatedCart = {
      ...cart,
      [`${itemType}LineItems`]: [...cart[`${itemType}LineItems`], newItem],
    };
    dispatch(updateCart(updatedCart));
  };

  const getActiveComponent = () => {
    switch (currentStep) {
      case POS_NEW_ORDER_STEPS.ADD_ITEM:
        return (
          <AddItem
            replica={replica}
            setReplica={setReplica}
            editMode={editMode}
            setEditMode={setEditMode}
            duplicateMode={duplicateMode}
            setDuplicateMode={setDuplicateMode}
            products={products}
            subCategories={subCategories}
            addServiceLineItem={(newItem) => addLineItem(newItem, "service")}
            addProductLineItem={(newItem) => addLineItem(newItem, "product")}
          />
        );
      case POS_NEW_ORDER_STEPS.CREATE_NEW_DISCOUNT:
        return <NewDiscount />;
      case POS_NEW_ORDER_STEPS.ADD_DISCOUNT:
        return <SelectDiscount />;
      case POS_NEW_ORDER_STEPS.PAYMENT_METHOD:
        return <PaymentMethod />;
      case POS_NEW_ORDER_STEPS.CALCULATE_PAYMENT:
        return <CalculatePayment />;
      case POS_NEW_ORDER_STEPS.CARD_PAYMENT:
        return <ManualCardPayment />;
      case POS_NEW_ORDER_STEPS.SCAN_CARD:
        return <ScanCard />;
      case POS_NEW_ORDER_STEPS.SELECT_CUSTOMER:
        return <SelectCustomer />;
      case POS_NEW_ORDER_STEPS.SELECT_CORPORATE:
        return <SelectCorporate />;
      case POS_NEW_ORDER_STEPS.CREATE_CUSTOMER:
        return <CreateCustomer />;
      case POS_NEW_ORDER_STEPS.ATTACH_CUSTOMER:
        return <AttachCustomer />;
      case POS_NEW_ORDER_STEPS.ORDER_SUCCESS:
        return <OrderSuccess />;
      case POS_NEW_ORDER_STEPS.ORDER_CART:
        return (
          <CartDetail
            setReplica={setReplica}
            editMode={editMode}
            setEditMode={setEditMode}
            setDuplicateMode={setDuplicateMode}
          />
        );
      default:
        return;
    }
  };

  const setupTaxesInfo = async (currentStore) => {
    const { store } = currentStore;
    const addr = store?.addresses?.find((i) => i.address_type === "shop");

    if (addr?.country === 'CA') {
      const product_sales_tax = customizeTaxes?.reduce(
        (total, tax) => total + (tax?.product_sales_tax || 0), 0
      )
      const service_sales_tax = customizeTaxes?.reduce(
        (total, tax) => total + (tax?.service_sales_tax || 0), 0
      );
      return { product_sales_tax, service_sales_tax  };
    } else if (addr?.country === 'US') {
      const taxes = await getStateTaxes(addr?.state, dispatch);
      return taxes;
    } else if(store?.addresses?.length < 0) {
      dispatch(addToast("Please provide Shop address to calculate sales tax."));
    }
  };

  useEffect(() => {
    if (cart) {
      dispatch(updateCart(cart));
      const prevCart = prevCartRef.current;
      const { serviceLineItems, productLineItems, discount, rushOrder } = cart;
      const updatedCart = { ...cart };

      let hasChanges = false;
      const isDiscountChanged = !isEqual(prevCart?.discount, discount);
      const isServiceLineItemsChanged = !isEqual(prevCart?.serviceLineItems, serviceLineItems);
      const isProductLineItemsChanged = !isEqual(prevCart?.productLineItems, productLineItems);
      const isRushOrderChanged = !isEqual(prevCart?.rushOrder, rushOrder);

      if (
        isServiceLineItemsChanged ||
        isRushOrderChanged ||
        isProductLineItemsChanged ||
        isDiscountChanged
      ) {
        const serviceTotal = calculateServiceSubtotal(serviceLineItems, rushOrder);
        const productTotal = calculateProductSubtotal(productLineItems);

        updatedCart.serviceSubTotal = serviceTotal;
        updatedCart.productSubTotal = productTotal;
        updatedCart.totalDiscount = calculateDiscount(serviceTotal, productTotal, discount);
        updatedCart.serviceSalesTax = calculateServicesSalesTax(
          serviceTotal,
          productTotal,
          discount,
          cart.serviceSalesTaxRate,
        );
        updatedCart.productSalesTax = calculateProductsSalesTax(
          serviceTotal,
          productTotal,
          discount,
          cart.productSalesTaxRate,
        );

        hasChanges = true;
      }

      if (hasChanges) {
        dispatch(updateCart(updatedCart));
        prevCartRef.current = cart;
        return;
      }
    }
  }, [cart]);

  useEffect(()=>{
  if(customizeTaxes && currentOrganization?.id){
      const setupCustomizesPOS = async () => {
        const taxResponse = await setupTaxesInfo(currentStore);
        dispatch(
          updateCart({
            ...cart,
            productSalesTaxRate: taxResponse?.product_sales_tax,
            serviceSalesTaxRate: taxResponse?.service_sales_tax,
          }))
      }
      setupCustomizesPOS();
    }else{
      dispatch(fetchCustomizeTaxes({ organizationId: currentOrganization?.id }));
    }
  },[customizeTaxes, currentOrganization?.id])

  useEffect(() => {
    const setupPOS = async () => {
      if (currentStore) {

        await getPOS(setProducts, setSubCategories, currentStore, dispatch);
        const taxResponse = await setupTaxesInfo(currentStore);

        dispatch(
          updateCart({
            ...cart,
            productSalesTaxRate: taxResponse?.product_sales_tax,
            serviceSalesTaxRate: taxResponse?.service_sales_tax,
            rushOrder: { ...cart.rushOrder, price: currentStore?.store?.rush_fee },
          })
        );
      }
    };

    setupPOS();
  }, [currentStore]);

  useEffect(() => {
    if (isDesktopScreen && !previouslyDesktopScreen) {
      if (currentStep === POS_NEW_ORDER_STEPS.ORDER_CART)
        dispatch(selectCurrentStep(POS_NEW_ORDER_STEPS.ADD_ITEM));
    }

    setPreviouslyDesktopScreen(isDesktopScreen);
  }, [isDesktopScreen]);

  useEffect(() => {
    dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.ADD_ITEM));
  }, []);

  return (
    <EntityPage
      title="New Order"
      className="bg-[#F8F8F8]"
      classNameLayout="hidden md:flex p-0 md:p-5"
    >
      <Box className="flex gap-8 w-full h-[calc(100vh-67px-40px)] md:h-[calc(100vh-80px-40px)]">
        <MediaQuery minWidth={1024}>
          <CartDetail
            setReplica={setReplica}
            editMode={editMode}
            setEditMode={setEditMode}
            setDuplicateMode={setDuplicateMode}
          />
        </MediaQuery>
        {getActiveComponent()}
      </Box>
      {cart?.data && <PrintComponent currentOrder={cart.data} />}
    </EntityPage>
  );
};

export default NewOrder;
