import React from "react";
import StripeConnect from "./StripeConnect";
import ShipStation from "./ShipStation";

const SettingIntegrations = ({ id }) => {
  return (
    <div className="flex flex-col gap-6 p-8 bg-white rounded-lg w-full">
      <div className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Integrations
      </div>
      <div className="w-full justify-between rounded-lg xl:items-center border border-stone-300 p-7">
        <StripeConnect/>
        <hr className="my-4" />
        <ShipStation/>
      </div>
    </div>
  );
};

export default SettingIntegrations;
