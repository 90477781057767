import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { selectCurrentUser, setCurrentUser } from "../../redux/authSlice";
import { useSelector } from "react-redux";
import { Add } from "@mui/icons-material";
import ActionMenu from "../Button/ActionMenu";
import CustomTable from "../Table";
import { deleteRequest, postRequest, updateRequest } from "../../api";
import { useDispatch } from "react-redux";
import { addToast } from "../../redux/toastSlice";
import { CONTACTS, USERS } from "../../constants/BackendRoutes";
import ContactPersonModal from "../Modal/ContactPersonModal";

const columns = [
  {
    field: "name",
    label: "Name",
    width: "300px",
  },
  {
    field: "email",
    label: "Contact email",
    width: "300px",
  },
  {
    field: "phone_number",
    label: "Phone number",
    width: "300px",
  },
  {
    field: "actions",
    label: "",
    width: "100px",
    component: ActionMenu,
  },
];

const ContactPerson = ({}) => {
  const userData = useSelector(selectCurrentUser);
  const [isModalOpen, setModalOpen] = useState(false);
  const [contactPersonToEdit, setContactPersonToEdit] = useState(null);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  const transformContacts = (contacts, handleEditModalOpen, handleRemove) => {
    return contacts?.map((item) => ({
      id: item.id,
      name: item?.name,
      email: item?.email,
      phone_number: item?.phone_number,
      actionsProps: {
        listOfActions: [
          { label: "Edit", handler: handleEditModalOpen },
          { label: "Remove", handler: handleRemove },
        ],
      },
    }));
  };

  const handleSubmit = async (contact) => {
    try {
      const isEditRequest = !!contactPersonToEdit;
      const url = isEditRequest
        ? `${CONTACTS}/${contact?.id}`
        : `${USERS}/${userData?.id}${CONTACTS}`;
      const requestMethod = isEditRequest ? updateRequest : postRequest;
      const response = await requestMethod(url, { contact });

      if (response) {
        dispatch(
          setCurrentUser({
            ...userData,
            contacts: isEditRequest
              ? userData.contacts.map((co) =>
                  co.id === contact?.id ? { ...co, ...response.data } : co
                )
              : [...userData.contacts, response.data],
          })
        );

        dispatch(addToast("Contact updated successfully."));
      }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setContactPersonToEdit(null);
    setModalOpen(false);
  };

  const handleEditModalOpen = (id) => {
    const contact = userData?.contacts?.find((contact) => contact?.id == id);
    setContactPersonToEdit(contact);
    setModalOpen(true);
  };

  const handleRemove = async (id) => {
    try {
      await deleteRequest(`contacts/${id}`);
      setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      dispatch(addToast("Contact removed successfully."));
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  useEffect(() => {
    if (userData?.contacts) {
      setRows(
        transformContacts(
          userData?.contacts,
          handleEditModalOpen,
          handleRemove
        )
      );
    }
  }, [userData?.contacts]);

  return (
    <div className="flex flex-col gap-6 p-8 bg-white rounded-lg w-full">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between">
        <span className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
          Contact person
        </span>
        <Button
          variant="textSecondary"
          onClick={() => setModalOpen(true)}
          size="small"
          startIcon={<Add />}
          sx={{
            margin: 0,
            justifyContent: "start",
          }}
        >
          Add a new contact person
        </Button>
      </div>
      <CustomTable columns={columns} rows={rows} />
      <ContactPersonModal
        open={isModalOpen}
        close={handleModalClose}
        onSubmit={handleSubmit}
        contact={contactPersonToEdit}
        editable={!!contactPersonToEdit}
      />
    </div>
  );
};

export default ContactPerson;
