import { KeyboardArrowDown } from "@mui/icons-material";
import { Button, Menu, MenuItem, Modal, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateRequest } from "../../api";
import Chip from "../../components/Chip";
import LabelModal from "../../components/Order/LabelModal";
import MoveOrderModal from "../../components/OrderOverview/MoveOrderModal";
import TrackNumberModal from "../../components/OrderOverview/TrackNumberModal";
import { initialStatuses } from "../../constants/OrderStatus";
import { selectCurrentUser } from "../../redux/authSlice";
import { addToast } from "../../redux/toastSlice";
import { STATUS_STYLES } from "../../constants/OrderStatus";
import { selectCurrentStore } from "../../redux/storeSlice";

export const SelectButton = styled(Button)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "6px 20px",
  borderRadius: "50px",
  boxShadow: theme.shadows[1],
  textTransform: "none",
}));

const StatusCapsule = ({ order, setOrder, fetchOrder }) => {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [trackNumberModal, setTrackNumberModal] = useState(false);
  const [isLabelModalOpen, setIsLabelModalOpen] = useState(false);
  const [isMoveOrderModalOpen, setIsMoveOrderModalOpen] = useState(false);

  const dispatch = useDispatch();

  const currentStore = useSelector(selectCurrentStore);
  const storeId = currentStore?.store?.id;

  const handleStatusChange = (status) => {
    setIsMoveOrderModalOpen(true);
    setSelectedStatus(status);
    setAnchorEl(null);
  };

  const handleStatusUpdate = async (value) => {
    const data = { order: { status: selectedStatus }, is_send_email: value };
    if (selectedStatus === "shipping") {
      data["order"]["shipping_date"] = new Date();
    } else if (selectedStatus === "in_progress") {
      data["order"]["in_progress_date"] = new Date();
    }
    try {
      const response = await updateRequest(
        `stores/${storeId}/orders/${order?.id}`,
        data
      );
      setOrder?.(response?.data);
      fetchOrder?.();
      dispatch(addToast("Order status is updated"));
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  const handleStatusClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const onCancelMoveOrder = () => {
    setSelectedStatus(order?.status);
    setIsMoveOrderModalOpen(false);
  };

  useEffect(() => {
    if (order) {
      setSelectedStatus(order.status);
    }
  }, [order]);

  return (
    <div>
      <SelectButton
        onClick={handleStatusClick}
        endIcon={<KeyboardArrowDown />}
        sx={{
          backgroundColor: STATUS_STYLES[selectedStatus]?.bgColor,
          color: STATUS_STYLES[selectedStatus]?.textColor,
        }}
      >
        <Chip status={selectedStatus} />
      </SelectButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "custom-select",
        }}
      >
        {initialStatuses.map((statusObj, i) => {
          const status = statusObj?.status;
          return (
            <MenuItem
              key={i}
              onClick={() => handleStatusChange(status)}
              selected={status === selectedStatus}
              sx={{
                color: STATUS_STYLES[status]?.textColor,
              }}
            >
              {STATUS_STYLES[status]?.text}
            </MenuItem>
          );
        })}
      </Menu>
      <Modal open={trackNumberModal} onClose={() => setTrackNumberModal(false)}>
        <TrackNumberModal
          handleClose={() => setTrackNumberModal(false)}
          openLabelModal={() => setIsLabelModalOpen(true)}
          orderId={order?.id}
          fetchRepairOrders={() => {}}
        />
      </Modal>
      <Modal open={isLabelModalOpen} onClose={() => setIsLabelModalOpen(false)}>
        <LabelModal
          isFinalShippingLeg={
            order?.shipping_labels?.length > 0 &&
            order?.shipping_labels.some(
              (label) => label.shipping_leg == "leg_2"
            )
          }
          handleClose={() => setIsLabelModalOpen(false)}
          customer={order?.user}
          orderId={order?.id}
        />
      </Modal>
      <Modal open={isMoveOrderModalOpen} onClose={onCancelMoveOrder}>
        <MoveOrderModal
          handleClose={() => setIsMoveOrderModalOpen(false)}
          onCancel={onCancelMoveOrder}
          handleNotification={(value) => handleStatusUpdate(value)}
          openTrackNumberModal={() => setTrackNumberModal(true)}
          order={order}
          destinationName={selectedStatus}
        />
      </Modal>
    </div>
  );
};

export default StatusCapsule;
