import React from "react";
import { Button, Box } from "@mui/material";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { POS_NEW_ORDER_STEPS } from "../../../constants";
import { LeftArrowIcon } from "../../../common/icons";
import CartBadge from "./CartBadge";
import { selectCurrentCart, updateCart } from "../../../redux/cartSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateCurrentStep } from "../../../redux/currentStepSlice";

const AttachCustomer = () => {
  const dispatch = useDispatch();
  const cart = useSelector(selectCurrentCart);
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1024px)" });

  const handleBack = async () => {
    dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.SELECT_CUSTOMER));
    dispatch(updateCart({ ...cart, type: null }));
  };

  const handleAttachCustomer = () => {
    dispatch(
      updateCart({
        ...cart,
        attached_customer: cart.customer,
        isCorporateCustomer: false,
        customer: null,
      }),
    );

    dispatch(
      updateCurrentStep(
        isDesktopScreen
          ? POS_NEW_ORDER_STEPS.ADD_ITEM
          : POS_NEW_ORDER_STEPS.ORDER_CART
      )
    );
  };

  return (
    <Box className="relative flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <MediaQuery minWidth={1024}>
        <Box
          className="absolute flex items-center gap-1 top-[2.5%] left-[2.5%] cursor-pointer"
          onClick={handleBack}
        >
          <LeftArrowIcon width={18} height={19} />
          <span>Back</span>
        </Box>
      </MediaQuery>
      <Box className="flex h-full sm:h-auto flex-col justify-between bg-[#F8F8F8] lg:bg-white w-full sm:max-w-[70%] xl:max-w-[50%] sm:m-auto">
        <div>
          <CartBadge />
          <p className="text-lg sm:text-3xl font-semibold sm:font-bold font-['Montserrat'] leading-[25.20px]">
            {cart.new_customer ? "Attaching new customer to the order" : "We found you!"}
          </p>
          <div className="flex flex-col gap-1 my-6">
            <span className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
              Customer #{cart?.customer?.id}
            </span>
            <span className="text-lg sm:text-3xl font-semibold sm:font-bold font-['Montserrat'] leading-[25.20px]">
              {cart?.customer?.name}
            </span>
          </div>
          <div className="flex flex-col md:flex-row justify-between my-8">
            <div className="flex flex-col gap-1">
              <span className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
                Phone number
              </span>
              <span>{cart?.customer?.phone_number || "-"}</span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
                Email address:
              </span>
              <span>{cart?.customer?.email || "-"}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Button variant="containedPrimary" onClick={handleAttachCustomer}>
            Attach to order
          </Button>
          {/* <MediaQuery maxWidth={1023}> */}
          <Button variant="outlinedSecondary" onClick={handleBack}>
            Try another method
          </Button>
          {/* </MediaQuery> */}
        </div>
      </Box>
    </Box>
  );
};

export default AttachCustomer;
