import React, { useRef, useState } from "react";
import { Box, Typography, Menu, MenuItem, IconButton } from "@mui/material";
import Slider from "react-slick";
import {
  ORDERS,
  ORDER_LINE_ITEMS,
  RAILS_APP_API_URL,
  STORES,
  UPDATE_IMAGE,
} from "../../constants/BackendRoutes";
import theme from "../../theme";
import {
  CrossIcon,
  LeftPointer,
  MoreIcon,
  RightPointer,
} from "../../common/icons";
import { convertDateFormat } from "../../utils";
import { updateRequest } from "../../api";
import { useMediaQuery } from "react-responsive";

const ImageSliderModal = ({
  handleClose,
  modalData,
  currentUser,
  currentStore,
  handleDelete,
  orderId,
  handleUpdateImage,
}) => {
  const { data, name, status, itemId } = modalData;
  const { name: userName } = currentUser;
  const isMdUp = useMediaQuery({ query: "(max-width: 767px)" });
  const inputRef = useRef(null);
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [images, setImages] = useState([...data]);

  const handleUploadImageClick = () => {
    inputRef.current.click();
  };

  const settings = {
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_, newIndex) => setCurrentSlide(newIndex),
  };

  const onClickDelete = () => {
    handleDelete(itemId, status, data[currentSlide]?.id);
    setImages([
      ...images.slice(0, currentSlide),
      ...images.slice(currentSlide + 1),
    ]);
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function UploadInput() {
    const isBefore = status === "before";
    const inputId = `contained-button-file-${itemId}-${
      isBefore ? "before" : "after"
    }`;
    const imageType = isBefore
      ? "order_line_item[before_images]"
      : "order_line_item[after_images]";

    const uploadPicture = async (event) => {
      const files = event.target.files;
      const formData = new FormData();
      for (const file of files) {
        formData.append(`${imageType}[]`, file);
      }
      try {
        await updateRequest(
          `${STORES}/${currentStore?.store_id}${ORDERS}/${orderId}/${ORDER_LINE_ITEMS}/${itemId}${UPDATE_IMAGE}`,
          formData,
          { "Content-Type": "multipart/form-data" }
        );
        handleUpdateImage();
        handleClose();
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    };

    return (
      <input
        accept="image/*"
        id={inputId}
        multiple
        type="file"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={uploadPicture}
      />
    );
  }

  return (
    <div
      style={{ width: "calc(100vw - 26px)" }}
      className="absolute top-1/2 left-1/2 max-w-[792px] transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-8 rounded-lg"
    >
      <Box className="flex flex-col gap-2">
        <Box className="flex flex-row w-full justify-between items-center">
          <Typography style={theme.typography.subTitle}>
            Attached images
          </Typography>
          <Box className="flex flex-row gap-2 items-center">
            <MoreIcon onClick={handleMenuClick} />
            <CrossIcon onClick={handleClose} />
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={onClickDelete}>Remove image</MenuItem>
            <MenuItem onClick={handleUploadImageClick}>
              Add more images
            </MenuItem>
          </Menu>
        </Box>
        <Box className="relative flex justify-center">
          <Slider ref={sliderRef} {...settings} className="w-full">
            {images?.map((image, index) => (
              <div key={index} className="relative rounded-xl">
                <img
                  className="mx-auto rounded-xl w-auto max-h-[371px]"
                  src={`${RAILS_APP_API_URL}${image.url}`}
                  alt={`After ${index}`}
                />
                <div className="absolute bottom-0 left-0 w-full h-[50px] bg-[rgba(39,37,35,0.35)] flex items-center justify-center text-white rounded-b-lg">
                  {currentSlide + 1}/{images?.length}
                </div>
              </div>
            ))}
          </Slider>
          <div
            className={`absolute left-[15px] top-1/2 transform -translate-y-1/2 md:p-2 p-1 ${
              currentSlide === 0
                ? "bg-[#686b64] cursor-not-allowed"
                : "bg-[#e8e9e8] cursor-pointer"
            } rounded-full`}
          >
            <LeftPointer
              className="h-[15px] w-[15px]"
              onClick={() => sliderRef.current.slickPrev()}
            />
          </div>
          <div
            className={`absolute right-[15px] top-1/2 transform -translate-y-1/2 md:p-2 p-1 ${
              currentSlide + 1 === images?.length
                ? "bg-[#686b64] cursor-not-allowed"
                : "bg-[#e8e9e8] cursor-pointer"
            } rounded-full`}
          >
            <RightPointer
              className="h-[15px] w-[15px]"
              onClick={() => sliderRef.current.slickNext()}
            />
          </div>
        </Box>
        <Box className="flex flex-col w-full md:gap-2 gap-5">
          <Box className="flex flex-col md:flex-row md:gap-2 gap-5">
            <Box className="flex flex-col w-1/2 gap-1">
              <Typography
                className={isMdUp && "!text-[12px]"}
                style={{
                  ...theme.typography.body,
                  color: theme.palette.text.secondary,
                }}
              >
                Item name
              </Typography>
              <Typography
                style={{
                  ...theme.typography.body,
                }}
              >
                {name}
              </Typography>
            </Box>
            <Box className="flex flex-col gap-1">
              <Typography
                className={isMdUp && "!text-[12px]"}
                style={{
                  ...theme.typography.body,
                  color: theme.palette.text.secondary,
                }}
              >
                Status
              </Typography>
              <Typography
                style={{
                  ...theme.typography.body,
                }}
              >
                {status === "before" ? "Before repair" : "After repair"}
              </Typography>
            </Box>
          </Box>
          <Box className="flex flex-col md:flex-row md:gap-2 gap-5">
            <Box className="flex flex-col w-1/2 gap-1">
              <Typography
                className={isMdUp && "!text-[12px]"}
                style={{
                  ...theme.typography.body,
                  color: theme.palette.text.secondary,
                }}
              >
                Uploaded on
              </Typography>
              <Typography
                style={{
                  ...theme.typography.body,
                }}
              >
                {convertDateFormat(images[currentSlide]?.created_at)}
              </Typography>
            </Box>
            <Box className="flex flex-col gap-1">
              <Typography
                className={isMdUp && "!text-[12px]"}
                style={{
                  ...theme.typography.body,
                  color: theme.palette.text.secondary,
                }}
              >
                Uploaded by
              </Typography>
              <Typography
                style={{
                  ...theme.typography.body,
                }}
              >
                {userName}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <UploadInput />
    </div>
  );
};

export default ImageSliderModal;
