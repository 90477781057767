import React, { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CollapsibleRow from "./CollapsableRow";
import MenuButton from "../Button/Menu";
import SearchInput from "../Form/Field/SearchInput";
import { useDebounce } from "../../customHooks/useDebounce";
import { sortRows } from "../../utils";

export default function CustomTable({
  columns,
  rows,
  sortable = false,
  searchable = false,
  searchType = "MultiColumn",
  actions,
  csvPreview = false,
  searchCallback = null,
  setCurrentPage = null,
}) {
  const [searchInput, setSearchInput] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filteredRows, setFilteredRows] = useState(rows);

  const debouncedSearchValue = useDebounce(searchInput);

  const previousSearchValue = useRef(debouncedSearchValue);

  const filterRows = (rows, searchValue, columns) => {
    if (!searchValue) return rows;

    if (searchType === "MultiColumn") {
      return rows.filter((row) =>
        columns.some((column) =>
          column.searchable
            ? String(row[column.field] || "")
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            : false
        )
      );
    } else if (searchType === "Collapsed") {
      return rows
        .map((row) => ({
          ...row,
          collapse: row.collapse.filter((item) =>
            columns.some((column) =>
              column.searchable && item[column.field]
                ? String(item[column.field])
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
                : false
            )
          ),
        }))
        .filter((row) => row.collapse.length > 0);
    }
    return rows;
  };

  useEffect(() => {
    if (rows?.length > 0) {
      if (
        searchCallback &&
        debouncedSearchValue !== previousSearchValue.current
      ) {
        setCurrentPage(1);
        searchCallback(debouncedSearchValue);
        previousSearchValue.current = debouncedSearchValue;
      }

      let updatedRows = searchCallback
        ? rows
        : filterRows(rows, debouncedSearchValue, columns);
      updatedRows = sortRows(updatedRows, sortBy, sortOrder, columns);
      setFilteredRows(updatedRows);
    }
  }, [debouncedSearchValue, sortBy, sortOrder, rows, columns]);

  const handleSortChange = (value) => {
    if (sortBy === value) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(value);
      setSortOrder("asc");
    }
  };

  const sortableLabels = columns
    .filter((column) => column.sortable)
    .map((column) => column.label);

  return (
    <>
      {(searchable || sortable || actions) && (
        <div className="flex flex-col lg:flex-row gap-5 justify-between lg:items-center my-5">
          <div className="flex flex-col sm:flex-row gap-3">
            {searchable && (
              <SearchInput
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            )}
            {sortable && (
              <MenuButton
                title="Sort by:"
                variant="outlinedSecondary"
                options={sortableLabels}
                onChange={handleSortChange}
              />
            )}
          </div>

          {actions && (
            <div className="flex flex-col sm:flex-row gap-3">{actions}</div>
          )}
        </div>
      )}

      <TableContainer
        style={{
          borderRadius: "8px",
          boxShadow: "unset",
          border: "0.5px solid #C9C8C8",
        }}
        component={Paper}
      >
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow style={{ borderBottom: "0.5px solid #C9C8C8" }}>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  style={{ width: column.width, border: 0 }}
                  align={column.align}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows?.map((row, index) => (
              <CollapsibleRow key={index} row={row} columns={columns} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
