// CardForm.js
import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/loadingSlice";
import { addToast } from "../../redux/toastSlice";

const CardForm = ({ onPaymentMethodAttached }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch()

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    dispatch(setLoading(true))

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
    });

    if (error) {
      dispatch(addToast(error))
      return;
    }

    onPaymentMethodAttached(setupIntent.payment_method);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <Button
          variant="contained"
          color="primary"
          className="w-full"
          type="submit"
          sx={{ mt: 2 }}
          disabled={!stripe}
        >
          Add payment method
        </Button>
      </form>
    </React.Fragment>
  );
};

export default CardForm;
