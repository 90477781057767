import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentOrganization } from "../../../redux/organizationSlice";
import { selectCurrentStore } from "../../../redux/storeSlice";
import { postRequest } from "../../../api";
import { addToast } from "../../../redux/toastSlice";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../stripe/CheckoutForm";
import { calculateTotal } from "../../../utils";
import { selectCurrentCart } from "../../../redux/cartSlice";

const ManualCardPayment = () => {
  const [stripePromise, setStripePromise] = useState();
  const [clientSecret, setClientSecret] = useState(null);

  const dispatch = useDispatch();
  const currentOrganization = useSelector(selectCurrentOrganization);
  const currentStore = useSelector(selectCurrentStore);
  const cart = useSelector(selectCurrentCart);

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      spacingUnit: "2px",
      borderRadius: "8px",
    },
    rules: {
      ".Input": {
        color: "#424770",
        padding: "12px",
        marginBottom: "8px",
      },
      ".Input:focus": {
        borderColor: "#B3B29A",
        boxShadow: "0 0 0 4px rgba(179, 178, 154, 0.25) inset",
      },
      ".Error": {
        color: "rgba(0, 0, 0, 0.26)",
        marginTop: "2px",
        marginBottom: "2px",
      },
    },
  };

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await postRequest(`stripe/payment_intents`, {
          organization_id: currentOrganization?.id,
          amount: calculateTotal(cart) * 100,
          currency: "usd",
          payment_id: cart.payment_id,
        });

        if (response && response.status === 200) setClientSecret(response.data.clientSecret);
      } catch (error) {
        dispatch(addToast(error || "Error."));
      }
    };

    fetchClientSecret();
  }, [dispatch, currentStore]);

  useEffect(() => {
    if (currentOrganization) {
      setStripePromise(loadStripe(currentOrganization?.connect_acc_publishable_key));
    }
  }, [currentOrganization]);

  return (
    <Box className="flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <Box className="flex h-full sm:h-auto flex-col justify-between bg-[#F8F8F8] lg:bg-white w-full sm:max-w-[70%] xl:max-w-[55%] sm:m-auto">
        {!!clientSecret && (
          <Elements
            stripe={stripePromise}
            options={{
              appearance,
              clientSecret,
            }}
          >
            <CheckoutForm />
          </Elements>
        )}
      </Box>
    </Box>
  );
};

export default ManualCardPayment;
