import React, { useState, useEffect } from "react";
import { Avatar, InputAdornment, TextField } from "@mui/material";
import CameraSVG from "../../images/camera.svg";
import BlockIcon from "@mui/icons-material/Block";
import { useDispatch } from "react-redux";
import { addToast } from "../../redux/toastSlice";
import { diffImageSrc } from "../../utils"
import { resizeFile } from '../../utils/imageResizer';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/authSlice";

const MyAccount = ({ id, setAccountDetailChanges }) => {
  const userData = useSelector(selectCurrentUser);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [accountDetail, setAccountDetail] = useState({
    avatar: null,
    name: "",
    email: "",
    user_name: "",
    password_mask: "",
    existing_password: "",
    new_password: "",
    confirm_new_password: "",
  });

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAccountDetail((prevDetail) => {
      if (value !== userData[name]) {
        setAccountDetailChanges((prevChanges) => ({
          ...prevChanges,
          [name]: value,
        }));
      } else {
        setAccountDetailChanges((prevChanges) => {
          const updatedChanges = { ...prevChanges };
          delete updatedChanges[name];
          return updatedChanges;
        });
      }
      return { ...prevDetail, [name]: value };
    });
  };

  const handleFileChange = async (event) => {
    const file = await resizeFile(event.target.files[0]);
    if (file && file.type.startsWith("image/")) {
      setAccountDetail((prevDetail) => {
        if (file !== userData.avatar) {
          setAccountDetailChanges((prevChanges) => ({
            ...prevChanges,
            avatar: file,
          }));
        } else {
          setAccountDetailChanges((prevChanges) => {
            const updatedChanges = { ...prevChanges };
            delete updatedChanges.avatar;
            return updatedChanges;
          });
        }
        return { ...prevDetail, avatar: file };
      });
    } else {
      dispatch(addToast("Please select an image file."));
    }
  };

  useEffect(() => {
    if (userData) {
      setAccountDetail((prev) => ({
        ...prev,
        avatar: userData.avatar_url,
        user_name: userData.user_name,
        name: userData.name,
        email: userData.email,
        password_mask: userData.pswd_mask,
      }));
    }
  }, [userData]);

  return (
    <div className="flex flex-col gap-6 p-8 bg-white rounded-lg w-full">
      <div className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        My account
      </div>
      <div className="flex flex-col lg:flex-row gap-16 justify-between">
        <div className="min-w-[18%]">
          <div className="flex flex-col gap-3 items-center">
            <div className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug hidden md:block">
              Profile image
            </div>
            <Avatar
              alt="Profile Image"
              src={
                accountDetail.avatar
                  ? diffImageSrc(
                      accountDetail.avatar
                    )
                  : CameraSVG
              }
              sx={{ width: 95, height: 95 }}
            />
            <a
              className="text-[#4c8c4a] underline cursor-pointer"
              onClick={() => document.getElementById("fileInput").click()}
            >
              Edit
            </a>
            <input
              id="fileInput"
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className="flex flex-col gap-5 w-full">
          <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                User name
              </div>
              <div className="text-right text-green-700 text-xs font-normal font-['Questrial'] underline leading-none">
                Send edit request
              </div>
            </div>
            <TextField
              id="user_name"
              placeholder="MattewP"
              variant="outlined"
              disabled
              value={accountDetail.user_name}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <BlockIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="flex flex-col gap-2">
            <div className="w-[480px] h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              Name
            </div>
            <TextField
              id="name"
              placeholder="Mattew Paker"
              variant="outlined"
              name="name"
              value={accountDetail.name}
              onChange={handleInputChange}
            />
          </div>

          <div className="flex flex-col gap-2">
            <div className="w-[480px] h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              Email address
            </div>
            <TextField
              id="email"
              placeholder="MattewP@coblrshop.com"
              type="email"
              variant="outlined"
              name="email"
              value={accountDetail.email}
              onChange={handleInputChange}
            />
          </div>

          {!isEditingPassword && (
            <div className="flex flex-col gap-2">
              <div className="flex flex-row justify-between">
                <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                  Password
                </div>
                <div
                  className="text-right text-green-700 text-xs font-normal font-['Questrial'] underline leading-none cursor-pointer"
                  onClick={() => setIsEditingPassword(true)}
                >
                  Edit password
                </div>
              </div>
              <TextField
                id="password"
                type="password"
                variant="outlined"
                value={accountDetail.password_mask}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <BlockIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}

          {isEditingPassword && (
            <div className="flex flex-col gap-2">
              <div className="w-[480px] h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Existing Password
              </div>
              <TextField
                id="existing-password"
                type="password"
                variant="outlined"
                name="existing_password"
                value={accountDetail.existing_password}
                onChange={handleInputChange}
              />
              <div className="w-[480px] h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                New Password
              </div>
              <TextField
                id="new-password"
                type="password"
                variant="outlined"
                name="new_password"
                value={accountDetail.new_password}
                onChange={handleInputChange}
              />
              <div className="w-[480px] h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Confirm New Password
              </div>
              <TextField
                id="confirm-password"
                type="password"
                variant="outlined"
                name="confirm_new_password"
                value={accountDetail.confirm_new_password}
                onChange={handleInputChange}
              />

              {accountDetail.new_password !==
                accountDetail.confirm_new_password && (
                <p className="text-red-400 text-xs font-normal font-['Questrial']">
                  Password mismatch!
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
