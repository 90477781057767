import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { ORDERS_OVERVIEW } from "../../../constants/FrontendRoutes";
import { CART_TYPES, POS_NEW_ORDER_STEPS } from "../../../constants";
import { initialCartState, selectCurrentCart, updateCart } from "../../../redux/cartSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateCurrentStep } from "../../../redux/currentStepSlice";
import { validateEmail } from "../../../utils";
import { PlusCircle } from "../../../common/icons";
import { addToast } from "../../../redux/toastSlice";
import { postRequest } from "../../../api";
import { selectCurrentStore } from "../../../redux/storeSlice";

const RECIPIENTS_INPUT_FIELDS = { email: "", is_customer: false };

const OrderHeader = ({ cart }) => {
  return (
    <p className="mt-4 text-2xl font-bold font-['Questrial'] leading-snug">
      {`${cart.type === CART_TYPES.checkout ? "Order" : "Quote"} #${
        cart.orderId
      } has been created! 🎉`}
    </p>
  );
};

const EmailInputList = ({ notificationRecipients, handleInputChange }) => (
  <div className="flex flex-col">
    {notificationRecipients.map((item, index) => (
      <div key={index} className="flex flex-col">
        <div className="flex items-center justify-between">
          <label className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
            Email address
          </label>
          <div className="flex items-center">
            <Checkbox
              checked={item.is_customer}
              onChange={() =>
                handleInputChange(
                  index,
                  "is_customer",
                  !item.is_customer
                )
              }
            />
            <span className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
              This is a customer email
            </span>
          </div>
        </div>
        <TextField
          fullWidth
          placeholder="John.doe@example.com"
          variant="outlined"
          size="small"
          value={item.email}
          onChange={(e) => handleInputChange(index, "email", e.target.value)}
        />
      </div>
    ))}
  </div>
);

const OrderSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentStore = useSelector(selectCurrentStore);
  const cart = useSelector(selectCurrentCart);
  const [notificationRecipients, setNotificationRecipients] = useState([RECIPIENTS_INPUT_FIELDS]);

  const handleInputChange = (index, field, value) => {
    setNotificationRecipients((prevRecipients) => {
      const updatedRecipients = prevRecipients.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      );
      return updatedRecipients;
    });
  };

  const handleAddEmail = () => {
    if (validateEmail(notificationRecipients[notificationRecipients.length - 1].email.trim())) {
      setNotificationRecipients([...notificationRecipients, RECIPIENTS_INPUT_FIELDS]);
    }
  };

  const isAllFieldsValid = () => {
    return notificationRecipients.every(
      (item) => item.email.trim() !== "" && validateEmail(item.email)
    );
  };

  const handleSendReceipt = async () => {
    try {
      const { status } = await postRequest(
        `stores/${currentStore?.store_id}/orders/${cart?.orderId}/notification_recipients`,
        {
          notification_recipients: notificationRecipients,
        }
      );

      if (status === 201) {
        setNotificationRecipients([RECIPIENTS_INPUT_FIELDS]);
        dispatch(addToast("Notification recipients added successfully"));
      }
    } catch (error) {
      dispatch(addToast(error))
    }
  };

  const handleCreateNew = () => {
    dispatch(
      updateCart({
        ...initialCartState,
        productSalesTaxRate: cart.productSalesTaxRate,
        serviceSalesTaxRate: cart.serviceSalesTaxRate,
        rushOrder: { ...cart.rushOrder, isRush: false },
      }),
    );

    dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.ADD_ITEM));
  };

  useEffect(() => {
    dispatch(updateCart({ ...cart , data: null}))
    return () => {
      dispatch(
        updateCart({
          ...initialCartState,
          productSalesTaxRate: cart.productSalesTaxRate,
          serviceSalesTaxRate: cart.serviceSalesTaxRate,
          rushOrder: { ...cart.rushOrder, isRush: false },
        })
      );
    };
  }, []);

  return (
    <Box className="flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <Box className="flex h-full sm:h-auto flex-col justify-between bg-[#F8F8F8] lg:bg-white w-full sm:max-w-[60%] lg:max-w-[70%] xl:max-w-[50%] sm:m-auto sm:gap-8">
        <div className="flex flex-col gap-3">
          <OrderHeader cart={cart} />
          {!cart.isBillLater ? (
            <p className="mt-4 text-lg font-semibold font-['Questrial'] leading-snug">
              {`We will be sending ${
                cart.type == CART_TYPES.checkout
                  ? "emails to the customer for their record!"
                  : "the quote to the customer to complete the order!"
              }`}
            </p>
          ) : (
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <span className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
                  {" "}
                  We will send the order’s bill to the associate email address:
                </span>
                <span className="text-[#272523] text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                  {cart?.attached_customer?.email}
                </span>
              </div>
              <p className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
                If you want to send another email receipt, or allow an
                individual customer to track their order, please enter the email
                below:
              </p>
              <EmailInputList
                notificationRecipients={notificationRecipients}
                handleInputChange={handleInputChange}
                handleAddEmail={handleAddEmail}
                isAllFieldsValid={isAllFieldsValid}
              />
              <Button
                variant="textPrimary"
                startIcon={
                  <PlusCircle
                    fill={
                      !isAllFieldsValid() || notificationRecipients.length >= 2
                        ? "gray"
                        : "green"
                    }
                  />
                }
                onClick={handleAddEmail}
                disabled={
                  !isAllFieldsValid() || notificationRecipients.length >= 2
                }
                sx={{ width: "fit-content", marginLeft: "auto" }}
              >
                Add Another Email
              </Button>
            </div>
          )}
        </div>
        <Box
          className={`flex ${
            cart.isBillLater ? "flex-col" : "flex-col-reverse md:flex-row"
          } items-center gap-2`}
        >
          <Button
            fullWidth
            startIcon={!cart.isBillLater && <Add />}
            disabled={
              cart.isBillLater &&
              (!isAllFieldsValid() || notificationRecipients.length < 1)
            }
            variant={
              cart.isBillLater ? "containedPrimary" : "outlinedSecondary"
            }
            onClick={
              cart.isBillLater
                ? handleSendReceipt
                : () => navigate(ORDERS_OVERVIEW)
            }
          >
            {cart.isBillLater ? "Send Receipt" : "Back to order list"}
          </Button>

          <Button
            fullWidth
            variant={
              cart.isBillLater ? "outlinedSecondary" : "containedPrimary"
            }
            startIcon={<Add />}
            onClick={handleCreateNew}
          >
            Create new order
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderSuccess;
