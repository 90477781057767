import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import theme from "../../theme";
import { CrossIcon } from "../../common/icons";
import TextInput from "../Form/Field/TextInput";
import { postRequest } from "../../api";
import { ORDERS, SHIPPING_LABELS, STORES } from "../../constants/BackendRoutes";
import { selectCurrentStore } from "../../redux/storeSlice";
import { useSelector } from "react-redux";

const TrackNumberModal = ({
  handleClose,
  openLabelModal,
  orderId,
  fetchRepairOrders,
}) => {
  const currentStore = useSelector(selectCurrentStore);
  const [trackingNumber, setTrackingNumber] = useState("");

  const handleSubmitTrackingNumber = () => {
    const res = postRequest(
      `${STORES}/${currentStore?.store_id}${ORDERS}/${orderId}${SHIPPING_LABELS}`,
      {
        shipping_label: {
          shipping_leg: "leg_1",
          tracking_number: trackingNumber,
        },
      }
    );
    fetchRepairOrders();
    handleClose();
  };

  const handleOpenlabelModal = () => {
    openLabelModal();
    handleClose();
  };

  return (
    <div
      style={{
        width: "calc(100% - 24px)",
        "@media (min-width: 768px)": {
          width: "auto",
        },
      }}
      className="absolute top-1/2 left-1/2 max-w-[535px] md:p-4 p-7 bg-white shadow-lg overflow-x-auto transform -translate-x-1/2 -translate-y-1/2 rounded-lg"
    >
      <div className="flex flex-col md:gap-3 gap-6">
        <div className="flex flex-row justify-between">
          <Typography style={theme.typography.title}>
            Missing tracking number
          </Typography>
          <CrossIcon className="md:block hidden" onClick={handleClose} />
        </div>
        <Typography style={theme.typography.body}>
          There’s no shipping info attached to this order yet, please enter the
          tracking number below:
        </Typography>
        <div className="flex flex-col gap-2">
          <Typography style={theme.typography.body}>Tracking number</Typography>
          <TextInput
            name="trackingNumber"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
          />
        </div>
        <div className="flex md:flex-row flex-col justify-end gap-3 md:items-center">
          <p
            className="text-base font-['Questrial'] md:self-center self-end underline cursor-pointer"
            onClick={handleOpenlabelModal}
          >
            Create a label now
          </p>
          <Button
            variant="contained"
            sx={{ px: 5 }}
            disabled={trackingNumber === ""}
            onClick={() => handleSubmitTrackingNumber()}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ px: 5 }}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TrackNumberModal;
