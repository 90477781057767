import { createSlice } from "@reduxjs/toolkit";
import { fetchUserData, login, logout } from "./authActions";
import { fetchMainCategories, fetchServiceTypes } from "./storeAction";
import { fetchSubStatuses } from "./storeAction";
import { retrieveStoreIdFromLocalStorage, addStoreIdToLocalStorage } from "../utils/localStorage";

const storeSlice = createSlice({
  name: "store",
  initialState: {
    allServiceTypes: null,
    allMainCategories: null,
    store: null,
    allStores: [],
    loading: false,
    error: null,
    subStatuses: [],
    selectedSubStatuses: [],
  },
  reducers: {
    setSelectedSubStatuses: (state, action) => {
      state.selectedSubStatuses = action.payload || [];
    },
    setCurrentStore: (state, action) => {
      state.store = action.payload;
    },
    setAllStores: (state, action) => {
      state.allStores = action.payload;
    },
    setStoreById(state, action) {
      const storeId = action.payload;
      const store = state.allStores.find((store) => store.store_id === storeId);

      if (store) {
        state.store = store;
        addStoreIdToLocalStorage(state.store.store_id);
      } else {
        alert(`Store with ID ${storeId} not found`);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubStatuses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSubStatuses.fulfilled, (state, action) => {
        state.loading = false;
        state.subStatuses = action.payload || [];
      })
      .addCase(fetchSubStatuses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch subscription statuses';
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        const { stores_as_staff } = action.payload;

        state.allStores = stores_as_staff;
        state.store = stores_as_staff[0];
        state.loading = false;
        state.error = null;

      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchServiceTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServiceTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.allServiceTypes = action.payload;
      })
      .addCase(fetchServiceTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchMainCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMainCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.allMainCategories = action.payload;
      })
      .addCase(fetchMainCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        const storeId = retrieveStoreIdFromLocalStorage();
        const { stores_as_staff } = action.payload;

        state.allStores = stores_as_staff;
        state.store = storeId
          ? stores_as_staff?.find((store) => store?.store_id == storeId) ||
            stores_as_staff?.[0]
          : stores_as_staff?.[0];
        addStoreIdToLocalStorage(state?.store?.store_id);
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(logout.fulfilled, (state) => {
        state.store = null;
        state.allStores = [];
        state.allServiceTypes = null;
        state.allMainCategories = null;
        state.loading = false;
        state.error = null;
      });
  },
});

export const selectAllServiceType = (state) => state?.store?.allServiceTypes;
export const selectAllCategories = (state) => state?.store?.allMainCategories;
export const selectSubStatuses = state => state?.store?.subStatuses || [];
export const selectSelectedSubStatuses = state => state?.store?.selectedSubStatuses || [];
export const selectCurrentStore = (state) => state?.store?.store;
export const selectAllStores = (state) => state?.store?.allStores;

export const { setCurrentStore, setAllStores, setStoreById } = storeSlice.actions;

export const { setSelectedSubStatuses } = storeSlice.actions;
export default storeSlice.reducer;
