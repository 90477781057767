import React, { useEffect, useState } from "react";
import { Percent } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, Tooltip, useMediaQuery } from "@mui/material";
import Selector from "../Form/Field/AutoComplete";
import InfoIcon from "@mui/icons-material/Info";
import { TAX_RATE_SPECIFICATIONS } from "../../constants";

const TAX_FIELDS = [
  { tax_type: "GST", percentage: "", applied_to: [] },
  { tax_type: "PST", percentage: "", applied_to: [] },
  { tax_type: "HST", percentage: "", applied_to: [] },
];

const CustomizedTax = ({ businessDetail ,setBusinessDetailChanges }) => {
  const [taxRates, setTaxRates] = useState([]);
  const [hovered, setHovered] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleInputChange = (index, name, value) => {
    const updatedTaxRates = [...taxRates];
    updatedTaxRates[index][name] = value;

    const transformedTaxes = updatedTaxRates.map((tax) => {
      const hasProduct = tax?.applied_to?.some((item) => item?.value === "all_products");
      const hasService = tax?.applied_to?.some((item) => item?.value === "all_services");

      return {
        tax_type: tax.tax_type,
        percentage: tax.percentage,
        product: hasProduct ? 1 : 0,
        services: hasService ? 1 : 0,
      };
    });

    setBusinessDetailChanges((prevChanges) => ({
      ...prevChanges,
      customize_taxes: transformedTaxes,
    }));
  };

  const transformTaxData = (customizedTaxes) => {
    return [...customizedTaxes]?.sort((a, b) => a.id - b.id)?.map((tax) => {
      const appliedTo = [];

      if (tax.product_sales_tax > 0) {
        appliedTo.push({ title: "All Products", value: "all_products" });
      }
      if (tax.service_sales_tax > 0) {
        appliedTo.push({ title: "All Services", value: "all_services" });
      }

      let percentage = "";
      if (tax.product_sales_tax > 0) {
        percentage = (tax.product_sales_tax * 100).toFixed(2);
      } else if (tax.service_sales_tax > 0) {
        percentage = (tax.service_sales_tax * 100).toFixed(2);
      }

      return {
        tax_type: tax?.tax_type?.toUpperCase(),
        percentage,
        applied_to: appliedTo,
      };
    });
  };

  useEffect(() => {
    setTaxRates(businessDetail?.customize_taxes && businessDetail?.customize_taxes.length > 0
      ? transformTaxData(businessDetail?.customize_taxes)
      : TAX_FIELDS)
  }, [businessDetail?.customize_taxes]);

  return (
    <div className="flex flex-col gap-2">
      <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
        Tax Rate Specifications
        <Tooltip
          title={
            <p className="font-['Questrial'] text-sm text-[#272523] font-thin leading-relaxed">
              Disclaimer: <br />
              Coblr provides tools for setting tax rates; however, it is the responsibility of each
              business to ensure accuracy and compliance with all applicable tax regulations. Coblr
              is not liable for any tax miscalculations, errors, or omissions resulting from
              user-defined tax settings. Please consult with a tax professional for specific
              guidance.
            </p>
          }
          arrow
          placement={isSmallScreen ? "bottom" : "right"}
          componentsProps={{
            popper: {
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: 'white',
                  color: 'black',
                  border: '1px solid rgba(128, 128, 128, 0.3)',
                  padding: '16px',
                  borderRadius: '8px',
                  maxWidth: {
                    xs: '250px',
                    sm: '350px',
                  },
                },
                '& .MuiTooltip-arrow': {
                  color: 'white',
                  '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    border: '1px solid rgba(128, 128, 128, 0.5)',
                    zIndex: -1,
                  },
                },
              }
            },
          }
          }
        >
          <IconButton
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            sx={{
              color: hovered ? "green" : "gray",
            }}
          >
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </p>
      <p className=" text-stone-800 text-sm font-normal font-['Questrial'] leading-snug">
        You can customize the tax rate since your business location is set in Canada.
      </p>

      {taxRates?.map((tax, index) => (
        <div key={index} className="flex flex-col sm:flex-row gap-4 mb-4">
          <div className="flex flex-col gap-2 w-full basis-[33%]">
            <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              {tax.tax_type}
            </div>
            <TextField
              name="percentage"
              type="number"
              placeholder="Please enter"
              variant="outlined"
              value={tax.percentage}
              onChange={(e) => handleInputChange(index, "percentage", e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Percent />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="flex flex-col gap-2 w-full basis-[67%]">
            <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              Applies to
            </div>
            <Selector
              name="applied_to"
              label="Please select"
              size="large"
              options={TAX_RATE_SPECIFICATIONS}
              multiSelect={true}
              value={TAX_RATE_SPECIFICATIONS.filter((rate) =>
                tax?.applied_to?.some((applied) => applied.value === rate.value)
              )}
              onChange={(_, value) => handleInputChange(index, "applied_to", value)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomizedTax;
