import React from "react";
import Chip from "../Chip";
import { useNavigate } from "react-router-dom";
import {
  MESSAGES,
  CONVERSATION_DETAIL,
} from "../../constants/FrontendRoutes";
import { Button } from "@mui/material";

const RedDot = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <circle cx="5" cy="5" r="5" fill="#D84141" />
    </svg>
  );
};
export const IconRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.15967 12.3536C5.94678 12.1583 5.94678 11.8417 6.15967 11.6464L9.75 8.35355C9.9629 8.15829 9.9629 7.84171 9.75 7.64645L6.15968 4.35355C5.94678 4.15829 5.94678 3.84171 6.15968 3.64645C6.37257 3.45118 6.71775 3.45118 6.93065 3.64645L10.521 6.93934C11.1597 7.52513 11.1597 8.47487 10.521 9.06066L6.93065 12.3536C6.71775 12.5488 6.37257 12.5488 6.15967 12.3536Z"
        fill="#939291"
      />
    </svg>
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  return formattedDate.replace(/,(?=[^,]*$)/, " at");
};

const MessageIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
  >
    <rect x="1" y="1" width="14" height="10.5" rx="2" stroke="#272523" />
    <path
      d="M1 3.625L7.10557 6.67779C7.66863 6.95931 8.33137 6.95931 8.89443 6.67779L15 3.625"
      stroke="#272523"
    />
  </svg>
);

const MessageHistory = ({ userData, currentStore }) => {
  const orders = userData?.orders.filter(
    (order) => order.last_message !== null && order?.store_id == currentStore?.id
  );

  const navigate = useNavigate();

  return (
    <section className="bg-white px-[24px] py-[32px] flex flex-col gap-[16px] rounded-[8px]">
      <div className="flex flex-col sm:flex-row justify-between sm:items-center">
        <div className="font-['Montserrat'] text-[24px] font-bold text-[#272523] mb-[16px]">
          Message history
        </div>
        <Button
          onClick={() => navigate(MESSAGES)}
          sx={{
            borderColor: "#C9C8C8",
            color: "#272523",
            "&:hover": {
              borderColor: "#C9C8C8",
              color: "#272523",
              backgroundColor: "#C9C8C8",
            },
          }}
          variant="outlined"
          startIcon={<MessageIcon />}
        >
          Send message
        </Button>
      </div>
      {orders?.map((order) => {
        return (
          <div className="flex border border-[#C9C8C8] rounded-[8px] flex-wrap md:flex-nowrap">
            <div className="font-['Questrial'] text-[16px] font-normal flex flex-col grow px-[20px] py-[17px] gap-[16px]">
              <div className="flex items-center justify-between flex-wrap">
                <div className="flex items-center gap-[8px]">
                  {!order?.last_message?.is_read && <RedDot />}
                  <div className="text-[#939291]">Order #{order?.id}</div>
                  <Chip status={order?.status} />
                </div>
                <div className="text-[#939291]">
                  {formatDate(order?.last_message?.created_at)}
                </div>
              </div>
              <div>
                {order?.last_message?.user?.name}:{" "}
                {order?.last_message?.content}
              </div>
            </div>
            <div className="w-[0.5px] bg-[#C9C8C8]" />
            <div className="shrink self-center px-[20px]">
              <div
                onClick={() =>
                  navigate(CONVERSATION_DETAIL.replace(":id", order?.conversation_id))
                }
                className="cursor-pointer flex py-[16px] px-[10px] items-center gap-[8px] font-['Questrial'] text-[16px] font-normal underline text-[#272523]"
              >
                <div className="text-nowrap">View Message</div>
                <IconRight />
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default MessageHistory;
