import { CircleSharp } from "@mui/icons-material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
  deleteRequest,
  getRequest,
  postRequest,
  updateRequest,
} from "../../api";
import { fetchStoreUsers } from "../../api/assignees";
import {
  CloudUploadIcon,
  GreenTick,
  QRIcon,
  ShipIcon,
} from "../../common/icons";
import { ORDER_STATUS_ENUM, USER_ROLES } from "../../constants";
import {
  ORDERS,
  ORDER_LINE_ITEMS,
  RAILS_APP_API_URL,
  STORES,
  STORE_TOKEN,
  UPDATE_IMAGE,
} from "../../constants/BackendRoutes";
import { ORDERS_OVERVIEW } from "../../constants/FrontendRoutes";
import Lightning from "../../images/lightning.svg";
import { selectCurrentUser } from "../../redux/authSlice";
import { setLoading } from "../../redux/loadingSlice";
import { addToast } from "../../redux/toastSlice";
import theme from "../../theme";
import {
  calculateDaysRemaining,
  convertDateFormat,
  filterLineItems,
  formatTo24Hour,
  generateRandomString
} from "../../utils";
import { mapAssignees } from "../../utils/assignees";
import { resizeFile } from "../../utils/imageResizer";
import Capsule from "../Capsule";
import EntityPage from "../EntityPage";
import DropdownButton from "../Form/DropdownButton";
import CustomDatePicker from "../Form/Field/DatePicker";
import AssignOrderModal from "../Modal/AssignOrderModal";
import CollapsibleRow from "./CollapsibleRow";
import GenerateQRCodeModal from "./GenerateQRCodeModal";
import ImageSliderModal from "./ImageSliderModal";
import LabelModal from "./LabelModal";
import OrderDetailsFooter from "./OrderDetailsFooter";
import QRCodeModal from "./QRCodeModal";
import QRScanner from "./QrScanner";
import SubStatuses from "./SubStatuses";
import StatusCapsule from "../../common/components/StatusCapsule";
import { selectCurrentStore } from "../../redux/storeSlice";
import { selectCurrentOrganization } from "../../redux/organizationSlice";

const OrderDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const currentStore = useSelector(selectCurrentStore);
  const currentOrganization = useSelector(selectCurrentOrganization);

  const inputRef = useRef(null);
  const ref = useRef(null);
  const [order, setOrder] = useState({});
  const [user, setUser] = useState({});
  const [orderLineItems, setOrderLineItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [openQr, setQrOpen] = useState(false);

  const [openQrCodeModal, setOpenQrCodeModal] = useState(false);
  const [token, setToken] = useState("");
  const [isGenerateQRCode, setIsGenerateQRCode] = useState(false);
  const [isLabelModalOpen, setIsLabelModalOpen] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [openRowIndex, setOpenRowIndex] = useState(null);
  const [showGreenTick, setShowGreenTick] = useState(false);
  const [editDate, setEditDate] = useState(false);
  const [daysRemaining, setDaysRemaining] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    data: null,
    status: "",
    name: "",
    itemId: 0,
  });

  const [assignments, setAssignments] = useState([]);
  const [usersData, setUsersData] = useState([]);

  const setAssignmentsData = () => {
    const mappedOrderAssignees = mapAssignees(orderLineItems);
    setAssignments(mappedOrderAssignees);
  };

  useEffect(() => {
    setAssignmentsData();
  }, [orderLineItems]);

  const handleRowClick = (index) => {
    setOpenRowIndex(openRowIndex === index ? null : index);
  };

  const role = currentStore?.role;
  const storeId = currentStore?.store_id;
  const isAdminOrManager = role === USER_ROLES.ADMIN || role === USER_ROLES.MANAGER;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleQrOpen = () => setQrOpen(true);
  const handleQrClose = () => setQrOpen(false);
  const handleImageModal = (
    isOpen,
    data,
    status = "",
    name = "",
    itemId = 0
  ) => {
    setImageModal({
      isOpen,
      data,
      status,
      name,
      itemId,
    });
  };

  const handleDateUpdate = async (e) => {
    const data = {
      order: {
        estimated_completion: e,
      },
    };
    try {
      await updateRequest(
        `${STORES}/${currentStore?.store_id}${ORDERS}/${order.id}`,
        data
      );
      dispatch(addToast("Order Est. Date is updated"));
      fetchOrder();
      setEditDate(false);
      document.removeEventListener("mousedown", handleClickOutside);
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  const handleLabelModal = async (value) => {
    if (value === "create") {
      setIsLabelModalOpen(true);
    } else if (value === "download") {
      if (!order.shipping_labels || order.shipping_labels.length === 0) {
        dispatch(addToast("No shipping label found!"));
        return;
      }
      for (const label of order.shipping_labels) {
        if (label?.label_pdf?.url) {
          const labelUrl = `${process.env.REACT_APP_BACKEND_URL}${label.label_pdf.url}`;
          window.open(labelUrl, "_blank");
        }
      }
    }
  };

  const handleOpenOrderAssignModal = () => {
    setIsAssignModalOpen(true);
  };

  const handleCloseOrderAssignModal = () => {
    setIsAssignModalOpen(false);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setEditDate(false);
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const isEmpty = (obj) => Object.keys(obj).length === 0;

  const tableHeaderClasses = "font-questrial text-xs text-gray-800";

  const fetchOrder = async () => {
    try {
      const includes = "sub_statuses,user.addresses,order_line_items,shipping_labels,order_line_items.order_assignees.user";
      const fields = "order.*,order_line_item.*,shipping_label.*";
      const data = await getRequest(
        `${STORES}/${currentStore?.store_id}${ORDERS}/${id}`,
        {},
        `${includes}&fields=${fields}`
      );
      setOrder(data);
      setSelectedStatus(data?.status);
      setDaysRemaining(calculateDaysRemaining(data?.estimated_completion));
      setUser(data?.user);
      setOrderLineItems(data?.order_line_items);
    } catch (error) {
      dispatch(addToast(error.message || "Something went Wrong!"));
      console.error("Error fetching order:", error);
    }
  };

  useEffect(() => {
    if (token) {
      postToken();
    }
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      const roles = [USER_ROLES.MANAGER, USER_ROLES.REPAIR_TEAM];
      const data = await fetchStoreUsers(storeId, roles);
      setUsersData(data);
    };

    fetchData();
  }, []);

  const postToken = async () => {
    const response = await postRequest(`${STORE_TOKEN}`, {
      user_id: currentUser?.id,
      token,
    });

    if (response.data.status === "success") {
      handleOpen();
    }
  };

  const handleScan = async () => {
    setToken(generateRandomString(20));
    handleOpen();
  };

  const handleGenerateQRCode = () => {
    setOpenQrCodeModal(true);
    handleClose();
  };

  const handleScanQRCode = () => {
    handleQrOpen();
  };

  const handleCloseQrCodeModal = () => {
    setOpenQrCodeModal(false);
    setIsGenerateQRCode(false);
  };

  const calculateProgress = () => {
    if (ORDER_STATUS_ENUM[order.status] === 0) return 0;
    const progressPercentage = parseInt(
      (ORDER_STATUS_ENUM[order.status] / 5) * 100
    );
    if (progressPercentage < 0 || progressPercentage > 100) return 0;
    return progressPercentage;
  };

  function UploadInput({ repairLineItemId, isBefore, isMobile }) {
    const inputId = `contained-button-file-${repairLineItemId}-${
      isBefore ? "before" : "after"
    }`;
    const imageType = isBefore
      ? "order_line_item[before_images]"
      : "order_line_item[after_images]";

    const uploadPicture = async (event) => {
      try {
        dispatch(setLoading(true));
        const files = event.target.files;
        const formData = new FormData();
        for (const file of files) {
          const resizedImage = await resizeFile(file);
          formData.append(`${imageType}[]`, resizedImage);
        }
        await updateRequest(
          `${STORES}/${currentStore?.store_id}${ORDERS}/${id}/${ORDER_LINE_ITEMS}/${repairLineItemId}${UPDATE_IMAGE}`,
          formData,
          { "Content-Type": "multipart/form-data" }
        );
        await fetchOrder();
        dispatch(addToast("Images Uploaded Successfully!"));
      } catch (error) {
        dispatch(
          addToast(
            error.message || "Something went Wrong! \nImage is not uploaded!"
          )
        );
        console.error("Error uploading images:", error);
      } finally {
        dispatch(setLoading(false));
      }
    };

    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <label htmlFor={inputId}>
          <input
            accept="image/*"
            id={inputId}
            multiple
            type="file"
            style={{ display: "none" }}
            ref={inputRef}
            onChange={uploadPicture}
          />
          <UploadButton
            variant="contained"
            component="span"
            startIcon={<CloudUploadIcon />}
            sx={{ maxWidth: "140px", maxHeight: isMobile && "40px" }}
          >
            {isMobile ? (
              <div>Upload</div>
            ) : (
              <p className="text-[#4C8C4A] font-normal">
                Browse file
                <span className=" text-gray-500"> or drag & drop</span>
              </p>
            )}
          </UploadButton>
        </label>
      </Box>
    );
  }

  const handleImageDelete = async (itemId, imageType, imageId) => {
    deleteRequest(
      `/stores/${currentStore?.store_id}/orders/${id}/order_line_items/${itemId}/delete_images`,
      {
        data: { [`${imageType}_image_id`]: imageId },
      }
    );
    fetchOrder();
  };

  function calculateTotalSum(object) {
    if (object.price) return object.price;

    const optionsSum =
      (object.options &&
        object.options.reduce((total, option) => total + option.price, 0)) ||
      0;
    const servicesSum =
      (object.services &&
        object.services.reduce((total, service) => total + service.price, 0)) ||
      0;

    return optionsSum + servicesSum;
  }

  const handleQRCode = (value) => {
    if (value === "scanQR") {
      handleScanQRCode();
    } else if (value === "generateQR") {
      handleGenerateQRCode();
    } else if (value === "scanBarcode") {
      handleScan();
    }
  };

  const formatDate = (dateString) => {
    // Create a new Date object from the date string
    const date = new Date(dateString);

    // Get the month, day, and year
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    // Return the formatted date string
    return `${month}/${day}/${year}`;
  };

  const handleTrackingId = () => {
    if (!order.shipping_labels?.length) {
      return "-------";
    }

    const trackingNumbers = order.shipping_labels
      .map((shipment) => ({
        leg: `Leg #${shipment.shipping_leg.split("_")[1]}`,
        number: shipment.tracking_number,
        trackingURL: shipment.tracking_url,
        legNumber: parseInt(shipment.shipping_leg.split("_")[1], 10),
      }))
      .sort((a, b) => a.legNumber - b.legNumber);

    return (
      <>
        <div className="hidden md:block">
          {trackingNumbers.map((item, index) => (
            <div key={index}>
              {item.leg}:{" "}
              <a
                href={item.trackingURL}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {item.number}
              </a>
            </div>
          ))}
        </div>
        <div className="md:hidden font-['Questrial'] text-[16px] font-normal pl-[36px] grid grid-cols-[min-content,1fr] gap-[12px]">
          <div className="text-[#939291] text-nowrap row-span-2">
            Tracking #
          </div>
          {trackingNumbers.map((item, index) => (
            <div key={index}>
              {item.leg}:{" "}
              <a
                href={item.trackingURL}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {item.number}
              </a>
            </div>
          ))}
        </div>
      </>
    );
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  const renderAssignmentModal = () => {
    if (!isAdminOrManager) return null;

    return (
      <>
        <Button
          sx={{
            borderColor: "lightgray",
            color: "#272523",
            "&:hover": {
              borderColor: "#272523",
              color: "#272523",
              backgroundColor: "lightgray",
            },
          }}
          variant="outlined"
          startIcon={<PersonAddAlt1Icon />}
          onClick={handleOpenOrderAssignModal}
        >
          Assign to
        </Button>
        <AssignOrderModal
          id={id}
          users={usersData}
          show={isAssignModalOpen}
          orderLineItemsData={orderLineItems}
          fetchOrder={fetchOrder}
          handleClose={handleCloseOrderAssignModal}
        />
      </>
    );
  };

  return (
    <EntityPage title="Order Details" breadcrumbs={{ href: ORDERS_OVERVIEW }}>
      <Box className="flex flex-col items-center gap-7">
        <Modal open={openQr} onClose={handleQrClose}>
          <QRScanner handleClose={handleQrClose} />
        </Modal>
        <Modal open={open} onClose={handleClose}>
          <QRCodeModal token={token} handleClose={handleClose} />
        </Modal>
        <Modal open={openQrCodeModal} onClose={handleCloseQrCodeModal}>
          <GenerateQRCodeModal
            lineItems={filterLineItems(orderLineItems, "service")}
            dueDate={order.shipping_date}
            handleClose={handleCloseQrCodeModal}
            isGenerateQRCode={isGenerateQRCode}
            user={user}
            order={order}
            orderId={id}
          />
        </Modal>
        <Modal
          open={isLabelModalOpen}
          onClose={() => setIsLabelModalOpen(false)}
        >
          <LabelModal
            isFinalShippingLeg={
              order.shipping_labels?.length > 0 &&
              order.shipping_labels.some(
                (label) => label.shipping_leg == "leg_2"
              )
            }
            handleClose={() => setIsLabelModalOpen(false)}
            customer={user}
            orderId={id}
          />
        </Modal>
        <Modal
          open={imageModal.isOpen}
          onClose={() => handleImageModal(false, null)}
        >
          <ImageSliderModal
            handleClose={() => handleImageModal(false, null)}
            orderId={id}
            modalData={imageModal}
            handleDelete={handleImageDelete}
            currentUser={currentUser}
            currentStor={currentStore}
            handleUpdateImage={fetchOrder}
          />
        </Modal>
        <div className="lg:hidden max-w-[1550px] w-full items-center justify-between">
          <div className="flex-row w-full justify-between items-center">
            <div className="flex flex-col gap-[8px]">
              <div className="flex items-center flex-wrap gap-5 justify-start">
                <Typography variant="text-[#272523] font-montserrat text-lg font-semibold">
                  Order #{order.id}
                </Typography>
                <div className="flex ms-3 gap-2">
                  {order && order.rush_fee !== 0 && (
                    <>
                      <img
                        alt="LightningLogo"
                        src={Lightning}
                        className="w-5 h-5 mr-2 mt-[2px]"
                      />
                    </>
                  )}
                  <StatusCapsule order={order} fetchOrder={fetchOrder}/>
                  {order.placement_type === "online" && (
                    <Capsule
                      textColor="text-[#4968A6]"
                      capsuleColor="bg-[#D8E1F8]"
                      text="Online order"
                      LeftIcon={
                        <CircleSharp
                          style={{ fontSize: "0.5rem" }}
                          className="text-[#4968A6]"
                        />
                      }
                    />
                  )}
                  <SubStatuses
                    selectedStatus={selectedStatus}
                    id={id}
                    order={order}
                  />
                </div>
                <div className="w-[1px] mx-3 mt-1 h-10 bg-[#DFDFDF] text-lg"></div>
                <div className="flex flex-col items-start justify-start">
                  <Typography className="text-[#939291]">
                    Est. completion date:
                  </Typography>
                  {order.estimated_completion ? (
                    <div className="flex flex-col">
                      <Typography>
                        <p
                          ref={ref}
                          className={`${
                            daysRemaining < 7 && "text-red-600"
                          } mr-3`}
                          onClick={() => {
                            setEditDate(true);
                          }}
                        >
                          {editDate ? (
                            <CustomDatePicker
                              onOpen={() => {
                                document.removeEventListener(
                                  "mousedown",
                                  handleClickOutside
                                );
                              }}
                              onClose={() => {
                                document.addEventListener(
                                  "mousedown",
                                  handleClickOutside
                                );
                              }}
                              value={moment(order.estimated_completion)}
                              onChange={(e) => {
                                handleDateUpdate(e);
                              }}
                            />
                          ) : (
                            <div className="cursor-pointer">
                              {moment(order.estimated_completion).format(
                                "MM/DD/YYYY"
                              )}
                            </div>
                          )}
                        </p>
                      </Typography>
                      {daysRemaining !== null && daysRemaining < 7 && (
                        <Typography className="text-red-600 mx-auto">
                          Due in {daysRemaining} day
                          {daysRemaining !== 1 ? "s" : ""}
                        </Typography>
                      )}
                    </div>
                  ) : (
                    <CustomDatePicker
                      onOpen={() => {
                        document.removeEventListener(
                          "mousedown",
                          handleClickOutside
                        );
                      }}
                      onClose={() => {
                        document.addEventListener(
                          "mousedown",
                          handleClickOutside
                        );
                      }}
                      value={moment(order.estimated_completion)}
                      onChange={(e) => {
                        handleDateUpdate(e);
                      }}
                    />
                  )}
                </div>
                {renderAssignmentModal()}
                <div className="flex flex-row gap-2">
                  <div className="flex gap-5">
                    <DropdownButton
                      disabled={!currentOrganization?.shipstation_token?.trim()}
                      button={{
                        text: (
                          <>
                            <ShipIcon />
                            Ship order
                          </>
                        ),
                        returnedValue: "create",
                      }}
                      menuItems={[
                        {
                          text: "Download Shipping label",
                          returnedValue: "download",
                        },
                      ]}
                      onClick={handleLabelModal}
                      variant="outlined"
                      color="secondary"
                    />
                    <DropdownButton
                      button={{
                        text: <QRIcon />,
                        returnedValue: "scanQR",
                      }}
                      menuItems={[
                        {
                          text: "Scan QR code",
                          returnedValue: "scanQR",
                        },
                        {
                          text: "Generate QR code",
                          returnedValue: "generateQR",
                        },
                      ]}
                      onClick={handleQRCode}
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-row items-center h-[17px] gap-2">
                <LinearProgress
                  className="flex-grow h-[5px] min-h-[5px] rounded-[100px]"
                  variant="determinate"
                  color="success"
                  value={calculateProgress()}
                />
                <div className="text-[12px] text-[#5D5C5A] font-normal font-['Questrial']">
                  {calculateProgress()}%
                </div>
              </div>
            </div>
          </div>
          <Box className="w-full">
            <Paper className="w-full rounded-t-lg-[10px] mt-10 mb-[2px]">
              <Table aria-labelledby="tableTitle">
                <TableBody>
                  {orderLineItems?.map((row, index) => {
                    const {
                      name,
                      category,
                      categoryType,
                      description,
                      services,
                      options,
                      id,
                    } = row.details;
                    const isProduct = row.line_item_type === "product";
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <React.Fragment key={row.trackingNumber}>
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.trackingNumber}
                          style={{
                            border: "none",
                          }}
                        >
                          <CollapsibleRow
                            index={index}
                            isProduct={isProduct}
                            name={name}
                            category={category}
                            categoryType={categoryType}
                            services={services}
                            options={options}
                            description={description}
                            openRowIndex={openRowIndex}
                            assignments={assignments}
                            lineItemId={row.id}
                            storeId={storeId}
                            currentUserId={currentUser?.id}
                            handleRowClick={handleRowClick}
                            fetchOrder={fetchOrder}
                          />
                          <div className="font-['Questrial'] text-[16px] font-normal pl-[36px] grid grid-cols-2 gap-[12px]">
                            <div className="text-[#939291]">
                              Order created date
                            </div>
                            <div className="text-[#272523]">
                              {convertDateFormat(order.created_at)}
                            </div>
                            {order?.shipping_labels?.length > 0 && (
                              <div className="text-[#939291]">
                                Shipping date
                              </div>
                            )}
                            {order?.shipping_labels?.length > 0 && (
                              <div className="text-[#272523]">
                                {convertDateFormat(order.shipping_date)}
                              </div>
                            )}
                            <div className="text-[#939291]">
                              In progress date
                            </div>
                            <div className="text-[#272523]">
                              {convertDateFormat(order.in_progress_date)}
                            </div>
                          </div>
                          <Divider />
                          <div className="font-['Questrial'] text-[16px] text-[#939291] font-normal flex flex-row pl-[36px] justify-between mr-10">
                            <div className="flex flex-col gap-2">
                              <div>Before image(s)</div>
                              {row?.before_images_data?.length > 0 && (
                                <div
                                  className="relative w-[112px] h-[59px] cursor-pointer"
                                  onClick={() =>
                                    handleImageModal(
                                      true,
                                      row.before_images_data,
                                      "before",
                                      isProduct ? name : categoryType,
                                      row.id
                                    )
                                  }
                                >
                                  <img
                                    className="w-full h-full object-cover bg-opacity-90 rounded-md"
                                    src={`${RAILS_APP_API_URL}${row.before_images_data[0].url}`}
                                    alt="Before"
                                  />
                                  <div
                                    className={`absolute top-0 left-0 w-full h-full flex items-center justify-center text-white font-bold ${
                                      row?.before_images_data?.length > 0 &&
                                      "bg-black bg-opacity-50 rounded-md"
                                    }`}
                                  >
                                    {row?.before_images_data?.length - 1 !==
                                      0 && (
                                      <div className="flex text-[12px] text-center flex-col">
                                        <div className="font-['Montserrat'] font-[700]">
                                          +{" "}
                                          {row?.before_images_data?.length - 1}{" "}
                                          more
                                        </div>
                                        <div className="font-['Questrial'] font-normal">
                                          See all
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                              <div>
                                <UploadInput
                                  isBefore={true}
                                  repairLineItemId={row.id}
                                  isMobile={true}
                                />
                              </div>
                            </div>
                            <div className="flex flex-col gap-2">
                              <div>After image(s)</div>
                              {row?.after_images_data?.length > 0 && (
                                <div
                                  className="relative w-[112px] h-[59px] cursor-pointer"
                                  onClick={() =>
                                    handleImageModal(
                                      true,
                                      row.after_images_data,
                                      "after",
                                      isProduct ? name : categoryType,
                                      row.id
                                    )
                                  }
                                >
                                  <img
                                    className="w-full h-full object-cover bg-opacity-90 rounded-md"
                                    src={`${RAILS_APP_API_URL}${row.after_images_data[0].url}`}
                                    alt="After"
                                  />
                                  <div
                                    className={`absolute top-0 left-0 w-full h-full flex items-center justify-center text-white font-bold ${
                                      row?.after_images_data?.length > 1 &&
                                      "bg-black bg-opacity-50 rounded-md"
                                    }`}
                                  >
                                    <div className="flex flex-col">
                                      {row?.after_images_data?.length - 1 !==
                                        0 && (
                                        <div className="flex text-[12px] text-center flex-col">
                                          <div className="font-['Montserrat'] font-[700]">
                                            +{" "}
                                            {row?.after_images_data?.length - 1}{" "}
                                            more
                                          </div>
                                          <div className="font-['Questrial'] font-normal">
                                            See all
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div>
                                <UploadInput
                                  isBefore={false}
                                  repairLineItemId={row.id}
                                  isMobile={true}
                                />
                              </div>
                            </div>
                          </div>
                          <Divider />
                          <div className="font-['Questrial'] text-[16px] font-normal pl-[36px] grid grid-cols-2 gap-[12px]">
                            <div className="text-[#939291]">Price</div>
                            <div className="font-['Montserrat'] text-[18px] font-[600] text-[#272523]">
                              ${calculateTotalSum(row.details)}
                            </div>
                          </div>
                          {order?.shipping_labels?.length > 0 && <Divider />}
                          {order?.shipping_labels?.length > 0 &&
                            handleTrackingId()}
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </Box>
        </div>
        <Paper className="lg:block hidden max-w-[1550px] w-full p-5 items-center justify-between">
          <div className="flex flex-col w-full gap-3">
            <div className="flex gap-2">
              {order && order.rush_fee !== 0 && (
                <>
                  <img
                    alt="LightningLogo"
                    src={Lightning}
                    className="w-5 h-5 mr-2 mt-[2px]"
                  />
                </>
              )}
              <Typography variant="text-[#272523] font-montserrat text-lg font-semibold">
                Order #{order.id}
              </Typography>
              <StatusCapsule order={order} fetchOrder={fetchOrder}/>
              {order.placement_type === "online" && (
                <Capsule
                  textColor="text-[#4968A6]"
                  capsuleColor="bg-[#D8E1F8]"
                  text="Online order"
                  LeftIcon={
                    <CircleSharp
                      style={{ fontSize: "0.5rem" }}
                      className="text-[#4968A6]"
                    />
                  }
                />
              )}
              <SubStatuses
                selectedStatus={selectedStatus}
                id={id}
                order={order}
              />
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row gap-2 items-center">
                <div className="flex flex-col">
                  <Typography>Progress of Order</Typography>
                  <div className="flex items-center lg:w-[300px]">
                    <div className="w-full mr-1">
                      <LinearProgress
                        className="rounded-[20%]"
                        variant="determinate"
                        color="success"
                        value={calculateProgress()}
                      />
                    </div>
                    <Typography>{calculateProgress()}%</Typography>
                  </div>
                </div>
                <div className="w-[1px] mx-3 mt-1 h-10 bg-[#DFDFDF] text-lg"></div>
                <div className="flex flex-col items-start justify-start w-[40%]">
                  <Typography className="text-[#939291]">
                    Est. completion date:
                  </Typography>
                  {order.estimated_completion ? (
                    <div className="flex flex-col">
                      <Typography>
                        <p
                          ref={ref}
                          className={`${
                            daysRemaining < 7 && "text-red-600"
                          } mr-3`}
                          onClick={() => {
                            setEditDate(true);
                          }}
                        >
                          {editDate ? (
                            <CustomDatePicker
                              onOpen={() => {
                                document.removeEventListener(
                                  "mousedown",
                                  handleClickOutside
                                );
                              }}
                              onClose={() => {
                                document.addEventListener(
                                  "mousedown",
                                  handleClickOutside
                                );
                              }}
                              value={moment(order.estimated_completion)}
                              onChange={(e) => {
                                handleDateUpdate(e);
                              }}
                            />
                          ) : (
                            <div className="cursor-pointer">
                              {moment(order.estimated_completion).format(
                                "MM/DD/YYYY"
                              )}
                            </div>
                          )}
                        </p>
                      </Typography>
                      {daysRemaining !== null && daysRemaining < 7 && (
                        <Typography className="text-red-600 mx-auto">
                          Due in {daysRemaining} day
                          {daysRemaining !== 1 ? "s" : ""}
                        </Typography>
                      )}
                    </div>
                  ) : (
                    <CustomDatePicker
                      onOpen={() => {
                        document.removeEventListener(
                          "mousedown",
                          handleClickOutside
                        );
                      }}
                      onClose={() => {
                        document.addEventListener(
                          "mousedown",
                          handleClickOutside
                        );
                      }}
                      value={moment(order.estimated_completion)}
                      onChange={(e) => {
                        handleDateUpdate(e);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-wrap flex-row gap-2">
                <div className="flex justify-between gap-2">
                  <div className="flex items-center gap-2">
                    <div
                      className={`transition-opacity duration-1000 ${
                        showGreenTick ? "opacity-100" : "opacity-0"
                      }`}
                    >
                      <GreenTick />
                    </div>
                    <Typography color="textSecondary">
                      <span className="text-nowrap">Last saved on</span>{" "}
                      <span className="text-nowrap">
                        {formatTo24Hour(order.updated_at)}
                      </span>
                    </Typography>
                  </div>
                </div>
                {renderAssignmentModal()}
                <div className="flex gap-5">
                  <DropdownButton
                    disabled={!currentOrganization?.shipstation_token?.trim()}
                    button={{
                      text: (
                        <>
                          <ShipIcon />
                          Ship order
                        </>
                      ),
                      returnedValue: "create",
                    }}
                    menuItems={[
                      {
                        text: "Download Shipping label",
                        returnedValue: "download",
                      },
                    ]}
                    onClick={handleLabelModal}
                    variant="outlined"
                    color="secondary"
                  />
                  <DropdownButton
                    button={{
                      text: "Scan and track",
                      returnedValue: "scanQR",
                    }}
                    menuItems={[
                      {
                        text: "Scan and track",
                        returnedValue: "scanQR",
                      },
                      {
                        text: "Generate QR code",
                        returnedValue: "generateQR",
                      },
                    ]}
                    onClick={handleQRCode}
                  />
                </div>
              </div>
            </div>
          </div>
          <Box className="w-full">
            <Paper className="w-full rounded-t-lg-[10px] mt-10 mb-[2px]">
              <TableContainer>
                <Table className="min-w-[750px]" aria-labelledby="tableTitle">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={`${tableHeaderClasses} min-w-[350px]`}
                      >
                        Order Items
                      </TableCell>
                      <TableCell className={tableHeaderClasses}>
                        Order Created
                      </TableCell>
                      {order?.shipping_labels?.length > 0 && (
                        <TableCell className={tableHeaderClasses}>
                          Shipping Date
                        </TableCell>
                      )}
                      <TableCell className={tableHeaderClasses}>
                        In progress date
                      </TableCell>
                      <TableCell className={tableHeaderClasses}>
                        Before image(s)
                      </TableCell>
                      <TableCell className={tableHeaderClasses}>
                        After image(s)
                      </TableCell>
                      <TableCell className={tableHeaderClasses}>
                        Price
                      </TableCell>
                      {order?.shipping_labels?.length > 0 && (
                        <TableCell className={tableHeaderClasses}>
                          Tracking #
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderLineItems?.map((row, index) => {
                      const {
                        name,
                        category,
                        categoryType,
                        description,
                        services,
                        options,
                      } = row.details;
                      const isProduct = row.line_item_type === "product";
                      return (
                        <React.Fragment key={row.trackingNumber}>
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.trackingNumber}
                            style={{
                              border: "none",
                            }}
                          >
                            <TableCell>
                              <CollapsibleRow
                                index={index}
                                isProduct={isProduct}
                                name={name}
                                category={category}
                                categoryType={categoryType}
                                services={services}
                                options={options}
                                description={description}
                                openRowIndex={openRowIndex}
                                assignments={assignments}
                                lineItemId={row.id}
                                storeId={storeId}
                                currentUserId={currentUser?.id}
                                handleRowClick={handleRowClick}
                                fetchOrder={fetchOrder}
                              />
                            </TableCell>
                            <TableCell>
                              {convertDateFormat(order.created_at)}
                            </TableCell>
                            {order?.shipping_labels?.length > 0 && (
                              <TableCell>
                                {convertDateFormat(order.shipping_date)}
                              </TableCell>
                            )}
                            <TableCell>
                              {convertDateFormat(order.in_progress_date)}
                            </TableCell>
                            <TableCell>
                              <Box className="flex flex-col items-center gap-2">
                                {row?.before_images_data?.length > 0 && (
                                  <div
                                    className="relative w-[131px] h-[68px] cursor-pointer"
                                    onClick={() =>
                                      handleImageModal(
                                        true,
                                        row.before_images_data,
                                        "before",
                                        isProduct ? name : categoryType,
                                        row.id
                                      )
                                    }
                                  >
                                    <img
                                      className="w-full h-full object-cover bg-opacity-90 rounded-md"
                                      src={`${RAILS_APP_API_URL}${row.before_images_data[0].url}`}
                                      alt="Before"
                                    />
                                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-white font-bold">
                                      {row?.before_images_data?.length - 1 !==
                                        0 && (
                                        <div className="flex flex-col">
                                          <Typography
                                            style={theme.typography.body}
                                          >
                                            +{" "}
                                            {row?.before_images_data?.length -
                                              1}{" "}
                                            more
                                          </Typography>
                                          <Typography className="font-montserrat text-xs text-center">
                                            See all
                                          </Typography>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                                <UploadInput
                                  isBefore={true}
                                  repairLineItemId={row.id}
                                />
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box className="flex flex-col items-center gap-2">
                                <Box className="flex flex-col">
                                  {row?.after_images_data?.length > 0 && (
                                    <div
                                      className="relative w-[131px] h-[68px] cursor-pointer"
                                      onClick={() =>
                                        handleImageModal(
                                          true,
                                          row.after_images_data,
                                          "after",
                                          isProduct ? name : categoryType,
                                          row.id
                                        )
                                      }
                                    >
                                      <img
                                        className="w-full h-full object-cover bg-opacity-90 rounded-md"
                                        src={`${RAILS_APP_API_URL}${row.after_images_data[0].url}`}
                                        alt="Before"
                                      />
                                      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-white font-bold">
                                        <div className="flex flex-col">
                                          {row?.after_images_data?.length -
                                            1 !==
                                            0 && (
                                            <div className="flex flex-col">
                                              <Typography
                                                style={theme.typography.body}
                                              >
                                                +{" "}
                                                {row?.after_images_data
                                                  ?.length - 1}
                                                more
                                              </Typography>
                                              <Typography className="font-montserrat text-xs text-center">
                                                See all
                                              </Typography>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Box>
                                <UploadInput
                                  isBefore={false}
                                  repairLineItemId={row.id}
                                />
                              </Box>
                            </TableCell>
                            <TableCell>
                              $ {calculateTotalSum(row.details)}
                            </TableCell>
                            {order?.shipping_labels?.length > 0 && (
                              <TableCell>{handleTrackingId()}</TableCell>
                            )}
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Paper>
        {(!isEmpty(user) || !isEmpty(order)) && (
          <OrderDetailsFooter
            user={user}
            order={order}
            setShowGreenTick={setShowGreenTick}
            fetchOrder={fetchOrder}
          />
        )}
      </Box>
    </EntityPage>
  );
};

export default OrderDetailsPage;

const UploadButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.background.paper),
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
  },
  boxShadow: "none",
  textTransform: "none",
  fontSize: 12,
  padding: "10px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  borderColor: theme.palette.grey[300],
  borderRadius: theme.shape.borderRadius,
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
}));
