import AddIcon from "@mui/icons-material/Add";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteRequest, postRequest } from "../../api";
import { setLoading } from "../../redux/loadingSlice";
import { fetchSubStatuses } from "../../redux/storeAction";
import { selectCurrentStore, selectSelectedSubStatuses, selectSubStatuses, setSelectedSubStatuses } from "../../redux/storeSlice";
import { addToast } from "../../redux/toastSlice";
import Capsule from "../Capsule";
import SubStatusModal from "../Modal/SubStatusModal";

const SubStatuses = ({ selectedStatus, id, order }) => {
  const [dropdownAnchor, setDropdownAnchor] = useState(null);
  const [matchingSubStatusNames, setMatchingSubStatusNames] = useState([]);
  const [isEditingLabels, setIsEditingLabels] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const dispatch = useDispatch();
  const currentStore = useSelector(selectCurrentStore);
  const subStatuses = useSelector(selectSubStatuses);
  const selectedSubStatuses = useSelector(selectSelectedSubStatuses);

  const handleDropdownToggle = (event) => {
    if (!isEmpty(matchingSubStatusNames)) {
      setDropdownAnchor(event.currentTarget);
    } else {
      setIsStatusModalOpen(true);
    }
  };

  const handleDropdownClose = () => {
    setDropdownAnchor(null);
  };

  const handleClose = () => {
    setIsStatusModalOpen(false);
    setIsEditingLabels(false);
  };

  const handleOpen = () => {
    setIsStatusModalOpen(true);
  }

  const handleSubStatusSelect = async (subStatus) => {
    try {
      dispatch(setLoading(true));
      const response = await postRequest(
        `stores/${currentStore?.store_id}/orders/${id}/add_substatus`,
        {
          sub_status_id: subStatus?.id,
        }
      );
      if (response?.data?.success) {
        dispatch(setSelectedSubStatuses([...selectedSubStatuses, subStatus]));
        setDropdownAnchor(null);
        dispatch(addToast("Successfully Added sub-status"));
      }
    } catch (error) {
      dispatch(addToast("Error adding sub-status"));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleRemoveSubStatus = async (subStatusId) => {
    try {
      dispatch(setLoading(true));
      const response = await deleteRequest(
        `stores/${currentStore?.store_id}/orders/${id}/remove_substatus/${subStatusId}`
      );
      if (response?.success) {
        dispatch(setSelectedSubStatuses(
          selectedSubStatuses.filter((status) => status?.id !== subStatusId)
      ));
        dispatch(addToast("Successfully removed sub-status"));
      }
    } catch (error) {
      dispatch(addToast("Error removing sub-status"));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const availableSubStatuses = matchingSubStatusNames.filter((status) => {
    return !selectedSubStatuses.some((selected) => selected.id === status.id);
  });

  const handleEditLabelsClick = () => {
    dispatch(fetchSubStatuses(currentStore?.store_id));
    setIsEditingLabels(true);
    handleDropdownClose();
    setIsStatusModalOpen(true);
  };

  useEffect(() => {
    if (selectedStatus) {
      const matchingSubStatuses = subStatuses?.filter(
        (subStatus) => subStatus.order_status === selectedStatus
      );

      setMatchingSubStatusNames(
        matchingSubStatuses.map((subStatus) => ({
          id: subStatus.id,
          name: subStatus.name,
        }))
      );

      const currentOrderSubStatuses = selectedSubStatuses || [];
      const newSelectedSubStatuses = matchingSubStatuses.filter((subStatus) =>
        currentOrderSubStatuses.some(
          (orderStatus) => orderStatus.id === subStatus.id
        )
      );
      dispatch(setSelectedSubStatuses(newSelectedSubStatuses));
    }
  }, [selectedStatus, subStatuses]);

  useEffect(() => {
    if (!isEmpty(currentStore?.store)) {
      dispatch(fetchSubStatuses(currentStore?.store_id));
    }
  }, []);

  useEffect(() => {
    if (selectedStatus) {
      dispatch(setSelectedSubStatuses(order?.sub_statuses?.filter(
        (os) => os?.order_status === selectedStatus
      )));
    }
  }, [dispatch, order?.sub_statuses]);

  return (
    <div className="flex items-center">
      {!isEmpty(selectedSubStatuses) ? (
        <div className="flex items-center mr-2">
          <Box
            sx={{
              display: "flex",
              overflowX: "auto",
              whiteSpace: "nowrap",
              maxWidth: "800px",
              gap: "8px",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {selectedSubStatuses?.map((subStatus) => (
              <Box key={subStatus?.id} sx={{ mr: 0.5 }}>
                <Capsule
                  textColor="text-[black]"
                  capsuleColor="bg-[#E8E8E8]"
                  text={subStatus?.name}
                  RightIcon={
                    <CloseIcon
                      style={{ fontSize: "0.5rem" }}
                      sx={{
                        cursor: "pointer",
                      }}
                      className="hover-effect"
                      onClick={() => handleRemoveSubStatus(subStatus?.id)}
                    />
                  }
                />
              </Box>
            ))}
          </Box>
          <Typography className="text-[#939291]">
            <Button
              onClick={handleDropdownToggle}
              className="bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center mx-2 text-gray-500 hover:bg-[#4C8C4A] hover:text-white"
            >
              <AddIcon
                fontSize="small"
                sx={{
                  color: "inherit",
                  "&:hover": {
                    backgroundColor: "#4C8C4A",
                    color: "white",
                  },
                }}
              />
            </Button>
          </Typography>
        </div>
      ) : (
        <Typography
          sx={{
            color: "gray",
            "&:hover": {
              color: "#4C8C4A",
              backgroundColor: "white",
            },
            "&:hover .add-icon": {
              backgroundColor: "#4C8C4A",
              color: "white",
            },
          }}
        >
          <Button
            onClick={handleDropdownToggle}
            className="rounded-full flex items-center w-auto h-8 mx-2"
          >
            <AddIcon
              fontSize="small"
              className="add-icon"
              sx={{
                color: "inherit",
                borderRadius: 100,
              }}
            />
            <span className="ml-1 rounded-full flex items-center w-auto h-8 mx-2">
              {!isEmpty(matchingSubStatusNames)
                ? "Attach sub-status"
                : "Create sub-status"}
            </span>
          </Button>
        </Typography>
      )}

      <Menu
        anchorEl={dropdownAnchor}
        open={Boolean(dropdownAnchor)}
        onClose={handleDropdownClose}
      >
        <Box
          sx={{
            maxHeight: availableSubStatuses?.length > 5 ? "200px" : "auto",
            overflowY: availableSubStatuses?.length > 5 ? "scroll" : "visible",
            pr: 1,
            mr: -2,
          }}
        >
          {availableSubStatuses?.map((subStatus, index) => (
            <MenuItem
              onClick={() => handleSubStatusSelect(subStatus)}
              sx={{
                maxWidth: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Capsule
                key={index}
                textColor="text-[#272523]"
                capsuleColor="bg-[#E8E8E8]"
                text={subStatus?.name}
              />
            </MenuItem>
          ))}
        </Box>
        <div className="border-t border-gray-300 my-2" />
        <MenuItem onClick={handleEditLabelsClick}>
          <div
            className="flex items-start group"
            style={{
              cursor: "pointer",
            }}
          >
            <span className="pr-1 text-[#939291] group-hover:text-black">
              <BorderColorIcon
                sx={{
                  fontSize: "1rem",
                  color: "inherit",
                }}
              />
            </span>
            <span className="text-[#939291] group-hover:text-black">
              Edit Labels
            </span>
          </div>
        </MenuItem>
      </Menu>

      {isStatusModalOpen && (
        <SubStatusModal
          open={handleOpen}
          onClose={handleClose}
          selectedSubStatuses={selectedSubStatuses}
        />
      )}

      {isEditingLabels && <SubStatusModal onClose={handleClose} open={handleOpen} />}
    </div>
  );
};

export default SubStatuses;
