import React from "react";
import { Typography } from "@mui/material";
import DropdownButton from "../Button/DropdownButton";
import theme from "../../theme";
import { CrossIcon } from "../../common/icons";
import { OrderStatus, OrderStatusMap } from "../../constants/OrderStatus";

const MoveOrderModal = ({
  handleNotification,
  handleClose,
  openTrackNumberModal,
  destinationName,
  handleBack,
  order,
  onCancel
}) => {
  const handleClick = (value) => {
    handleNotification(value);
    const legs = shippingLegs();
    if (
      (destinationName === OrderStatus.SHIPPING && !legs.includes("leg_2")) ||
      ([OrderStatus.REPAIRED].includes(destinationName) &&
        order.placement_type === "online" &&
        !legs.includes("leg_3"))
    ) {
      openTrackNumberModal();
    }
    handleClose?.();
  };

  const shippingLegs = () => {
    return order?.shipping_labels.map((item) => item.shipping_leg);
  };

  return (
    <div
      style={{
        width: "calc(100% - 24px)",
        "@media (min-width: 768px)": {
          width: "auto",
        },
      }}
      className="absolute top-1/2 left-1/2 max-w-[535px] md:p-4 p-7 bg-white shadow-lg overflow-x-auto transform -translate-x-1/2 -translate-y-1/2 rounded-lg"
    >
      <div className="flex flex-col md:gap-3 gap-7">
        <div className="flex flex-row justify-between">
          <Typography style={theme.typography.title}>Move Order</Typography>
          <CrossIcon
            className="md:block hidden"
            onClick={() => {
              handleBack?.();
              onCancel?.();
            }}
          />
        </div>
        <Typography style={theme.typography.body}>
          Are you sure you want to move the order to{" "}
          <span className="font-bold">{OrderStatusMap[destinationName]}</span>?
          This action will update the status of the order and send an update to
          the customer.
        </Typography>
        <div className="flex md:flex-row flex-col-reverse justify-end gap-3 items-center">
          <button
            className="rounded-md max-h-[50px] border border-solid border-dark-25 bg-white flex md:w-[160px] self-stretch py-4 px-2 justify-center items-center"
            onClick={() => {
              handleBack?.();
              onCancel?.();
            }}
          >
            Cancel
          </button>
          <DropdownButton
            button={{
              text: "Move",
              returnedValue: true,
            }}
            menuItems={[
              {
                text: "Move",
                returnedValue: true,
              },
              {
                text: "Move without notifications",
                returnedValue: false,
              },
            ]}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};
export default MoveOrderModal;
