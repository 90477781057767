import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { getDaysInMonth, getOrdersForMonth, sumNetTotalForEachDay } from "../../../utils/dashboard";

const DefaultLineChart = ({ orders, monthIndex, year }) => {
  const [onlineSalesData, setOnlineSalesData] = useState([]);
  const [inStoreSalesData, setInStoreSalesData] = useState([]);

  const daysCount = getDaysInMonth(year, monthIndex);

  const xTicks = ["", ...Array.from({ length: daysCount }, (_, i) => i + 1)];

  const data = {
    labels: xTicks,
    datasets: [
      {
        label: "In Store",
        data: inStoreSalesData,
        fill: false,
        borderColor: "#5A8A6E",
        tension: 0.1,
        pointRadius: inStoreSalesData.map((value) => (value === 0 ? 0 : 3)),
        pointHoverRadius: inStoreSalesData.map((value) =>
          value === 0 ? 0 : 5
        ),
      },
      {
        label: "Online",
        data: onlineSalesData,
        fill: false,
        borderColor: "#D8EDE0",
        tension: 0.1,
        pointRadius: onlineSalesData.map((value) => (value === 0 ? 0 : 5)),
        pointHoverRadius: onlineSalesData.map((value) => (value === 0 ? 0 : 7)),
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: true,
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          display: true,
        },
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    if (orders.length > 0) {
      const monthlyOrder = getOrdersForMonth(orders, monthIndex + 1, year);
      const online = monthlyOrder.filter(
        (order) => order.placement_type === "online"
      );
      const inStore = monthlyOrder.filter(
        (order) => order.placement_type === "in_store"
      );

      setOnlineSalesData(sumNetTotalForEachDay(online, monthIndex));
      setInStoreSalesData(sumNetTotalForEachDay(inStore, monthIndex));
    }else {
      setOnlineSalesData(Array(daysCount).fill(0));
      setInStoreSalesData(Array(daysCount).fill(0));
    }
  }, [orders, monthIndex]);

  return (
    <>
      <Line data={data} options={options} />
      <div className="bg-white p-2 flex justify-center items-center rounded-b-xl">
        <div className="flex justify-center items-center gap-2">
          <div className="w-3 h-3 bg-[#5A8A6E] rounded-full"></div>
          <p className="text-sm text-[#5A8A6E]">In Store</p>
        </div>
        <div className="flex justify-center items-center gap-2 ml-4">
          <div className="w-3 h-3 bg-[#D8EDE0] rounded-full"></div>
          <p className="text-sm text-[#D8EDE0]">Online</p>
        </div>
      </div>
    </>
  );
};

export default DefaultLineChart;
