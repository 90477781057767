import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import InputFileUpload from "../../Button/FileUpload";
import CustomTable from "../../Table";

import { Plus } from "../../../common/icons";
import { getRequest, deleteRequest } from "../../../api"; // Import deleteRequest
import { POS, POS_CREATE_PRODUCT } from "../../../constants/FrontendRoutes";
import { PRODUCT_STATUS } from "../../../constants";
import { addToast } from "../../../redux/toastSlice";
import { maskUSD } from "../../../utils";
import { selectCurrentStore } from "../../../redux/storeSlice";
import ActionMenu from "../../Button/ActionMenu";

const columns = [
  {
    field: "product_id",
    label: "ID",
    sortable: true,
    width: "100px",
  },
  {
    field: "name",
    label: "Item Name",
    sortable: true,
    searchable: true,
    width: "200px",
  },
  {
    field: "brand",
    label: "Brand",
    sortable: true,
    searchable: true,
    width: "150px",
  },
  {
    field: "status",
    label: "Status",
    sortable: true,
    width: "150px",
  },
  {
    field: "category",
    label: "Category",
    sortable: true,
    width: "200px",
  },
  {
    field: "price",
    label: "Price",
    sortable: true,
    width: "100px",
  },
  {
    field: "actions",
    label: "",
    width: "10px",
    component: ActionMenu,
  },
];

const transformData = (data, handleEdit = () => {}, handleRemove = () => {}) => {
  return data?.map((item) => ({
    id: item.id,
    name: item.name,
    product_id: item.product_id,
    brand: item.brand || "",
    status: PRODUCT_STATUS[item.status],
    category: item.category,
    price: maskUSD(item.price),
    actionsProps: {
      listOfActions: [
        { label: "Edit", handler: handleEdit },
        { label: "Remove", handler: handleRemove },
      ],
    },
  }));
};

function ActionItems({ onCSV }) {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {/* <InputFileUpload onChange={onCSV} /> */}
      <Button
        variant="containedPrimary"
        onClick={() => navigate(POS_CREATE_PRODUCT)}
        startIcon={<Plus />}
      >
        Add Product
      </Button>
    </React.Fragment>
  );
}

const Product = ({ onCSVUpload }) => {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentStore = useSelector(selectCurrentStore);

  useEffect(() => {
    if (currentStore) {
      handleGetProducts()
        .then((products) => {
          setRows(transformData(products, handleEdit, handleRemove));
        })
        .catch((error) => {
          dispatch(addToast(error.message));
        });
    }
  }, [currentStore]);

  const handleGetProducts = async () => {
    return await getRequest(
      `${process.env.REACT_APP_BACKEND_URL}/api/stores/${currentStore?.store_id}/products`
    );
  };

  const handleEdit = (id) => {
    navigate(`${POS}/edit/product/${id}`);
  };

  const handleRemove = async (id) => {
    try {
      await deleteRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stores/${currentStore?.store_id}/products/${id}`
      );
      setRows((prevRows) =>
        prevRows.filter((row) => row.id !== id)
      );
      dispatch(addToast("Product removed successfully."));
    } catch (error) {
      dispatch(addToast(error.message));
    }
  };

  return (
    <div className="flex flex-col justify-between">
      <CustomTable
        columns={columns}
        rows={rows}
        sortable
        searchable
        actions={<ActionItems onCSV={onCSVUpload} />}
      />
    </div>
  );
};

export default Product;
