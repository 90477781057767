import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { IconButton, TextField, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputMask from "react-input-mask";

const INITIAL_STATE = {
  name: "",
  email: "",
  phone_number: "",
};
export default function ContactPersonModal({ open, close, contact, onSubmit, editable }) {
  const [formState, setFormState] = useState();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formState);
    handleClose();
  };

  const handleClose = () => {
    close();
    setFormState(INITIAL_STATE);
  };

  useEffect(() => {
    if (contact) {
      setFormState({
        id: contact?.id,
        email: contact?.email || "",
        name: contact?.name || "",
        phone_number: contact?.phone_number || "",
      });
    }
  }, [contact]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: "20px",
          right: "8px",
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <div className="flex flex-col gap-5 w-full bg-white justify-between">
        <div className="flex flex-col gap-2 mx-8 mt-8">
          <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
            {editable ? "Edit Contact Person" : "Add New Contact Person"}
          </p>
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            {editable
              ? "Update the contact details below to make the necessary changes."
              : "Add a new contact by filling in the required details below."}
          </p>
        </div>
        <form className="flex flex-col gap-2 mx-8 mb-8" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-1">
            <label className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              Contact email
            </label>
            <TextField
              name="email"
              placeholder="john.doe@example.com"
              variant="outlined"
              value={formState?.email}
              onChange={handleOnChange}
              required
            />
          </div>

          <div className="flex flex-col md:flex-row gap-4 md:gap-1">
            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Name
              </div>
              <TextField
                name="name"
                placeholder="John Doe"
                variant="outlined"
                value={formState?.name}
                onChange={handleOnChange}
                required
              />
            </div>

            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Phone number
              </div>
              <InputMask
                mask="(999) 999-9999"
                value={formState?.phone_number}
                onChange={handleOnChange}
              >
                {() => (
                  <TextField
                    id="phone_number"
                    name="phone_number"
                    type="tel"
                    placeholder="(123) 456-7890"
                    className="flex-1"
                    variant="outlined"
                  />
                )}
              </InputMask>
            </div>
          </div>

          <div className="flex flex-col gap-2 md:flex-row w-full mt-5">
            <Button variant="outlinedSecondary" fullWidth onClick={handleClose}>
              Back
            </Button>
            <Button variant="containedPrimary" fullWidth type="submit">
              {editable ? "Update" : "Save"}
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
}
