import React from "react";
import { Typography } from "@mui/material";
import FieldItem from "../Form/FieldItem";
import TextInput from "../Form/Field/TextInput";
import InputMask from "react-input-mask";
import AutoCompleteInput from "../Form/Field/AutoComplete";
import { COUNTRIES, STATES_PER_COUNTRY } from "../../constants";

const AddressSection = ({ section, address, handleInputChange, required = false }) => {
  const countryValue = address?.country?.value;
  const fields = [
    { label: "Street address", name: "line", placeholder: "123 Main St" },
    {
      label: "Country",
      name: "country",
      type: "select",
      options: COUNTRIES,
      placeholder: "Select country",
    },
    {
      label: "State",
      name: "state",
      type: "select",
      options: STATES_PER_COUNTRY?.[countryValue] || [],
      placeholder: "Select state",
    },
    { label: "City", name: "city", placeholder: "New York" },
    { label: "Zipcode", name: "zipcode", placeholder: "12345" },
  ];

  return fields.map((field) => (
    <FieldItem key={`${field.name}-${section}`}>
      <Typography width="30%" variant="body">
        {field.label}
      </Typography>
      {field.type === "select" ? (
        <AutoCompleteInput
          required={required}
          options={field.options}
          value={address[field.name]}
          label={field.placeholder}
          onChange={(_e, value) =>
            handleInputChange(section, field.name, value)
          }
        />
      ) : field.name === "zipcode" ? (
        <InputMask
          mask={countryValue === "CA" ? "a9a 9a9" : "99999"}
          value={address[field.name]}
          onChange={(e) =>
            handleInputChange(section, field.name, e.target.value)
          }
        >
          {() => (
            <TextInput
              id={field.name}
              name={field.name}
              type="tel"
              placeholder={field.placeholder}
              variant="outlined"
              value={address[field.name]}
              required
            />
          )}
        </InputMask>
      ) : (
        <TextInput
          value={address[field.name]}
          onChange={(e) =>
            handleInputChange(section, field.name, e.target.value)
          }
          placeholder={field.placeholder}
          required={required}
        />
      )}
    </FieldItem>
  ));
};

export default AddressSection;
