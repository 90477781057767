import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postRequest } from "../../api";
import { selectCurrentUser } from "../../redux/authSlice";
import { setLoading } from "../../redux/loadingSlice";
import { addToast } from "../../redux/toastSlice";
import { cleanAssignmentsForPosting, generateAssignmentsDifferences, getUserIds, mapAssignees, mapUsers, updateAssignments } from "../../utils/assignees";
import MultiSelectDropdown from "../Form/MultiSelectDropdown";
import { groupServicesByType } from "../../utils";
import { isEmpty } from "lodash";
import { selectCurrentStore } from "../../redux/storeSlice";

const AssignOrderModal = ({
  id,
  show,
  users,
  orderLineItemsData,
  handleClose,
  fetchOrder,
}) => {
  const [openItems, setOpenItems] = useState({});
  const [assignments, setAssignments] = useState([]);
  const [previousAssignments, setPreviousAssignments] = useState([]);

  const [orderLineItems, setOrderLineItems] = useState([]);
  const [options, setOptions] = useState([]);
  const [allOrderAssignees, setAllOrderAssignees] = useState([]);

  const userData = useSelector(selectCurrentUser);
  const currentStore = useSelector(selectCurrentStore);
  const currentUserId = userData?.id;
  const storeId = currentStore?.store_id;

  const dispatch = useDispatch();

  const AssignSelect = ({ assignees, orderLineItemId, type, assignableId, style }) => {
    const data =
      assignees ||
      getUserIds(
        assignments?.find((a) => a?.order_line_item_id === orderLineItemId),
        type,
        assignableId
      );

    const [defaultAssignees, setDefaultAssignees] = useState(data);
    const [selectedUsers, setSelectedUsers] = useState(data);

    const handleButtonClick = () => {
      setDefaultAssignees(selectedUsers);
      setAssignments((prevAssignments) =>
        updateAssignments(
          prevAssignments,
          orderLineItemId,
          type,
          assignableId,
          selectedUsers
        )
      );
    };

    const fetchSelectedOnOpen = () => {
      setSelectedUsers(defaultAssignees);
    };

    return (
      <MultiSelectDropdown
        style={style}
        options={options}
        selectedValues={selectedUsers}
        onSelectionChange={setSelectedUsers}
        enableSearch={false}
        placeholder="Select employees"
        allItemsLabel="All employees"
        itemLabel={(item) => item.label}
        displayButton={true}
        handleButtonClick={handleButtonClick}
        fetchSelectedOnOpen={fetchSelectedOnOpen}
      />
    );
  };

  const toggleCollapse = (unique_identifier) => {
    setOpenItems((prev) => ({
      ...prev,
      [unique_identifier]: !prev[unique_identifier],
    }));
  };

  const setOrderAssignees = () => {
    let allUserIds = [];
    assignments.forEach((asg) => {
      allUserIds = allUserIds.concat(asg.user_ids);
    });
    const uniqueUserIds = Array.from(new Set(allUserIds));
    setAllOrderAssignees(uniqueUserIds);
  };

  const setAssignmentsData = () => {
    const mappedOrderAssignees = mapAssignees(orderLineItems);
    setAssignments(mappedOrderAssignees);
    setPreviousAssignments(mappedOrderAssignees);
  };

  useEffect(() => {
    setAssignmentsData();
  }, [orderLineItems]);

  useEffect(() => {
    setOrderAssignees();
  }, [assignments]);

  useEffect(() => {
    setOrderLineItems(orderLineItemsData);
  }, [orderLineItemsData]);

  useEffect(() => {
    if (!isEmpty(users)) {
      const mappedUsers = mapUsers(users, currentUserId);
      setOptions(mappedUsers);
    }
  }, [users]);

  const handleAssignData = async () => {
    dispatch(setLoading(true));

    try {
      const current = cleanAssignmentsForPosting(assignments);
      const previous = cleanAssignmentsForPosting(previousAssignments);
      const difference = generateAssignmentsDifferences(previous, current);

      if (isEmpty(difference)) {
        dispatch(addToast("Please select new assignees."));
        return;
      }

      const response = await postRequest(
        `stores/${storeId}/orders/${id}/order_assignees/sync`,
        { order_assignees: difference }
      );

      if (response?.data?.success) {
        dispatch(addToast(response.data.message));
        fetchOrder();
      } else {
        dispatch(addToast("Something went wrong!"));
      }

      handleCloseModal();
    } catch (error) {
      dispatch(addToast("Something went wrong!"));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const renderLineItems = useMemo(() => {
    return orderLineItems?.map((row, index) => {
      const {
        details: { name, category, categoryType, services, options, id },
        line_item_type,
        unique_identifier,
      } = row;
      const isProduct = line_item_type === "product";
      const isOpen = openItems[unique_identifier] || false;
      const rowAssignees =
        assignments?.find((a) => a.order_line_item_id === row.id)?.user_ids ||
        [];

    const organizeServicesData = (value) => groupServicesByType(value);

      return (
        <React.Fragment key={unique_identifier}>
          <TableRow style={{ background: isOpen ? "#FAFAFA" : "" }}>
            <TableCell
              component="th"
              scope="row"
              sx={{ verticalAlign: "top", border: "none" }}
            >
              <div className="flex items-center gap-2">
                {!isProduct && (
                  <IconButton
                    onClick={() => toggleCollapse(unique_identifier)}
                    size="small"
                  >
                    {isOpen ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                )}
                <div className="flex flex-col">
                  <span className="font-questrial text-[14px] text-gray-500">
                    {!isProduct ? category : name}
                  </span>
                  {!isProduct && (
                    <span className="font-questrial text-[14px]">
                      {categoryType}
                    </span>
                  )}
                </div>
              </div>
            </TableCell>

            <TableCell
              sx={{ padding: "16px", textAlign: "right", border: "none" }}
            >
              <AssignSelect
                orderLineItemId={row?.id}
                assignees={rowAssignees}
                type="order_line_item"
                assignableId={id}
              />
            </TableCell>
          </TableRow>

          {!isProduct && (
            <TableRow style={{ background: isOpen ? "#FAFAFA" : "" }}>
              <TableCell sx={{ paddingLeft: 5, border: "none" }}>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <Box sx={{ paddingLeft: "5px" }}>
                    {Object.entries(organizeServicesData(services)).map(
                      ([type, services]) => (
                        <div key={type}>
                          <p className="font-questrial text-[16px] text-[#939291]">
                            {type}
                          </p>
                          {services.map((service, idx) => (
                            <div
                              key={idx}
                              className="font-questrial text-base mb-[24px]"
                            >
                              {service?.name}
                            </div>
                          ))}
                        </div>
                      )
                    )}
                  </Box>
                </Collapse>
              </TableCell>

              <TableCell
                sx={{ padding: "16px", textAlign: "right", border: "none" }}
                style={{ display: isOpen ? "table-cell" : "none" }}
              >
                {services.map(({ id }) => {
                  return (
                    <AssignSelect
                      style={{
                        marginBottom: "20px",
                      }}
                      orderLineItemId={row?.id}
                      type="service"
                      assignableId={id}
                    />
                  );
                })}
              </TableCell>
            </TableRow>
          )}

          {!isProduct && options?.length > 0 && (
            <TableRow style={{ background: isOpen ? "#FAFAFA" : "" }}>
              <TableCell sx={{ paddingLeft: 5, border: "none" }}>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <Box>
                    <div className="flex flex-col gap-2">
                      <p className="font-questrial text-[16px] text-[#939291]">
                        Option(s)
                      </p>
                      {options.map(({ name }, idx) => (
                        <p key={idx} className="font-questrial text-base mb-2">
                          {name}
                        </p>
                      ))}
                    </div>
                  </Box>
                </Collapse>
              </TableCell>

              <TableCell
                sx={{  textAlign: "right", border: "none" }}
                style={{ display: isOpen ? "table-cell" : "none" }}
              >
                {options.map(({ id }) => (
                  <AssignSelect
                    style={{
                      marginTop: "10px",
                      // marginBottom: "10px",
                    }}
                    orderLineItemId={row?.id}
                    type="option"
                    assignableId={id}
                  />
                ))}
              </TableCell>
            </TableRow>
          )}
          {index < orderLineItems.length - 1 && (
            <TableRow>
              <TableCell colSpan={2} sx={{ padding: "0", border: "none" }}>
                <Divider />
              </TableCell>
            </TableRow>
          )}
        </React.Fragment>
      );
    });
  }, [orderLineItems, openItems, options, assignments]);

  const handleCloseModal = () => {
    setOpenItems({});
    handleClose();
  };

  return (
    <Dialog
      open={show}
      onClose={handleCloseModal}
      sx={{
        "& .MuiDialog-paper": {
          width: 599,
          maxHeight: 599,
          border: "1px solid #ddd",
          borderRadius: "8px",
          padding: "16px",
          margin: "0 auto",
        },
      }}
      fullWidth
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontFamily: "Montserrat, sans-serif",
          fontSize: "24px",
          fontWeight: 700,
          textAlign: "left",
        }}
      >
        Assign Order to Employee
        <IconButton onClick={handleClose} sx={{ color: "grey.600" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          padding: "20px",
          maxHeight: "400px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        <p
          style={{
            fontFamily: "Questrial, sans-serif",
            fontSize: "16px",
            marginBottom: "16px",
          }}
        >
          You can now assign employees to an order, an item, or a repair.
        </p>

        <Box
          sx={{
            border: "1px solid #ddd",
            borderRadius: "4px",
            margin: "0 auto",
            maxWidth: "800px",
          }}
        >
          <Paper elevation={0}>
            <Table aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontFamily: "Questrial, sans-serif",
                      fontSize: "14px",
                      fontWeight: 400,
                      width: 250,
                    }}
                  >
                    Item
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Questrial, sans-serif",
                      fontSize: "14px",
                      fontWeight: 400,
                      width: "auto",
                    }}
                  >
                    Assignee
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      fontFamily: "Questrial, sans-serif",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    Order # {id}
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    {allOrderAssignees.length > 0 ? (
                      <div className="overflow-x-auto no-scrollbar whitespace-nowrap sm:max-w-[250px] max-w-[170px] h-[30px]">
                        {allOrderAssignees.map((id, index) => {
                          const user = options.find((user) => user.id === id);
                          return (
                            <span key={id || index}>
                              {user?.label}
                              {user && index < allOrderAssignees.length - 1 && ", "}
                            </span>
                          );
                        })}
                      </div>
                    ) : (
                      <AssignSelect
                        type="order"
                        assignees={allOrderAssignees}
                      />
                    )}
                  </TableCell>
                </TableRow>
                {renderLineItems}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box
          sx={{ display: "flex", gap: "8px", width: "328px", height: "42px" }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{
              width: "160px",
              height: "42px",
              borderRadius: "30",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleAssignData}
            sx={{
              width: "160px",
              height: "42px",
              borderRadius: "30",
              backgroundColor: "#4C8C4A",
            }}
            disabled={isEmpty(options)}
          >
            Assign
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AssignOrderModal;
