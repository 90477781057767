import React from "react";
import Tooltip from "@mui/material/Tooltip";

const FieldGroup = ({ label, required = false, children }) => {
  return (
    <div className="flex flex-col gap-8 my-5">
      {label && (
        <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
          {label}
          {required && (
            <Tooltip title="Required" arrow placement="right">
              <span className="text-red-500 text-lg font-normal cursor-pointer">
                {" "}
                *
              </span>
            </Tooltip>
          )}
        </p>
      )}
      <div className="flex flex-col gap-5">{children}</div>
    </div>
  );
};

export default FieldGroup;
