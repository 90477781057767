export const retrieveTokenFromLocalStorage = () =>
  localStorage.getItem("token");
export const addTokenToLocalStorage = (token) =>
  localStorage.setItem("token", token);
export const destroyTokenFromLocalStorage = () =>
  localStorage.removeItem("token");

export const retrieveUserIdFromLocalStorage = () => localStorage.getItem("id");
export const addUserIdToLocalStorage = (id) => localStorage.setItem("id", id);
export const destroyUserIdFromLocalStorage = () =>
  localStorage.removeItem("id");

export const retrieveStoreIdFromLocalStorage = () =>
  localStorage.getItem("storeId");
export const addStoreIdToLocalStorage = (id) =>
  localStorage.setItem("storeId", id);
export const destroyStoreIdFromLocalStorage = () =>
  localStorage.removeItem("storeId");

export const retrieveDraftMessageFromLocalStorage = () =>
  JSON.parse(localStorage.getItem("draftMessage"));
export const addDraftMessageToLocalStorage = (message) =>
  localStorage.setItem("draftMessage", message);
export const destroyDraftMessageFromLocalStorage = () =>
  localStorage.removeItem("draftMessage");

export const setDataToLocalStorage = (token, id, storeId) => {
  addTokenToLocalStorage(token);
  addUserIdToLocalStorage(id);
  addStoreIdToLocalStorage(storeId);
};

export const destroyDataFromLocalStorage = () => {
  destroyTokenFromLocalStorage();
  destroyUserIdFromLocalStorage();
  destroyStoreIdFromLocalStorage();
};
