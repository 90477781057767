import React, { useState, useEffect } from "react";
import { TextField, Button, Box, Typography, IconButton, Divider } from "@mui/material";
import InputMask from "react-input-mask";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { POS_NEW_ORDER_STEPS } from "../../../constants";
import { deleteRequest, getRequest, postRequest, updateRequest } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentStore } from "../../../redux/storeSlice";
import { validateEmail } from "../../../utils";
import { addToast } from "../../../redux/toastSlice";
import { LeftArrowIcon } from "../../../common/icons";
import CartBadge from "./CartBadge";
import { selectCurrentCart, updateCart } from "../../../redux/cartSlice";
import { updateCurrentStep } from "../../../redux/currentStepSlice";

const SelectCustomer = () => {
  const [inputValue, setInputValue] = useState("");
  const [inputType, setInputType] = useState("phone");
  const [isFormValid, setIsFormValid] = useState(false);

  const dispatch = useDispatch();
  const currentStore = useSelector(selectCurrentStore);
  const cart = useSelector(selectCurrentCart);
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1024px)" });

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleCustomerTypeSwitch = (type) => {
    setInputValue("");
    setInputType(type);
  };

  const handleSelectCorporate = () => {
    dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.SELECT_CORPORATE))
  }

  const handleBack = async () => {
    if (cart?.payment_id) {
      const res = await deleteRequest(
        `stores/${currentStore?.store_id}/payments/${cart?.payment_id}`,
      );
      if (res) dispatch(updateCart({ ...cart, payment_id: null }));
    }

    dispatch(updateCart({ ...cart, type: null }));
    dispatch(
      updateCurrentStep(
        isDesktopScreen
          ? POS_NEW_ORDER_STEPS.ADD_ITEM
          : POS_NEW_ORDER_STEPS.ORDER_CART
      )
    );
  };

  const handleSubmit = async () => {
    try {
      const { data } = await getRequest(
        `stores/${currentStore?.store_id}/users`,
        {
          global: true,
          filters: {
            [inputType == "phone" ? "phone_number" : inputType]: inputValue,
          },
        }
      );

      if (data?.length > 0) {
        dispatch(updateCart({ ...cart, new_customer: false, customer: data[0] }));
        dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.ATTACH_CUSTOMER));
      } else {
        dispatch(
          updateCart({
            ...cart,
            lookupPhone: inputType === "phone" ? inputValue : cart.lookupPhone,
            lookupEmail: inputType === "email" ? inputValue : cart.lookupEmail,
          }),
        );
        dispatch(addToast("Couldn't find User"));
        dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.CREATE_CUSTOMER));
      }
    } catch (error) {
      dispatch(addToast(error || "Error."));
    }
  };

  useEffect(() => {
    if (inputType === "phone") {
      setIsFormValid(inputValue.replace(/[_()-\s]/g, "").length === 10);
    } else if (inputType === "email") {
      setIsFormValid(validateEmail(inputValue));
    }
  }, [inputValue, inputType]);

  return (
    <Box className="relative flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <MediaQuery minWidth={1024}>
        <Box
          className="absolute flex items-center gap-1 top-[2.5%] left-[2.5%] cursor-pointer"
          onClick={handleBack}
        >
          <LeftArrowIcon width={18} height={19} />
          <span>Back</span>
        </Box>
      </MediaQuery>
      <Box className="flex h-full sm:h-auto flex-col justify-between bg-[#F8F8F8] lg:bg-white w-full sm:max-w-[70%] xl:max-w-[50%] sm:m-auto">
        <div className="flex flex-col">
          <CartBadge />
          <p className="text-lg sm:text-3xl font-semibold sm:font-bold font-['Montserrat'] leading-[25.20px]">
            Customer Lookup{" "}
          </p>
          <p className="mt-4 text-base sm:lg font-normal md:font-semibold font-['Questrial'] leading-snug">
            Enter phone number or email address to attach a customer to an
            order:
          </p>
          <Box className="flex flex-row w-full items-center gap-3 my-4">
            <Button
              variant="selectablePrimary"
              className="flex-1"
              isSelected={inputType === "phone"}
              onClick={() => handleCustomerTypeSwitch("phone")}
            >
              Phone
            </Button>
            <Button
              variant="selectablePrimary"
              className="flex-1"
              isSelected={inputType === "email"}
              onClick={() => handleCustomerTypeSwitch("email")}
            >
              Email
            </Button>
          </Box>
          {inputType === "phone" ? (
            <>
              <Typography variant="body">Phone Number</Typography>
              <InputMask
                mask="(999) 999-9999"
                value={inputValue}
                onChange={handleInputChange}
              >
                {() => (
                  <TextField
                    type="tel"
                    placeholder="(123) 456-7890"
                    className="flex-1"
                    sx={{ mt: 0.5, mb: 4 }}
                  />
                )}
              </InputMask>
            </>
          ) : (
            <>
              <Typography variant="body">Email</Typography>
              <TextField
                type="email"
                placeholder="Example@gmail.com"
                className="flex-1"
                sx={{ mt: 0.5, mb: 4 }}
                value={inputValue}
                onChange={handleInputChange}
              />
            </>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <div className="text-right mb-4">
            <span className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
              I’m a{" "}
            </span>
            <span
              onClick={handleSelectCorporate}
              className="text-[#939291] text-base font-normal font-['Questrial'] underline leading-snug hover:cursor-pointer hover:text-[#4c8c4a]"
            >
              corporate customer
            </span>
          </div>
          <Button
            variant="containedPrimary"
            disabled={!isFormValid}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
          <MediaQuery maxWidth={1023}>
            <Button variant="outlinedSecondary" onClick={handleBack}>
              Back
            </Button>
          </MediaQuery>
        </div>
      </Box>
    </Box>
  );
};

export default SelectCustomer;
