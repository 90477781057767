import React from "react";
import { DEFAULT_STYLES, STATUS_STYLES } from "../../constants/OrderStatus";

const Chip = ({ status }) => {
  const mergedStyles = {
    ...DEFAULT_STYLES,
    ...(STATUS_STYLES[status] || {}),
  };

  const { bgColor, textColor, text } = mergedStyles;

  return (
    <div
      className={`px-2 py-1 bg-[${bgColor}] rounded-2xl justify-center items-center inline-flex w-fit`}
    >
      <div
        className={`text-center text-[${textColor}] text-xs font-normal font-['Questrial'] leading-none`}
      >
        {text}
      </div>
    </div>
  );
};

export default Chip;
