import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Introduction from "../components/Onboarding/Introduction";
import SetupCompany from "../components/Onboarding/SetupCompany";
import SetupStore from "../components/Onboarding/SetupStore";
import SetupTeam from "../components/Onboarding/SetupTeam";
import Success from "../components/Onboarding/Success";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SetupShipStation from "../components/Onboarding/SetupShipStation";
import { fetchOrganizationData } from "../redux/organizationAction";
import { selectCurrentStore } from "../redux/storeSlice";

const Onboarding = () => {
  const [maxWidth, setMaxWidth] = useState("sm");
  const [activeStep, setActiveStep] = useState(0);
  const currentStore = useSelector(selectCurrentStore);
  const dispatch = useDispatch();

  const handleNext = () => {
    setMaxWidth("sm");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setMaxWidth("sm");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (currentStore?.store?.organization_id) {
      dispatch(fetchOrganizationData({ organizationId: currentStore?.store?.organization_id }));
    }
  }, []);

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <Introduction handleNext={handleNext} activeStep={activeStep} />;
      case 1:
        return (
          <SetupStore
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
          />
        );
      case 2:
        return (
        <SetupCompany
          handleBack={handleBack}
          handleNext={handleNext}
          activeStep={activeStep}
        />
        );
      case 3:
        return (
          <SetupTeam
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
          />
        );
      case 4:
        return (
          <SetupShipStation
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
            setMaxWidth={setMaxWidth}
          />
        );
      case 5:
        return (
          <Success
            handleBack={handleBack}
            handleNext={handleNext}
            activeStep={activeStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#272523 ",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        open={true}
        sx={{ borderRadius: "50px" }}
      >
        {renderStepContent()}
      </Dialog>
    </Box>
  );
};

export default Onboarding;
