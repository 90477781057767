import React, { useState, useEffect } from "react";
import { Button, Box, Typography, Divider, IconButton } from "@mui/material";
import { ORDER_TYPE_ENUM, PAYMENT_METHODS, PAYMENT_STATUS_ENUM, POS_NEW_ORDER_STEPS } from "../../../constants";
import { calculateTotal } from "../../../utils";
import CustomDatePicker from "../../Form/Field/DatePicker";
import { Add } from "@mui/icons-material";
import { LeftArrowIcon } from "../../../common/icons";
import { deleteRequest, updateRequest } from "../../../api";
import { useDispatch } from "react-redux";
import { addToast } from "../../../redux/toastSlice";
import { useSelector } from "react-redux";
import { selectCurrentStore } from '../../../redux/storeSlice';
import { selectCurrentOrganization } from '../../../redux/organizationSlice';
import CartBadge from "./CartBadge";
import { useMediaQuery } from "react-responsive";
import { selectCurrentCart, updateCart } from "../../../redux/cartSlice";
import { updateCurrentStep } from "../../../redux/currentStepSlice";
import { posCreateOrder } from "../../../api/pos";
import { update } from "lodash";

const PaymentMethod = () => {
  const [enableStripeCharge, setEnableStripeCharge] = useState(false);
  const [publishableKey, setPublishableKey] = useState(false);

  const dispatch = useDispatch();
  const cart = useSelector(selectCurrentCart);
  const currentStore = useSelector(selectCurrentStore);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const isTabletScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1024px)" });

  const handleBack = async () => {
    try {
      const response = await deleteRequest(
        `stores/${currentStore?.store_id}/payments/${cart?.payment_id}`,
      );

      if (response) {
        dispatch(updateCart({ ...cart, type: null, paymentMethod: null, payment_id: null }));
        dispatch(
          updateCurrentStep(
            isDesktopScreen
              ? POS_NEW_ORDER_STEPS.ADD_ITEM
              : POS_NEW_ORDER_STEPS.ORDER_CART
          )
        );
      }
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  const handleEstimatedDateChange = (e) => {
    dispatch(updateCart({ ...cart, estimateCompleteDate: e }));
  };

  const handleCreateOrderBillLater = async () => {
    try {
      const { status } = await updateRequest(
        `stores/${currentStore?.store_id}/payments/${cart?.payment_id}`,
        {
          payment: { status: PAYMENT_STATUS_ENUM.bill_later },
        }
      );
      if (status === 200)
        posCreateOrder(ORDER_TYPE_ENUM.in_store, cart, currentStore, dispatch, true);
    } catch (error) {
      dispatch(addToast(error));
    }
  }

  useEffect(() => {
    if (currentOrganization) {
      setEnableStripeCharge(currentOrganization?.connect_acc_charges_enabled);
      setPublishableKey(currentOrganization?.connect_acc_publishable_key);
    }
  }, [currentOrganization]);

  return (
    <Box className="flex flex-col w-full bg-[#F8F8F8] lg:bg-white justify-between">
      <Box className="flex h-full sm:h-auto flex-col justify-between bg-[#F8F8F8] lg:bg-white w-full sm:max-w-[70%] xl:max-w-[55%] sm:m-auto">
        <div>
          <CartBadge />

          <div className="flex flex-col gap-5">
            <p className="text-xl font-semibold font-['Montserrat'] leading-7 lg:hidden">
              Checkout
            </p>
            <Box className="flex flex-row justify-between w-full items-center gap-6">
              <span className="text-base xl:text-2xl xl:font-bold font-normal font-['Questrial'] leading-snug">
                Total amount
              </span>
              <span className="text-lg xl:text-2xl xl:font-bold font-['Montserrat'] leading-[25.20px]">
                ${calculateTotal(cart).toFixed(2)}
              </span>
            </Box>
            <Box className="flex flex-col md:flex-row md:justify-between md:items-center w-full gap-2 xl:mt-6">
              <p className="text-base xl:text-2xl lg:font-bold font-normal font-['Questrial'] leading-snug">
                Est. Completion date
              </p>
              <CustomDatePicker
                value={cart.estimateCompleteDate}
                textFieldStyles={{ width: isTabletScreen ? "50%" : "100%" }}
                onChange={handleEstimatedDateChange}
              />
            </Box>
          </div>

          <hr className="my-3 lg:my-5" />

          <div className="flex flex-col gap-5">
            <div className="flex justify-between items-center gap-2">
              <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Choose a payment method below
              </p>
              <Button
                size="small"
                disabled={
                  !cart.estimateCompleteDate || !cart.isCorporateCustomer
                }
                onClick={handleCreateOrderBillLater}
                className="justify-end items-center gap-1 flex"
              >
                <span className="text-center text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
                  Or
                </span>
                <span
                  className={`text-center text-base font-normal font-['Questrial'] underline leading-snug ${
                    !cart.estimateCompleteDate || !cart.isCorporateCustomer
                      ? "text-[#939291]"
                      : "text-[#256e22]"
                  }`}
                >
                  charge later
                </span>
              </Button>
            </div>
            <Box className="flex justify-between w-full gap-3">
              <Button
                variant="selectablePrimary"
                className="w-1/2"
                isSelected={cart.paymentMethod == PAYMENT_METHODS.CASH}
                onClick={() =>
                  dispatch(
                    updateCart({ ...cart, paymentMethod: PAYMENT_METHODS.CASH })
                  )
                }
              >
                Cash
              </Button>
              <Button
                variant="selectablePrimary"
                className="w-1/2"
                disabled={!enableStripeCharge}
                isSelected={cart.paymentMethod == "card_present" ? true : false}
                onClick={() =>
                  dispatch(
                    updateCart({ ...cart, paymentMethod: "card_present" })
                  )
                }
              >
                Debit/Credit
              </Button>
            </Box>
            <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              *To continue processing the payment, please click [Confirm] below
              to swipe the card. You can also manually add card info.
            </p>
            <div className="flex flex-row justify-end items-center">
              <Button
                variant="textSecondary"
                disabled={
                  !enableStripeCharge ||
                  !publishableKey ||
                  !cart.estimateCompleteDate
                }
                onClick={() => {
                  dispatch(updateCart({ ...cart, paymentMethod: "card" }));
                  dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.CARD_PAYMENT));
                }}
                startIcon={<Add />}
              >
                Manually add card info
              </Button>
            </div>
          </div>
        </div>

        <Box className="flex flex-col justify-between w-full gap-2 mt-8">
          <Button
            variant="containedPrimary"
            className="flex-1"
            disabled={!cart.paymentMethod || !cart.estimateCompleteDate}
            onClick={() => {
              dispatch(
                updateCurrentStep(
                  cart.paymentMethod === PAYMENT_METHODS.CASH
                    ? POS_NEW_ORDER_STEPS.CALCULATE_PAYMENT
                    : POS_NEW_ORDER_STEPS.SCAN_CARD
                )
              );
            }}
          >
            Confirm
          </Button>
          <Button
            variant="outlinedSecondary"
            className="flex-1"
            onClick={handleBack}
          >
            Back
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentMethod;
