import { CheckCircle } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateRequest } from "../../api";
import { selectCurrentUser } from "../../redux/authSlice";
import { addToast } from "../../redux/toastSlice";
import CustomTooltip from "../Tooltip";
import { selectCurrentStore } from "../../redux/storeSlice";
import { USER_ROLES } from "../../constants";

const UsersRow = ({ fetchOrder, users, isLineItemUsers, storeId, collapsible, checkCompletion = true }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const orderId = parseInt(id);

  const currentStore = useSelector(selectCurrentStore);
  const isAdmin = currentStore?.role === USER_ROLES.ADMIN;
  const userData = useSelector(selectCurrentUser)
  const getAllAssignments = (assignments) => {
    const allCompleted = assignments.every(assignment => assignment.is_completed);

    return assignments.map((assignment) => ({
      id: assignment.id,
      is_completed: !allCompleted,
    }));
  };

  const updateTaskStatus = async (isLineItemUsers, user, storeId) => {
    try {
      let assignments = [];

      if (isLineItemUsers) {
        assignments = getAllAssignments(user?.assignments);
      } else {
        assignments = [
          {
            id: user?.orderAssigneeId,
            is_completed: !user?.is_completed,
          },
        ];
      }

      const response = await updateRequest(
        `stores/${storeId}/orders/${orderId}/order_assignees/bulk_update`,
        { assignees: assignments }
      );
      if (response?.data?.success) {
        await fetchOrder();
        dispatch(addToast(response?.data?.message));
      }
    } catch (error) {
      dispatch(addToast("Something went wrong!"));
    }
  };

  const sortedUsers = users.sort((a, b) => a.id - b.id);

  return (
    <div className="flex" onClick={(e) => e.stopPropagation()}>
      {sortedUsers?.map((user, index) => {
        const isTaskCompleted = checkCompletion && user?.is_completed;
        const isAssignedToCurrentUser = user.id === userData?.id;
        const canUpdateTask = isAssignedToCurrentUser || isAdmin;
        const updatedName =  isAssignedToCurrentUser ? `${user?.name} (You)` : user?.name;
        let tooltipContent;
        if (canUpdateTask && collapsible) {
          tooltipContent = (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (canUpdateTask) {
                  updateTaskStatus(isLineItemUsers, user, storeId, orderId);
                }
              }}
            >
              <span>Mark {`${isAssignedToCurrentUser ? 'your' : 'this'}`} task as </span>
              <span
                style={{
                  color: isTaskCompleted ? "#F44336" : "#4CAF50",
                  marginLeft: "4px",
                }}
              >
                {isTaskCompleted ? "Not Done" : "Done"}
              </span>
            </div>
          );
        } else {
          tooltipContent = isTaskCompleted ? (
            <span>Task Completed by {updatedName}</span>
          ) : (
            <span>Assigned to {updatedName}</span>
          );
        }

        return (
          <CustomTooltip key={user.id} title={tooltipContent} arrow>
            <div
              className={`w-6 h-6 rounded-full flex justify-center items-center overflow-hidden mt-[10px] ${
                index !== 0 ? "-ml-2" : ""
              }`}
              style={{
                backgroundColor: user.backgroundColor,
                zIndex: users.length - index,
                cursor: isAssignedToCurrentUser ? "pointer" : "default",
              }}
            >
              {isTaskCompleted ? (
                <CheckCircle
                  style={{
                    fontSize: "16px",
                    color: user.color,
                  }}
                />
              ) : (
                <span
                  className="font-bold text-sm"
                  style={{
                    color: user.color,
                  }}
                >
                  {user?.name?.charAt(0)?.toUpperCase()}
                </span>
              )}
            </div>
          </CustomTooltip>
        );
      })}
    </div>
  );
};

export default UsersRow;