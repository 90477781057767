const assigneesColors = [
  { background: "#B8E9B8", color: "#4C8C4A" },
  { background: "#F8E2C9", color: "#CA8A68" },
  { background: "#BAE0FF", color: "#003EB3" },
  { background: "#FFD6E7", color: "#9E1068" },
  { background: "#FFF1B8", color: "#AD6800" },
  { background: "#EFDBFF", color: "#391085" },
  { background: "#4C8C4A", color: "#CFF7CE" },
  { background: "#CA8A68", color: "#FFE0D0" },
  { background: "#15417E", color: "#B7DCFA" },
  { background: "#75204F", color: "#FAD2E3" },
  { background: "#AA7714", color: "#F8DF8B" },
  { background: "#642AB5", color: "#EBD7FA" },
  { background: "#D9F7BE", color: "#237804" },
  { background: "#B5F5EC", color: "#006D75" },
  { background: "#F4FFB8", color: "#5B8C00" },
  { background: "#FFCCC7", color: "#820014" },
  { background: "#146262", color: "#B2F1E8" },
  { background: "#306317", color: "#D5F2BB" },
  { background: "#536D13", color: "#F0FAB5" },
  { background: "#AA3E19", color: "#FAD4BC" },
];

export default assigneesColors;