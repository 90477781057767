import React from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { addToast } from "../../redux/toastSlice";
import { ORDER_TYPE_ENUM, PAYMENT_STATUS_ENUM, POS_NEW_ORDER_STEPS } from "../../constants";
import { calculateTotal } from "../../utils";
import { useSelector } from "react-redux";
import { selectCurrentStore } from "../../redux/storeSlice";
import { updateRequest } from "../../api";
import { posCreateOrder } from "../../api/pos";
import CartBadge from "../POS/NewOrder/CartBadge";
import { selectCurrentCart, updateCart } from "../../redux/cartSlice";
import { updateCurrentStep } from "../../redux/currentStepSlice";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const currentStore = useSelector(selectCurrentStore);
  const cart = useSelector(selectCurrentCart);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    try {
      const { paymentIntent, error } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

      if (error) dispatch(addToast(error?.message));
      if (paymentIntent?.status === "succeeded") {
        posCreateOrder(ORDER_TYPE_ENUM.in_store, cart, currentStore, dispatch);
      }
    } catch (err) {
      dispatch(addToast(err));
    }
  };

  const handleBack = () => {
    dispatch(updateCart({ ...cart, paymentMethod: null }));
    dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.PAYMENT_METHOD));
  };

  return (
    <React.Fragment>
      <div className="flex flex-col w-full h-full justify-between">
        <div className="flex justify-between items-center my-4">
          <span className="text-base lg:text-2xl lg:font-bold font-normal font-['Questrial'] leading-snug">
            Total amount due
          </span>
          <span className="text-lg font-semibold lg:text-2xl lg:font-bold font-['Montserrat'] leading-[25.20px]">
            ${calculateTotal(cart).toFixed(2)}
          </span>
        </div>

        <Divider sx={{ my: 2 }} />
        <form onSubmit={handleSubmit} className="flex flex-col h-full justify-between">
          <PaymentElement />
          <div className="flex flex-col gap-2 mt-3">
            <Button
              variant="contained"
              color="primary"
              className="w-full"
              type="submit"
              disabled={!stripe}
            >
              Pay Now
            </Button>
            <Button variant="outlinedSecondary" className="w-full" onClick={handleBack}>
              Back
            </Button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default CheckoutForm;
