import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PricingPlan from "../PricePlan";
import SetupPaymentMethod from "../stripe/SetupPaymentMethod";
import { useSelector } from "react-redux";
import { selectCurrentOrganization } from "../../redux/organizationSlice";
import { updateRequest } from "../../api";
import { useDispatch } from "react-redux";
import { addToast } from "../../redux/toastSlice";
import { setLoading } from "../../redux/loadingSlice";

export default function SubscriptionFlow({ open, setOpen }) {
  const [isPaymentMethod, setIsPaymentMethod] = useState(false);

  const dispatch = useDispatch()
  const currentOrganization = useSelector(selectCurrentOrganization);

  const handleClose = () => {
    setOpen(false);
  };

  const handlePaymentMethodAttached = async (paymentMethod) => {
    try {
      const response = await updateRequest(
        `organizations/${currentOrganization?.id}`,
        { organization: { payment_method_id: paymentMethod } }
      );

      if (response.status === 200) {
        setIsPaymentMethod(true);
        dispatch(addToast("Payment method attached"));
      }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false))
    }
  };

  useEffect(() => {
    if (currentOrganization) {
      setIsPaymentMethod(
        currentOrganization?.payment_method_id ? true : false
      );
    }
  }, [currentOrganization]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: "20px",
          right: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      {isPaymentMethod ? (
        <PricingPlan organization={currentOrganization} setOpen={setOpen} />
      ) : (
        <SetupPaymentMethod
          stripeCustomerID={currentOrganization?.cust_id}
          onPaymentMethodAttached={handlePaymentMethodAttached}
        />
      )}
    </Dialog>
  );
}
