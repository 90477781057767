import { useMediaQuery } from "@mui/material";
import MenuOptionButton from "../../../Button/MenuOptionButton";
import EditableProductPrice from "./EditableProductPrice";
import { POS_NEW_ORDER_CONFIRM_QUOTE } from "../../../../constants/FrontendRoutes";
import { POS_NEW_ORDER_STEPS } from "../../../../constants";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectCurrentCart, updateCart } from "../../../../redux/cartSlice";
import { selectCurrentStep, updateCurrentStep } from "../../../../redux/currentStepSlice";

const ProductItem = ({
  item,
  setReplica,
  editingId,
  setEditingId,
  setEditMode,
}) => {
  const dispatch = useDispatch();
  const cart = useSelector(selectCurrentCart);
  const currentStep = useSelector(selectCurrentStep);

  const removeProductFromCart = (uniqStr) => {
    dispatch(
      updateCart({
        ...cart,
        productLineItems: cart.productLineItems.filter(
          (productItem) => productItem.uniqStr !== uniqStr,
        ),
      }),
    );
  };

  const menuButtonConfig = [
    {
      name: "Edit",
      function: () => {
        setReplica(item);
        removeProductFromCart(item.uniqStr);

        dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.ADD_ITEM));
      },
    },
    {
      name: "Edit price",
      function: () => {
        setEditingId(item.uniqStr);
        setEditMode(false);
      },
    },
    {
      name: "Remove",
      function: () => removeProductFromCart(item.uniqStr),
    },
  ];

  return (
    <div className="mx-4">
      {item?.id && (
        <div className="flex justify-between items-center">
          <span
            className={
              currentStep === POS_NEW_ORDER_STEPS.ADD_ITEM ||
              currentStep === POS_NEW_ORDER_STEPS.ORDER_CART
                ? "w-[85%]"
                : "w-full"
            }
          >
            <div
              className="flex justify-between items-center text-[#272523] w-full"
              key={item.uniqStr}
            >
              <p className="">{item?.name}</p>
              {editingId === item.uniqStr ? (
                <EditableProductPrice
                  value={item?.price}
                  onSave={(newPrice) => {
                    const updatedProductLineItems = cart.productLineItems.map((product) =>
                      product.uniqStr === item?.uniqStr ? { ...product, price: newPrice } : product,
                    );

                    dispatch(
                      updateCart({
                        ...cart,
                        productLineItems: updatedProductLineItems,
                      }),
                    );
                  }}
                  onCancel={() => setEditingId(null)}
                />
              ) : (
                <span>${parseFloat(item?.price).toFixed(2)}</span>
              )}
            </div>
          </span>
          {(currentStep === POS_NEW_ORDER_STEPS.ADD_ITEM ||
            currentStep === POS_NEW_ORDER_STEPS.ORDER_CART) && (
            <MenuOptionButton data={menuButtonConfig} />
          )}
        </div>
      )}
      {!!item?.description && <h3 className="text-xs text-[#5D5C5A] mb-1">{item?.description}</h3>}

      {!!item?.quantity && (
        <div className="text-[#5d5c5a] text-xs font-normal font-['Questrial'] leading-none">
          Quantity: {item?.quantity}
        </div>
      )}
    </div>
  );
};

export default ProductItem;
