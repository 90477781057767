import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Selector from "../Form/Field/AutoComplete";
import { EMPLOYEE_ROLES_OPTIONS } from "../../constants";
import { TextField } from "@mui/material";
import { PlusCircle } from "../../common/icons";
import { validateEmail } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentUser, setCurrentUser } from "../../redux/authSlice";
import { postRequest } from "../../api";
import { addToast } from "../../redux/toastSlice";
import { setLoading } from "../../redux/loadingSlice";
import { selectCurrentStore } from "../../redux/storeSlice";

export const TEAM_MEMBER_FIELDS = {
  name: "",
  email: "",
  role: "",
};

const SetupTeam = ({ handleBack, handleNext, activeStep }) => {
  const userData = useSelector(selectCurrentUser);
  const currentStore = useSelector(selectCurrentStore);
  const dispatch = useDispatch();
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [teamMembers, setTeamMembers] = useState([TEAM_MEMBER_FIELDS]);

  const handleInputChange = (index, name, value) => {
    const updatedTeamMembers = [...teamMembers];
    updatedTeamMembers[index][name] = value;
    setTeamMembers(updatedTeamMembers);
  };

  const handleMore = () => {
    if (teamMembers.length < 4) {
      setTeamMembers([...teamMembers, { name: "", email: "", role: "" }]);
    }
  };

  const handleSkip = () => {
    setTeamMembers([]);
    handleNext();
  };

  const isAllFieldsFilled = teamMembers.every(
    (member) =>
      member.name.trim() !== "" &&
      member.email.trim() !== "" &&
      member.role &&
      validateEmail(member.email)
  );

  useEffect(() => {
    setIsNextDisabled(!isAllFieldsFilled);
  }, [teamMembers]);

  useEffect(() => {
    if (teamMembers.length === 0)
      setTeamMembers([{ email: "", name: "", phone_number: "" }]);
  }, []);

  const saveTeamData = async () => {
    try {
      dispatch(setLoading(true));
      const response = await postRequest(
        `/stores/${currentStore?.store_id}/add_team_members`,
        {
          team_members: teamMembers,
        }
      );
      dispatch(addToast("Team member added successfully!"));
      return response;
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleNextClick = async () => {
    const response = await saveTeamData();
    if (response && response.status >= 200 && response.status < 300) {
      dispatch(
        setCurrentUser({
          ...userData,
          organization: {
            ...userData.organization,
            users_count: teamMembers?.length,
          },
        })
      );
      handleNext();
    }
  };
  return (
    <div className="flex flex-col gap-3 m-8">
      {activeStep > 0 && (
        <div className="text-stone-400 text-base font-normal font-['Questrial'] leading-snug">
          Step {activeStep} / 4
        </div>
      )}
      <div className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Build your team
      </div>

      <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
        We will send your team members invites through email.
      </p>

      {teamMembers.map((member, index) => (
        <div key={index} className="flex flex-col gap-4">
          <div className="flex flex-col gap-2 w-full">
            <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              Employee’s Name
            </p>
            <TextField
              placeholder="Jane Doe"
              variant="outlined"
              value={member?.name}
              onChange={(e) => handleInputChange(index, "name", e.target.value)}
            />
          </div>

          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex flex-col gap-2 w-full">
              <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Employee’s Email
              </p>
              <TextField
                placeholder="example@gmail.com"
                variant="outlined"
                value={member?.email}
                onChange={(e) =>
                  handleInputChange(index, "email", e.target.value)
                }
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Employee’s role
              </p>
              <Selector
                name="state"
                label="Please select"
                size="large"
                options={EMPLOYEE_ROLES_OPTIONS}
                value={EMPLOYEE_ROLES_OPTIONS.find(
                  (role) => role?.value === member?.role
                )}
                onChange={(_, value) =>
                  handleInputChange(index, "role", value?.value)
                }
              />
            </div>
          </div>
        </div>
      ))}

      <Button
        variant="textPrimary"
        startIcon={
          <PlusCircle
            fill={
              !isAllFieldsFilled || userData?.organization?.users_count == 4
                ? "gray"
                : "green"
            }
          />
        }
        onClick={handleMore}
        disabled={
          !isAllFieldsFilled || userData?.organization?.users_count == 4
        }
        sx={{ width: "fit-content", marginLeft: "auto" }}
      >
        Add More
      </Button>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 ml-auto w-[90%]">
        <div className="flex items-center justify-end">
          <div className="underline cursor-pointer" onClick={handleSkip}>
            Skip for now
          </div>
        </div>
        <Button
          variant="outlinedSecondary"
          className="w-full"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="containedPrimary"
          className="w-full"
          disabled={isNextDisabled}
          onClick={handleNextClick}
        >
          Save & Next
        </Button>
      </div>
    </div>
  );
};

export default SetupTeam;
