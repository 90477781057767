import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";

const formControlOpenStyle = {
  backgroundColor: "#F1F5EB",
  borderRadius: "12px",
  color: "#4C8A2E",
  boxShadow: "none",
};

const getItemLabel = (item, isObjectArray) =>
  isObjectArray ? item.label : `# ${item}`;
const getItemId = (item, isObjectArray) => (isObjectArray ? item.id : item);

const MultiSelectDropdown = ({
  style,
  options = [],
  selectedValues = [],
  onSelectionChange,
  callbackFunction,
  searchTerm,
  onSearchChange,
  enableSearch = false,
  placeholder = "Select items",
  allItemsLabel = "All items",
  IconComponent,
  displayButton = false,
  handleButtonClick,
  fetchSelectedOnOpen,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const isObjectArray = !isEmpty(options) && typeof options[0] === "object";

  const filteredOptions = useMemo(() => {
    if (!enableSearch || !searchTerm) return options;
    return options.filter((option) =>
      `${isObjectArray ? option.label : option}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  }, [options, searchTerm, enableSearch, isObjectArray]);

  const onApply = () => {
    setIsDropdownOpen(false);
    handleButtonClick();
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    if (value.includes("")) return;

    const isSelectAll = value.includes("all");

    if (isSelectAll) {
      const newIsAllSelected = !isAllSelected;
      setIsAllSelected(newIsAllSelected);

      const selectedIds = newIsAllSelected
        ? isObjectArray
          ? options.map((obj) => obj.id)
          : options
        : [];

      onSelectionChange(selectedIds);
      callbackFunction?.(newIsAllSelected);
    } else {
      onSelectionChange(value);

      const isFullySelected = value?.length === options?.length;

      if (callbackFunction) {
        setIsAllSelected(false);
        callbackFunction(false);
      } else {
        setIsAllSelected(isFullySelected);
      }
    }
  };

  const renderValue = (selected) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {IconComponent && (
          <IconComponent sx={isDropdownOpen ? { color: "#4C8C4A" } : {}} />
        )}
        <span
          style={{
            color: isDropdownOpen ? "#4C8C4A" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "250px",
          }}
        >
          {isEmpty(selected)
            ? placeholder
            : isAllSelected
            ? allItemsLabel
            : `${selected.length} selected`}
        </span>
      </Box>
    );
  };

  const renderSearchField = () => (
    <MenuItem
      value=""
      disableRipple
      disableTouchRipple
      style={{
        background: "#FFFF",
        width: "235px",
      }}
    >
      <TextField
        style={{ borderRadius: "200px" }}
        placeholder="Search by ID"
        value={searchTerm}
        onChange={onSearchChange}
        onClick={(event) => event.stopPropagation()}
        onKeyDown={(event) => event.stopPropagation()}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          sx: {
            "& fieldset": {
              marginTop: "6px",
              borderRadius: "8px",
              height: "50px",
            },
          },
        }}
      />
    </MenuItem>
  );

  useEffect(() => {
    setIsAllSelected(selectedValues?.length === options?.length);
  }, [selectedValues]);

  const handleDropdown = (value) => {
    setIsDropdownOpen(value);
    fetchSelectedOnOpen?.();
  };

  return (
    <FormControl
      className="w-[150px] sm:w-[250px]"
      style={style}
      sx={isDropdownOpen ? formControlOpenStyle : {}}
    >
      <Select
        multiple
        value={selectedValues}
        onChange={handleSelectChange}
        displayEmpty
        open={isDropdownOpen}
        renderValue={renderValue}
        onOpen={() => handleDropdown(true)}
        onClose={() => handleDropdown(false)}
        sx={{
          "& .MuiSelect-select": {
            padding: "10px 16px",
            display: "flex",
            alignItems: "center",
          },
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 400,
              display: "flex",
              flexDirection: "column",
            },
          },
        }}
      >
        {enableSearch && renderSearchField()}
        {enableSearch && (
          <Box
            sx={{ marginX: "20px", height: "1px", backgroundColor: "#ddd" }}
          />
        )}
        {!isEmpty(filteredOptions) && (
          <MenuItem value="all">
            <Checkbox checked={isAllSelected} />
            <ListItemText primary={allItemsLabel} />
          </MenuItem>
        )}
        {(filteredOptions || []).map((option) => {
          const optionId = getItemId(option, isObjectArray);
          return (
            <MenuItem key={optionId} value={optionId}>
              <Checkbox checked={selectedValues.includes(optionId)} />
              <ListItemText
                primary={
                  <Typography
                    noWrap
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "250px",
                    }}
                  >
                    {getItemLabel(option, isObjectArray)}
                  </Typography>
                }
              />
            </MenuItem>
          );
        })}

        {displayButton && !isEmpty(filteredOptions) && (
          <Box
            sx={{
              padding: "8px 16px",
              backgroundColor: "#fff",
              position: "sticky",
              bottom: 0,
              zIndex: 1,
            }}
          >
            <Button
              variant="containedPrimary"
              className="w-full"
              onClick={onApply}
            >
              Apply
            </Button>
          </Box>
        )}

        {
          isEmpty(filteredOptions) &&
            <div value="" onClick={onApply} className="flex justify-center items-center h-full">
              <span className="text-center">No options found.</span>
            </div>
        }
      </Select>
    </FormControl>
  );
};

export default MultiSelectDropdown;
