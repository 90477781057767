import React, { useEffect, useState, useRef } from "react";
import EntityPage from "../components/EntityPage";
import OrderHistory from "../components/CustomerDetails/OrderHistory";
import ContactHistory from "../components/CustomerDetails/ContactHistory";
import { STORES, USERS } from "../constants/BackendRoutes";
import { getRequest } from "../api";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToast } from "../redux/toastSlice";
import { setLoading } from "../redux/loadingSlice";
import CorporateOverview from "../components/CustomerDetails/Corporate/Overview";
import SummaryInTabs from "../components/CustomerDetails/Corporate/SummaryInTabs";
import { INVOICE_HISTORY_TAB_LIST, INVOICE_STATUS } from "../constants";
import InvoiceSummary from "../components/CustomerDetails/Corporate/InvoiceSummary";
import { selectCurrentStore } from "../redux/storeSlice";

const CorporateDetails = () => {
  const [activeLink, setActiveLink] = useState("overview");
  const [corporationDetail, setCorporationDetail] = useState();

  const { id } = useParams();
  const dispatch = useDispatch();
  const currentStore = useSelector(selectCurrentStore);

  const overviewRef = useRef(null);
  const invoiceHistoryRef = useRef(null);
  const contactHistoryRef = useRef(null);

  const groupedInvoices = (invoices) => {
    const filteredInvoices = invoices?.filter((invoice) => invoice?.store?.id == currentStore?.store_id)
    return filteredInvoices?.reduce(
      (acc, invoice) => {
        const { status } = invoice;

        if ([INVOICE_STATUS.PAID].includes(status)) {
          acc.paid.push(invoice);
        } else if (
          [INVOICE_STATUS.OPEN, INVOICE_STATUS.OVERDUE].includes(status)
        ) {
          acc.unpaid.push(invoice);
        }
        acc.all.push(invoice);
        return acc;
      },
      { all: [], paid: [], unpaid: [] }
    );
  }

  const linksConfig = [
    {
      id: "overview",
      label: "Overview",
      component: CorporateOverview,
      props: {
        corporationDetail: corporationDetail,
        currentStore: currentStore?.store,
        corporateUserId: id,
      },
      ref: overviewRef,
    },
    {
      id: "invoice",
      label: "Invoices",
      component: SummaryInTabs,
      props: {
        summaryTitle: "Invoice",
        summaryTabList: INVOICE_HISTORY_TAB_LIST,
        SummaryComponent: InvoiceSummary,
        groupedItems: groupedInvoices(corporationDetail?.invoices),
      },
      ref: invoiceHistoryRef,
    },
    {
      id: "contact_history",
      label: "Contact history",
      component: ContactHistory,
      props: {
        userData: corporationDetail,
      },
      ref: contactHistoryRef,
    },
  ];

  useEffect(() => {
    if (currentStore?.store_id && id) {
      (async () => {
        try {
          dispatch(setLoading(true));
          const response = await getRequest(
            `${USERS}/${id}`,
            {},
            `addresses,contacts,orders.payment,invoices.orders,invoices.store&fields=user.id,user.name,user.phone_number,store.id,contact.name,contact.email,contact.phone_number,address.human_readable,address.address_type,address.updated_at,order.id,order.status,order.store_id,order.net_total,order.created_at,payment.status`
          );
          if (response) setCorporationDetail(response);
        } catch (error) {
          dispatch(addToast(error));
        } finally {
          dispatch(setLoading(false));
        }
      })();
    }
  }, [currentStore, id]);

  const handleScrollToSection = (sectionRef, linkId) => {
    setActiveLink(linkId);
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <EntityPage title="Customer details" breadcrumbs className="bg-[#F8F8F8]">
      <div className="flex gap-6">
        <div className="w-[240px] h-fit p-8 bg-white rounded-lg hidden lg:block">
          <div className="flex-col justify-start items-start gap-2 flex">
            {linksConfig.map((link) => (
              <button
                key={link.id}
                className={`text-base font-normal font-['Questrial'] leading-snug hover:bg-stone-100 w-full px-2 text-left ${
                  activeLink === link.id ? "text-stone-800" : "text-stone-400"
                }`}
                onClick={() => handleScrollToSection(link.ref, link.id)}
              >
                {link.label}
              </button>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-6 w-full h-[calc(100vh-40px-80px)] overflow-scroll">
          {linksConfig.map((link) => {
            const Component = link.component;
            return (
              <div key={link.id} ref={link.ref} id={link.id}>
                <Component {...link.props} />
              </div>
            );
          })}
        </div>
      </div>
    </EntityPage>
  );
};

export default CorporateDetails;
