import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { selectCurrentUser, setCurrentUser } from "../../redux/authSlice";
import { useSelector } from "react-redux";
import { Add } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import SetupPaymentMethodModal from "../Modal/SetupPaymentMethodModal";
import { postRequest } from "../../api";
import { PAYMENT_METHODS, USERS } from "../../constants/BackendRoutes";
import { addToast } from "../../redux/toastSlice";
import { setLoading } from "../../redux/loadingSlice";
import { CARD_NETWORK } from "../../constants";

const RenderCard = ({ paymentMethod }) => {
  const { brand , last4, exp_month, exp_year} = paymentMethod;
  const cardBrand = CARD_NETWORK[brand.toLowerCase()];

  return (
    <div className="flex items-center justify-start gap-4 p-5 rounded-lg border border-[#c9c8c8] ">
      {cardBrand && (
        <img src={cardBrand?.logo} className="w-8 h-auto" />
      )}
      <div>
        <div className="flex-col justify-start items-start gap-1 flex">
          <div className="text-xs font-medium font-['Work Sans']">{cardBrand?.title}</div>
          <div className="text-xs font-medium font-['Work Sans']">
            **** **** **** {last4}
          </div>
        </div>
        <div className="text-[10px] font-medium font-['Work Sans']">
          Expire on {exp_month}/{exp_year}
        </div>
      </div>
    </div>
  );
}

const BillingDetail = ({}) => {
  const userData = useSelector(selectCurrentUser);
  const [isModalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handlePaymentMethodAttached = async (paymentMethod) => {
    try {
      const response = await postRequest(
        `${USERS}/${userData?.id}${PAYMENT_METHODS}`,
        {
          payment_method_id: paymentMethod,
        }
      );

      if (response) {
        dispatch(
          setCurrentUser({
            ...userData,
            payment_methods: [...userData?.payment_methods, response?.data],
          })
        );
        dispatch(addToast('Payment method added successfully.'))
      }
    } catch (error) {
      dispatch(addToast(error))
    } finally {
      dispatch(setLoading(false));
      setModalOpen(false);
    }
  };

  return (
    <div className="flex flex-col gap-6 p-8 bg-white rounded-lg w-full">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between">
        <span className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
          Billing details
        </span>
        <Button
          variant="textSecondary"
          onClick={() => setModalOpen(true)}
          size="small"
          startIcon={<Add />}
          sx={{
            margin: 0,
            justifyContent: "start",
          }}
        >
          Add a new card
        </Button>
      </div>

      {userData?.payment_methods?.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 justify-between gap-5 w-full">
          {userData?.payment_methods?.map((card) => (
            <RenderCard key={card.id} paymentMethod={card} />
          ))}
        </div>
      ) : (
        <div className="rounded-lg border border-[#c9c8c8] flex flex-col justify-start items-start">
          <p className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug text-center w-full p-5">
            There’s no card saved on file yet, please add a new card first.
          </p>
        </div>
      )}
      <SetupPaymentMethodModal
        open={isModalOpen}
        close={() => setModalOpen(false)}
        handlePaymentMethodAttached={handlePaymentMethodAttached}
      />
    </div>
  );
};

export default BillingDetail;
