import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import MasterCardLogo from '../../images/mastercard.png';
import { addDaysToSpecificDate, daysDifferenceFromToday, formatDate } from '../../utils';
import { CARD_NETWORK, SUBSCRIPTION_STATUS } from '../../constants';
import Chip from '../Chip';
import MultipleConfirmationModal from '../Modal/MultipleConfirmationModal';
import MenuOptionButton from '../Button/MenuOptionButton';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/loadingSlice';
import { postRequest } from '../../api';
import { addToast } from '../../redux/toastSlice';
import { useSelector } from 'react-redux';
import { selectCurrentOrganization, selectSubscription } from '../../redux/organizationSlice';
import { fetchOrganizationData, fetchOrganizationSubscription } from '../../redux/organizationAction';
import { selectCurrentUser } from '../../redux/authSlice';

const removeAddOnProps = {
  title: 'Are you sure you want to remove the add-on seat?',
  description:
    'You will still be able to use the add-on seat during this billing cycle. Your additional seat will be removed on ',
};

const SettingBilling = ({ id, setOpenPlanModal }) => {
  const [subscription, setSubscription] = useState();
  const [openRemoveAddOnModal, setOpenRemoveAddOnModal] = useState(false);
  const [addOn, setAddOn] = useState();
  const currentOrganization = useSelector(selectCurrentOrganization);
  const organizationSubscription = useSelector(selectSubscription);
  const userData = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const buttonData = [
    { name: 'Remove add-on', function: () => setOpenRemoveAddOnModal(true), disabled: false },
  ];

  const handlePlanModal = () => {
    setOpenPlanModal(true);
  };

  const removeAddOnSubscription = async (count) => {
    try {
      const updatedCount = addOn?.current_quantity - count;
      dispatch(setLoading(true));
      const response = await postRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/stripe/add_ons/decrease_limit`,
        {
          organization_id: currentOrganization?.id,
          users_count: updatedCount,
        },
      );

      dispatch(addToast('Removed Add-on subscription successfully. Refresh your page'));
      setOpenRemoveAddOnModal(false);
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(fetchOrganizationSubscription({ organizationId: currentOrganization?.id }));
      dispatch(fetchOrganizationData({ organizationId: currentOrganization?.id }));
      setAddOn(organizationSubscription?.subscription?.add_on);
      dispatch(setLoading(false));
    }
  };

  const daysDifference = () => {
    return daysDifferenceFromToday(
      addDaysToSpecificDate(
        currentOrganization ? currentOrganization?.created_at : userData?.created_at,
        14,
      ),
    );
  };

  useEffect(() => {
    if (organizationSubscription) {
      setSubscription(organizationSubscription);
      setAddOn(organizationSubscription?.add_on)
    }
  }, [organizationSubscription]);

  return (
    <div className="flex flex-col gap-6 p-8 bg-white rounded-lg w-full text-stone-800">
      <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Billing
      </div>

      {subscription ? (
        <>
          <div className="flex flex-col lg:flex-row gap-8">
            <div className="rounded-lg border border-stone-300 flex-col justify-start items-start inline-flex w-full">
              <div className="p-4 rounded-tl-lg rounded-tr-lg flex justify-between items-center w-full">
                <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                  Current plan
                </div>
                {addOn && addOn?.current_quantity > 0 && (
                  <MenuOptionButton data={buttonData} />
                )}
              </div>
              <hr className="border-[0.5px] border-stone-300 w-full" />
              <div className="flex justify-between w-full px-4 pt-4 pb-2 rounded-tl-lg rounded-tr-lg">
                <div className="flex-col justify-center items-start gap-1 inline-flex">
                  <div className="text-base font-normal font-['Questrial'] leading-snug">
                    Plan
                  </div>
                  <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px] flex gap-2">
                    <span>{subscription?.plan?.name}</span>
                    {subscription?.status === SUBSCRIPTION_STATUS.TRIAL && (
                      <div className="px-2 py-1 bg-orange-100 rounded-2xl justify-center items-center inline-flex w-fit">
                        <div className="text-center text-orange-500 text-xs font-normal font-['Questrial'] leading-none">
                          14 days trial
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-col justify-center items-start gap-1 inline-flex">
                  <div className="text-base font-normal font-['Questrial'] leading-snug">
                    Pricing
                  </div>
                  <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                    ${subscription?.plan?.amount_in_cents / 100} /{" "}
                    {subscription?.plan?.interval}
                  </div>
                </div>
              </div>
              {addOn && (
                <React.Fragment>
                  <hr className="border-[0.5px] border-stone-300 w-full" />
                  <div className="flex justify-between w-full px-4 pt-4 pb-2 rounded-tl-lg rounded-tr-lg">
                    <div className="flex-col justify-center items-start gap-1 inline-flex">
                      <div className="text-sm font-normal font-['Questrial'] leading-snug">
                        Add-on Service
                      </div>
                      <div className="text-lg font-['Montserrat'] leading-[25.20px] flex gap-2">
                        <span>{`${subscription?.add_on?.current_quantity} Additional seat`}</span>
                      </div>
                    </div>
                    <div className="flex-col justify-end items-end gap-1 inline-flex">
                      <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                        $
                        {(subscription?.add_on?.amount_in_cents *
                          subscription?.add_on?.current_quantity) /
                          100}{" "}
                        / {subscription?.plan?.interval}
                      </div>
                    </div>
                  </div>
                  <hr className="border-[0.5px] border-stone-300 w-full" />
                </React.Fragment>
              )}
              <div className="self-stretch px-4 pt-2 pb-4 rounded-tl-lg rounded-tr-lg justify-start items-start inline-flex">
                <div className="flex-col justify-center items-start gap-1 inline-flex">
                  <div className="text-base font-normal font-['Questrial'] leading-snug">
                    Current ending date
                  </div>
                  <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                    {formatDate(subscription?.current_period_end)}
                  </div>
                </div>
              </div>
            </div>

            <div className="rounded-lg border border-stone-300 flex-col justify-start items-start inline-flex w-full">
              <div className="p-4 rounded-tl-lg rounded-tr-lg justify-start items-center inline-flex">
                <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                  Payment method
                </div>
              </div>
              <hr className="border-[0.5px] border-stone-300 w-full" />
              <div className="px-8 py-12 rounded-tl-lg rounded-tr-lg justify-start items-center inline-flex">
                <div className="grow shrink basis-0 justify-start items-center gap-6 flex">
                  <div className="flex items-center justify-start gap-4 ">
                    {CARD_NETWORK[
                      subscription?.payment_method?.brand.toLowerCase()
                    ]?.logo && (
                      <img
                        src={
                          CARD_NETWORK[
                            subscription?.payment_method?.brand.toLowerCase()
                          ]?.logo
                        }
                        className="w-8 h-auto"
                      />
                    )}
                    <div>
                      <div className="flex-col justify-start items-start gap-1 flex">
                        <div className="text-xs font-medium font-['Work Sans']">
                          {
                            CARD_NETWORK[
                              subscription?.payment_method?.brand.toLowerCase()
                            ]?.title
                          }
                        </div>
                        <div className="text-xs font-medium font-['Work Sans']">
                          **** **** **** {subscription?.payment_method?.last4}
                        </div>
                      </div>
                      <div className="text-[10px] font-medium font-['Work Sans']">
                        Expire on {subscription?.payment_method?.exp_month}/
                        {subscription?.payment_method?.exp_year}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Manage your subscription and billing information.
          </div>
          <div className="flex flex-col lg:flex-row gap-8">
            <div className="rounded-lg border border-stone-300 flex-col justify-start items-start inline-flex w-full">
              <div className="flex flex-row items-center justify-between w-full p-4 rounded-tl-lg rounded-tr-lg">
                <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                  Current plan
                </div>
                <Button
                  variant="outlined"
                  onClick={handlePlanModal}
                  sx={{
                    borderColor: "#d6d3d1",
                    color: "black",
                    "&:hover": {
                      borderColor: "#d6d3d1",
                    },
                  }}
                >
                  See our plan
                </Button>
              </div>
              <hr className="border-[0.5px] border-stone-300 w-full" />
              <div className="grid grid-cols-2 gap-4 px-4 pt-4 pb-2 w-full rounded-tl-lg rounded-tr-lg">
                <div className="flex flex-col justify-center items-start gap-1">
                  <div className="text-base font-normal font-['Questrial'] leading-snug">
                    Plan
                  </div>
                  <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px] flex gap-2">
                    <span>Free</span>
                    <div className="px-2 py-1 bg-orange-100 rounded-2xl justify-center items-center inline-flex w-fit">
                      <div className="text-center text-orange-500 text-xs font-normal font-['Questrial'] leading-none">
                        {`${daysDifference()} days left`}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-start gap-1">
                  <div className="text-base font-normal font-['Questrial'] leading-snug">
                    Expires on
                  </div>
                  <div className="text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                    {formatDate(
                      addDaysToSpecificDate(
                        currentOrganization
                          ? currentOrganization?.created_at
                          : userData?.created_at,
                        14
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <MultipleConfirmationModal
        title={removeAddOnProps?.title}
        description={removeAddOnProps?.description}
        open={openRemoveAddOnModal}
        count={addOn?.current_quantity}
        date={subscription?.current_period_end}
        onClose={() => setOpenRemoveAddOnModal(false)}
        onConfirm={removeAddOnSubscription}
      />
    </div>
  );
};

export default SettingBilling;
