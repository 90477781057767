import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import { TextField, Checkbox } from "@mui/material";
import InputMask from "react-input-mask";
import Selector from "../Form/Field/AutoComplete";
import { ADDRESS_TYPE_ENUM, COUNTRIES, STATES_PER_COUNTRY } from "../../constants";
import { validateEmail, validatePhoneNumber } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { postRequest, updateRequest } from "../../api";
import { selectCurrentUser } from "../../redux/authSlice";
import { selectCurrentStore } from "../../redux/storeSlice";
import { fetchUserData } from "../../redux/authActions";
import { addToast } from "../../redux/toastSlice";
import { setLoading } from "../../redux/loadingSlice";

export const STORE_FIELDS = {
  email: "",
  name: "",
  phone_number: "",
  line: "",
  zipcode: "",
  state: "",
  city: "",
  country: "",
};

const SetupStore = ({ handleBack, handleNext, activeStep }) => {
  const userData = useSelector(selectCurrentUser);
  const currentStore = useSelector(selectCurrentStore);
  const dispatch = useDispatch();
  const [storeData, setStoreData] = useState(STORE_FIELDS);
  const [selectedCountry, setSelectedCountry] = useState(storeData?.country || "US");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStoreData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectorChange = (field) => (_, value) => {
    handleChange({ target: { name: field, value: value?.value } });
    if (field === "country") {
      setSelectedCountry(value?.value);

      setStoreData((prevData) => ({
        ...prevData,
        line: "",
        zipcode: "",
        state: "",
        city: "",
      }));
    }
  };

  const isSubmitEnabled = () => {
    const isPhoneNumberValid = validatePhoneNumber(storeData.phone_number);
    const isEmailValid = validateEmail(storeData.email);
    const addressFields = ["line", "city", "zipcode", "state", "country"];
    const areAllAddressFieldsFilled = addressFields.every(
      (field) => storeData[field] !== ""
    );
    return (
      isEmailValid &&
      isPhoneNumberValid &&
      storeData.name !== "" &&
      areAllAddressFieldsFilled
    );
  };

  useEffect(() => {
    if (currentStore) {
      const store = currentStore?.store;
      const address = store.addresses?.[0] || {};
      setSelectedCountry(address?.country || "US");

      setStoreData((prevData) => ({
        ...prevData,
        email: store?.email || "",
        name: store?.name || "",
        phone_number: store?.phone_number || "",
        line: address?.line || "",
        city: address?.city || "",
        zipcode: address?.zipcode || "",
        state: address?.state || "",
        country: address?.country || "",
        address_id: address?.id || null,
      }));
    }
  }, [currentStore]);

  const isDataChanged = () => {
    const store = currentStore?.store || {};
    const address = store?.addresses?.[0] || {};

    return (
      store?.email !== storeData?.email ||
      store?.name !== storeData?.name ||
      store?.phone_number !== storeData?.phone_number ||
      address?.line !== storeData?.line ||
      address?.city !== storeData?.city ||
      address?.zipcode !== storeData?.zipcode ||
      address?.state !== storeData?.state ||
      address?.country !== storeData?.country
    );
  };

  const saveStoreData = async () => {
    try {
      dispatch(setLoading(true));
      const isUpdate = !!currentStore?.store_id;
      const url = isUpdate ? `/stores/${currentStore?.store_id}` : "/stores";
      const requestMethod = isUpdate ? updateRequest : postRequest;
      const response = await requestMethod(url, {
        store: {
          name: storeData?.name,
          phone_number: storeData?.phone_number,
          email: storeData?.email,
          addresses_attributes: [
            {
              id: storeData?.address_id,
              ...(isUpdate && currentStore?.store?.addresses?.[0]?.id ? { id: currentStore?.store?.addresses?.[0].id } : {}),  
              line: storeData?.line,
              zipcode: storeData?.zipcode,
              city: storeData?.city,
              state: storeData?.state,
              country: storeData?.country,
              address_type: ADDRESS_TYPE_ENUM.shop,
            },
            {
              ...(isUpdate && currentStore?.store?.addresses?.[1]?.id ? { id: currentStore?.store?.addresses?.[1].id } : {}),
              line: storeData?.line,
              zipcode: storeData?.zipcode,
              city: storeData?.city,
              state: storeData?.state,
              country: storeData?.country,
              address_type: ADDRESS_TYPE_ENUM.shipping,
            },
          ],
        },
      });
      dispatch(addToast("Store saved successfully!"));
      return response;
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const moveNextStep = async () => {
    if (!isDataChanged()) {
      handleNext();
    } else {
      const response = await saveStoreData();
      if (response && response.status >= 200 && response.status < 300) {
        dispatch(fetchUserData({ id: userData.id }));
        handleNext();
      }
    }
  };

  return (
    <div className="flex flex-col gap-3 m-8">
      {activeStep > 0 && (
        <p className="text-stone-400 text-base font-normal font-['Questrial'] leading-snug">
          Step {activeStep} / 4
        </p>
      )}
      <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Please tell us more about your Repair Store
      </p>

      <div className="flex flex-col sm:flex-row gap-4 mt-3">
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Store name *
          </p>
          <TextField
            id="companyName"
            placeholder="Please enter"
            variant="outlined"
            name="name"
            value={storeData?.name}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-4 mt-3">
      <div className="flex flex-col gap-2 w-full basis-2/6">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Country *
          </p>
          <Selector
            name="country"
            label="Please select"
            size="large"
            options={COUNTRIES}
            onChange={handleSelectorChange("country")}
            value={COUNTRIES.find(
              (country) => country?.value === storeData?.country
            )}
          />
        </div>
        <div className="flex flex-col gap-2 w-full basis-4/6">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Store address *
          </p>
          <TextField
            id="line"
            placeholder="Please enter"
            variant="outlined"
            name="line"
            value={storeData?.line}
            onChange={handleChange}
          />
        </div>

      </div>

      <div className="flex flex-col sm:flex-row gap-4 mt-3">
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            City *
          </p>
          <TextField
            id="city"
            placeholder="Please enter"
            variant="outlined"
            name="city"
            value={storeData?.city}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal text-nowrap font-['Questrial'] leading-snug">
          Province/State/Region *
          </p>
          <Selector
            name="state"
            label="Please select"
            size="large"
            options={STATES_PER_COUNTRY?.[selectedCountry] || []}
            onChange={handleSelectorChange("state")}
            value={STATES_PER_COUNTRY?.[selectedCountry]?.find(
              (state) => state?.value === storeData?.state
            )}
          />
        </div>

        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
          Zip/Postal Code *
          </p>
          <InputMask
            mask={selectedCountry === 'CA' ? 'a9a 9a9' : '99999'}
            value={storeData?.zipcode}
            onChange={handleChange}
          >
            {() => (
              <TextField
                type="text"
                name="zipcode"
                placeholder="12345"
                className="flex-1"
                variant="outlined"
              />
            )}
          </InputMask>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
          Store email *
        </p>
        <TextField
          id="contactEmail"
          placeholder="Please enter"
          variant="outlined"
          name="email"
          value={storeData?.email}
          onChange={handleChange}
        />
      </div>

      <div>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            Store number *
          </p>
          <InputMask
            mask="(999) 999-9999"
            value={storeData?.phone_number}
            onChange={handleChange}
          >
            {() => (
              <TextField
                id="phone_number"
                name="phone_number"
                type="tel"
                placeholder="(123) 456-7890"
                className="flex-1"
                variant="outlined"
              />
            )}
          </InputMask>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 mt-4 ml-auto w-[90%]">
        <div></div>
        <Button
          variant="outlinedSecondary"
          className="w-full"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="containedPrimary"
          className="w-full"
          disabled={!isSubmitEnabled()}
          onClick={moveNextStep}
        >
          Save & Next
        </Button>
      </div>
    </div>
  );
};
export default SetupStore;
