import React from "react";
import Button from "@mui/material/Button";
import SuccessFrame from "../../images/success-frame.png";
import { useSelector } from "react-redux";
import { selectCurrentUser, setCurrentUser } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { DASHBOARD } from "../../constants/FrontendRoutes";
import { updateRequest } from "../../api";
import { STORES } from "../../constants/BackendRoutes";
import { selectCurrentStore } from "../../redux/storeSlice";
import { useDispatch } from "react-redux";
import { addToast } from "../../redux/toastSlice";
import { fetchUserData } from "../../redux/authActions";
import { USER_STATUS_ENUM, USER_STATUS_MAPPER } from "../../constants";

const Success = () => {
  const navigate = useNavigate();
  const userData = useSelector(selectCurrentUser);
  const currentStore = useSelector(selectCurrentStore);

  const dispatch = useDispatch();

  const handleDashboardMove = async () => {
    try{
      const response = await updateRequest(
        `${STORES}/${currentStore?.store_id}/users/${userData?.id}`,
        {
          user: { status: USER_STATUS_ENUM.active }
        }
      );
      if (response) {
        dispatch(setCurrentUser({ ...userData, status: USER_STATUS_MAPPER.ACTIVE }))
        navigate(DASHBOARD);
      }    
    }catch(err){
      dispatch(addToast(err))
    }
  };

  return (
    <div className="flex flex-col gap-3 m-8">
      <div className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px] flex flex-col">
        <span>🎉 Congratulations!</span>
        <span>Your account has been created</span>
      </div>

      <img src={SuccessFrame} />

      <div className="flex flex-col sm:flex-row gap-5 mt-5">
        <Button
          variant="containedPrimary"
          className="w-full"
          onClick={handleDashboardMove}
        >
          Continue to dashboard
        </Button>
      </div>
    </div>
  );
};

export default Success;
