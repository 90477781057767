import { createSlice } from "@reduxjs/toolkit";

export const initialCartState = {
  type: null,
  customer: null,
  attached_customer: null,
  new_customer: false,
  serviceLineItems: [],
  productLineItems: [],
  paymentMethod: null,
  estimateCompleteDate: null,
  discount: null,
  totalDiscount: null,
  productSubTotal: 0,
  serviceSubTotal: 0,
  productSalesTaxRate: 0,
  productSalesTax: 0,
  serviceSalesTaxRate: 0,
  serviceSalesTax: 0,
  orderId: null,
  payment_id: null,
  lookupEmail: "",
  lookupPhone: "",
  isCorporateCustomer: false,
  isBillLater: false,
  rushOrder: {
    isRush: false,
    price: 0,
  },
};

const cartSlice = createSlice({
  name: "cart",
  initialState: initialCartState,
  reducers: {
    updateCart: (state, action) => {
      return action.payload;
    },
  },
});

export const { updateCart } = cartSlice.actions;
export const selectCurrentCart = (state) => state?.cart;

export default cartSlice.reducer;
