export const syncShopToShippingAddresses = (addresses) => {
  const shopAddress = addresses.find(
    (addr) => addr.address_type === "shop"
  );

  const { city, state, country, human_readable, line, zipcode } = shopAddress;
  const updatedAddresses = addresses.map((address) =>
    address.address_type === "shipping"
      ? {
          ...address,
          city,
          state,
          country,
          human_readable,
          line,
          zipcode,
        }
      : address
  );
  return updatedAddresses;
};
