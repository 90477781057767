import React from "react";

const FormPage = ({ onSubmit, children }) => {
  return (
    <div className="max-w-[640px] mt-5 mb-5">
      <form onSubmit={onSubmit}>{children}</form>
    </div>
  );
};

export default FormPage;
