import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";
import TextFieldWithAdortment from "./TextField";
import ShippingForm from "./ShippingForm";
import { BasicTextInput } from "../Form/Field/TextInput";
import Selector from "../Form/Field/AutoComplete";
import DropdownButton from "../Form/DropdownButton";
import { CrossIcon } from "../../common/icons";
import { addToast } from "../../redux/toastSlice";
import validateAddress from "../../api/addressValidator";
import { createShippingLabel, getShippingInfo } from "../../api/shipstation";
import { selectCurrentUser } from "../../redux/authSlice";
import { selectCurrentStore } from "../../redux/storeSlice";
import { selectCurrentOrganization } from "../../redux/organizationSlice";
import { setLoading } from "../../redux/loadingSlice";
import {
  ADDRESSES,
  ORDERS,
  SHIPPING_LABELS,
  STORES,
  USERS,
} from "../../constants/BackendRoutes";
import { getRequest, postRequest, updateRequest } from "../../api";
import {
  convertBase64ToPdfAndDownload,
  getLatestAddress,
  transformArrayToTitleObjects,
  validateEmail,
} from "../../utils";
import moment from "moment";
import CustomDatePicker from "../Form/Field/DatePicker";
import { useMediaQuery } from "react-responsive";
import { UPS_TRACKING_URL } from "../../constants";

const LabelModal = ({
  handleClose,
  isFinalShippingLeg = false,
  customer,
  orderId,
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const currentStore = useSelector(selectCurrentStore);
  const currentOrganization = useSelector(selectCurrentOrganization);

  const [selectedButton, setSelectedButton] = useState("mail");
  const [currentStep, setCurrentStep] = useState(1);
  const [userEmail, setUserEmail] = useState("");
  const [insurance, setInsurance] = useState(false);
  const [insuranceValue, setInsuranceValue] = useState(0);
  const [serviceRates, setServiceRates] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [isLoding, setIsLoading] = useState(false);
  const [totalShippingAmount, setTotalShippingAmount] = useState(0);
  const [shippingScheduledDate, setShippingScheduledDate] = useState(moment());
  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  useEffect(() => {
    if (currentStep !== 3) {
      handleStep(1);
    }
  }, [isMobileView]);

  const [shipFromData, setShipFromData] = useState({
    name: currentUser?.name || "",
    companyName: currentStore?.store?.name || "",
    addressLine1: currentStore?.store?.addresses?.[0]?.line || "",
    addressLine2: "",
    addressLine3: "",
    phone: currentStore?.store?.phone_number || "",
    zipCode: currentStore?.store?.addresses?.[0]?.zipcode || "",
    city: currentStore?.store?.addresses?.[0]?.city,
    state: currentStore?.store?.addresses?.[0]?.state || "",
    country: "US",
  });
  const [shipToData, setShipToData] = useState({
    name: "",
    companyName: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    zipCode: "",
    phone: "",
    city: "",
    state: "",
    country: "US",
  });
  const [packageInfo, setPackageInfo] = useState({
    lbs: 0,
    oz: 0,
    l: 0,
    w: 0,
    h: 0,
  });

  const handleStep = async (value, forward = true) => {
    if (value === 2 && forward) {
      dispatch(setLoading(true));
      const shipFromAddressStatus = await checkAddresses(shipFromData);
      if (!shipFromAddressStatus.status) {
        dispatch(addToast("Invalid ship from address or missing information."));
        dispatch(setLoading(false));
        return;
      }
      dispatch(setLoading(false));
    }
    if (value === 3) {
      dispatch(setLoading(true));
      const shipFromAddressStatus = await checkAddresses(shipFromData);
      if (!shipFromAddressStatus.status) {
        dispatch(addToast("Invalid ship from address or missing information."));
        dispatch(setLoading(false));
        return;
      }
      const shipToAddressStatus = await checkAddresses(shipToData);

      if (!shipToAddressStatus.status) {
        dispatch(addToast("Invalid ship to address or missing information."));
        dispatch(setLoading(false));
        return;
      } else {
        if (customer?.addresses?.length === 0) {
          try {
            postRequest(
              `${USERS}/${customer.id}${ADDRESSES}`,
              {
                address: {
                  zipcode: shipToData.zipCode,
                  state: shipToData.state,
                  country: shipToData.country,
                  city: shipToData.city,
                  line:
                    shipToData.addressLine1 +
                    shipToData.addressLine2 +
                    shipToData.addressLine3,
                  address_type: "customer",
                },
              }
            );
          } catch (error) {
            console.error("Error: ", error);
          }
        }
      }
      dispatch(setLoading(false));
    }
    setServiceRates([]);
    setCurrentStep(value);
  };

  const checkAddresses = async ({
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    state,
    zipCode,
    country,
  }) => {
    const address = {
      address: {
        regionCode: country,
        addressLines: [
          addressLine1,
          addressLine2,
          addressLine3,
          city,
          state,
          zipCode,
        ].filter((item) => item !== ""),
      },
    };

    const response = await validateAddress(address);
    return response;
  };

  const onChangeSelectedService = (_, value) => {
    setSelectedService(value);
    setTotalShippingAmount(value?.value);
  };

  const onChangeInsurance = (_, value) => {
    setInsurance(value);
  };

  const handleShipFromDataInput = (e) => {
    setShipFromData({ ...shipFromData, [e.target.name]: e.target.value });
  };

  const handleShipToDataInput = (e) => {
    setShipToData({ ...shipToData, [e.target.name]: e.target.value });
  };

  const handlePackageInfoInput = (key, value) => {
    setPackageInfo({ ...packageInfo, [key]: value });
  };

  const handleUserEmail = (e) => {
    setUserEmail(e.target.value);
  };

  const validateForm = () => {
    return isMobileView
      ? !(
          (currentStep === 2
            ? Object.entries(shipToData)
                .filter(
                  ([key]) =>
                    key !== "addressLine2" &&
                    key !== "addressLine3" &&
                    key !== "companyName"
                )
                .every(([, value]) => value?.trim() !== "")
            : Object.entries(shipFromData)
                .filter(
                  ([key]) =>
                    key !== "addressLine2" &&
                    key !== "addressLine3" &&
                    key !== "companyName"
                )
                .every(([, value]) => value?.trim() !== "")) &&
          (selectedButton === "email"
            ? userEmail !== "" && validateEmail(userEmail)
            : true)
        )
      : !(
          Object.entries(shipToData)
            .filter(
              ([key]) =>
                key !== "addressLine2" &&
                key !== "addressLine3" &&
                key !== "companyName"
            )
            .every(([, value]) => value?.trim() !== "") &&
          Object.entries(shipFromData)
            .filter(
              ([key]) =>
                key !== "addressLine2" &&
                key !== "addressLine3" &&
                key !== "companyName"
            )
            .every(([, value]) => value?.trim() !== "") &&
          (selectedButton === "email"
            ? userEmail !== "" && validateEmail(userEmail)
            : true)
        );
  };

  const extractServiceNames = (data) => {
    if (!data || !Array.isArray(data)) {
      return [];
    }

    return data
      .filter((obj) => obj.hasOwnProperty("serviceName"))
      .map((obj) => ({
        title: obj.serviceName,
        amount: obj.shipmentCost,
        value: obj.shipmentCost,
        code: obj.serviceCode,
      }));
  };

  const getRatesInfo = async () => {
    if (serviceRates.length !== 0 || !validatePackageinfo()) return;
    dispatch(setLoading(true));
    setIsLoading(true);
    const response = await getShippingInfo(
      shipFromData,
      shipToData,
      packageInfo,
      currentOrganization?.shipstation_token
    );

    if (response.status) {
      setServiceRates(extractServiceNames(response?.data));
    } else {
      dispatch(addToast(response?.data?.message));
    }

    setIsLoading(false);
    dispatch(setLoading(false));
  };

  const handleOrder = async (shipFrom, shipTo, legName) => {
    const response = await createShippingLabel(
      selectedService,
      packageInfo,
      shipFrom,
      shipTo,
      shippingScheduledDate,
      currentOrganization?.shipstation_token
    );
    if (response.status) {
      const {
        packageCode,
        carrierCode,
        insuranceCost,
        isReturnLabel,
        labelData,
        serviceCode,
        shipDate,
        shipmentCost,
        shipmentId,
        trackingNumber,
      } = response.data;
      const res = postRequest(
        `${STORES}/${currentStore?.store_id}${ORDERS}/${orderId}${SHIPPING_LABELS}`,
        {
          shipping_label: {
            shipping_leg: legName,
            tracking_number: trackingNumber,
            package_code: packageCode,
            carrier_code: carrierCode,
            insurance_cost: insuranceCost,
            is_return_label: isReturnLabel,
            label_data: labelData,
            service_code: serviceCode,
            ship_date: shipDate,
            shipment_cost: shipmentCost,
            shipment_id: shipmentId,
            tracking_url: UPS_TRACKING_URL + trackingNumber,
            email_to: selectedButton === "email" ? userEmail : "",
          },
        }
      );
      convertBase64ToPdfAndDownload(
        response.data.labelData,
        `${customer?.name}-${orderId}-${legName}`
      );
      handleClose();
    } else
      dispatch(addToast("Something went wrong while creating shipment label"));
  };

  const updateOrderShipmentType = async (legName) => {
    const data = { order: { shipment_type: legName } };

    await updateRequest(
      `${STORES}/${currentStore?.store_id}${ORDERS}/${orderId}`,
      data
    );
  };

  const createOrder = async () => {
    dispatch(setLoading(true));

    if (isFinalShippingLeg) {
      await handleOrder(shipFromData, shipToData, "leg_3");
    } else {
      if (selectedButton === "mail") {
        const firstLegResponse = await handleOrder(
          shipFromData,
          shipToData,
          "leg_1"
        );
        const secondLegResponse = await handleOrder(
          shipToData,
          shipFromData,
          "leg_2"
        );
        await updateOrderShipmentType("three_leg");
      } else if (selectedButton === "email") {
        const secondLegResponse = await handleOrder(
          shipFromData,
          shipToData,
          "leg_2"
        );
        await updateOrderShipmentType("two_leg");
      }
    }
    dispatch(setLoading(false));
  };

  const validatePackageinfo = () => {
    const { lbs, oz, l, w, h } = packageInfo;
    return (lbs !== 0 || oz !== 0) && l !== 0 && w !== 0 && h !== 0;
  };

  const handleMailType = (name) => {
    if (name === "mail") {
      handleShipFromData(
        currentUser?.name,
        currentStore?.store?.addresses,
        currentStore?.store?.phone_number
      );
      handleShipToData(
        customer?.name,
        customer?.addresses,
        customer?.phone_number
      );
    } else if (name === "email") {
      handleShipToData(
        currentUser?.name,
        currentStore?.store?.addresses,
        currentStore?.store?.phone_number
      );
      handleShipFromData(
        customer?.name,
        customer?.addresses,
        customer?.phone_number
      );
    }

    setSelectedButton(name);
  };

  const handleShipFromData = (name, addresses, phone = "") => {
    setShipFromData({
      name,
      companyName: "",
      addressLine1: getLatestAddress(addresses)?.line || "",
      addressLine2: "",
      addressLine3: "",
      phone,
      zipCode: getLatestAddress(addresses)?.zipcode || "",
      city: getLatestAddress(addresses)?.city || "",
      state: getLatestAddress(addresses)?.state || "",
      country: "US",
    });
  };

  const handleShipToData = (name, addresses, phone = "") => {
    setShipToData({
      name,
      companyName: "",
      addressLine1: getLatestAddress(addresses)?.line || "",
      addressLine2: "",
      addressLine3: "",
      phone,
      zipCode: getLatestAddress(addresses)?.zipcode || "",
      city: getLatestAddress(addresses)?.city || "",
      state: getLatestAddress(addresses)?.state || "",
      country: "US",
    });
  };

  const onSelectEstimationDate = (e) => {
    setShippingScheduledDate(e);
  };

  useEffect(() => {
    if (customer) {
      handleShipToData(
        customer?.name,
        customer?.addresses,
        customer?.phone_number
      );
      setUserEmail(customer?.email);
    }
  }, [customer]);

  return (
    <div
      className={`rounded-xl absolute top-1/2 left-1/2 md:h-auto h-screen max-w-[600px] overflow-auto max-h-[779px] transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg p-8`}
      style={{
        width: "calc(100% - 24px)",
        height: "calc(100vh - 96px)",
        "@media (min-width: 768px)": {
          width: "auto",
          height: "auto",
        },
      }}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-row justify-between">
          <p className="font-[Montserrat] text-2xl font-bold">Create Label</p>
          <CrossIcon className="md:block hidden" onClick={handleClose} />
        </div>
        {!isFinalShippingLeg && (
          <div className="flex flex-col gap-4">
            <p className="font-[Questrial]">
              Please select how you would like to send the pre-paid shipping
              label:
            </p>
            <div className="flex flex-row gap-2 justify-between">
              <div
                className={`flex p-4 justify-center items-center gap-4 flex-[1_0_0] rounded border ${
                  selectedButton === "mail"
                    ? "border-[#4C8C4A] bg-[#c2e9c2]"
                    : "bg-[#F7F7F7] border-[#C9C8C8]"
                } bg-opacity-10`}
                onClick={() => handleMailType("mail")}
              >
                <p
                  className={`${
                    selectedButton === "mail" ? "text-[#4C8C4A]" : ""
                  } font-questrial text-base not-italic font-normal`}
                >
                  Through Mail
                </p>
              </div>
              <div
                className={`flex p-4 justify-center items-center gap-4 flex-[1_0_0] rounded border ${
                  selectedButton === "email"
                    ? "border-[#4C8C4A] bg-[#C2E9C2]"
                    : "bg-[#F7F7F7] border-[#C9C8C8]"
                } bg-opacity-10`}
                onClick={() => handleMailType("email")}
              >
                <p
                  className={`${
                    selectedButton === "email" ? "text-[#4C8C4A]" : ""
                  } font-questrial text-base font-normal`}
                >
                  Through Email
                </p>
              </div>
            </div>
            {selectedButton === "email" && currentStep === 1 && (
              <div className="flex flex-col gap-2">
                <p className="text-[#272523] font-montserrat text-lg font-semibold">
                  Email address
                </p>
                <input
                  type="text"
                  name="email"
                  placeholder="Example@gmail.com"
                  className="px-4 py-2 border rounded-lg"
                  value={userEmail}
                  onChange={handleUserEmail}
                />
              </div>
            )}
            <Divider />
          </div>
        )}
        {currentStep === 1 ? (
          <>
            <div className="hidden md:flex flex-col md:gap-2 gap-5">
              <ShippingForm
                shippingType="Shipping from"
                data={shipFromData}
                inputHandler={handleShipFromDataInput}
              />
              <ShippingForm
                shippingType="Shipping to"
                data={shipToData}
                inputHandler={handleShipToDataInput}
              />
              <div className="flex md:flex-row flex-col-reverse justify-end gap-3">
                <button
                  className="rounded-md border border-solid border-dark-25 bg-white flex md:w-[160px] h-11 py-4 px-2 justify-center items-center"
                  onClick={handleClose}
                >
                  Cancel
                </button>

                <button
                  className="bg-green-700 text-white md:w-[160px] h-11 px-4 py-2 rounded-md disabled:bg-gray-500 disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={validateForm()}
                  onClick={() => handleStep(3)}
                >
                  Next
                </button>
              </div>
            </div>
            <div className="flex md:hidden flex-col md:gap-2 gap-5">
              <ShippingForm
                shippingType="Ship from"
                data={shipFromData}
                inputHandler={handleShipFromDataInput}
              />
              <div className="flex md:flex-row flex-col-reverse justify-end gap-3">
                <button
                  className="rounded-md border border-solid border-dark-25 bg-white flex md:w-[160px] h-11 py-4 px-2 justify-center items-center"
                  onClick={handleClose}
                >
                  Cancel
                </button>

                <button
                  className="bg-green-700 text-white md:w-[160px] h-11 px-4 py-2 rounded-md disabled:bg-gray-500 disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={validateForm()}
                  onClick={() => handleStep(2)}
                >
                  Next
                </button>
              </div>
            </div>
          </>
        ) : currentStep === 2 ? (
          <div className="flex md:hidden flex-col md:gap-2 gap-5">
            <ShippingForm
              shippingType="Ship to"
              data={shipToData}
              inputHandler={handleShipToDataInput}
            />
            <div className="flex md:flex-row flex-col-reverse justify-end gap-3">
              <button
                className="rounded-md border border-solid border-dark-25 bg-white flex h-11 py-4 px-2 justify-center items-center"
                onClick={() => handleStep(1)}
              >
                Back
              </button>

              <button
                className="bg-green-700 text-white md:w-[160px] h-11 px-4 py-2 rounded-md disabled:bg-gray-500 disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={validateForm()}
                onClick={() => handleStep(3)}
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            {selectedButton === "email" && (
              <div className="flex flex-row gap-2">
                <p className="font-questrial text-base font-normal w-[15%] justify-between">
                  Email address
                </p>
                <p className="font-questrial w-[80%] text-base font-normal">
                  {userEmail}
                </p>
              </div>
            )}
            <div className="flex flex-row gap-2">
              <p className="font-questrial text-base font-normal w-[15%] justify-between">
                Ship from:
              </p>
              <div className="flex flex-col w-[80%] justify-between">
                <p className="font-questrial text-base font-normal">
                  {shipFromData.name}
                </p>
                <p className="font-questrial text-base font-normal">
                  {shipFromData.addressLine1} {shipFromData.addressLine2}{" "}
                  {shipFromData.addressLine3} {shipFromData.state}{" "}
                  {shipFromData.country} {shipFromData.zipCode}
                </p>
              </div>
            </div>
            <div className="flex flex-row gap-2">
              <p className="font-questrial text-base font-normal w-[15%] justify-between">
                Ship to:
              </p>
              <div className="flex flex-col w-[80%] justify-between">
                <p className="font-questrial text-base font-normal">
                  {shipToData.name}
                </p>
                <p className="font-questrial text-base font-normal">
                  {shipToData.addressLine1} {shipToData.addressLine2}{" "}
                  {shipToData.addressLine3} {shipToData.state}{" "}
                  {shipToData.country} {shipToData.zipCode}
                </p>
              </div>
            </div>
            <Divider />
            <p className="text-[18px] font-[600]">Package details</p>
            <p className="text-[#272523] font-questrial text-base font-normal">
              Please specify the following information of your package:
            </p>
            <div className="flex md:flex-row flex-col gap-1">
              <div className="flex flex-col md:w-[40%]">
                <p className="font-questrial text-base font-normal">Weight</p>
                <div className="flex flex-row gap-1">
                  <TextFieldWithAdortment
                    endAdornment="lbs"
                    keyValue="lbs"
                    value={packageInfo}
                    isNumber={true}
                    handleChangeValue={handlePackageInfoInput}
                  />
                  <TextFieldWithAdortment
                    startAdornment="oz"
                    keyValue="oz"
                    value={packageInfo}
                    isNumber={true}
                    handleChangeValue={handlePackageInfoInput}
                  />
                </div>
              </div>
              <div className="flex flex-col md:w-[60%]">
                <p className="font-questrial text-base font-normal">Size</p>
                <div className="flex flex-row gap-1">
                  <TextFieldWithAdortment
                    startAdornment="L"
                    endAdornment="in"
                    value={packageInfo}
                    isNumber={true}
                    handleChangeValue={handlePackageInfoInput}
                    keyValue="l"
                  />
                  <TextFieldWithAdortment
                    startAdornment="W"
                    endAdornment="in"
                    keyValue="w"
                    value={packageInfo}
                    isNumber={true}
                    handleChangeValue={handlePackageInfoInput}
                  />
                  <TextFieldWithAdortment
                    startAdornment="H"
                    endAdornment="in"
                    keyValue="h"
                    value={packageInfo}
                    isNumber={true}
                    handleChangeValue={handlePackageInfoInput}
                  />
                </div>
              </div>
            </div>
            <div
              className={`flex flex-col gap-1 ${
                !validatePackageinfo
                  ? "cursor-not-allowed"
                  : isLoding
                  ? "cursor-wait"
                  : ""
              }`}
              onClick={() => getRatesInfo()}
            >
              <p className="font-questrial text-base font-normal">
                Service Class
              </p>
              <Selector
                label="Please Select"
                options={serviceRates}
                value={selectedService}
                loading={isLoding}
                onChange={onChangeSelectedService}
              />
            </div>
            <div className="flex flex-col gap-1">
              <p className="font-questrial text-base font-normal">Insurance</p>
              <Selector
                label="Please Select"
                value={insurance}
                onChange={onChangeInsurance}
                options={transformArrayToTitleObjects(["No", "Yes"])}
              />
            </div>

            {insurance?.title === "Yes" && (
              <div className="flex flex-col gap-1">
                <p className="font-questrial text-base font-normal">
                  Insured amount
                </p>
                <BasicTextInput
                  name="insureAmount"
                  value={insuranceValue}
                  inputHandler={(e) => setInsuranceValue(e.target.value)}
                  placeholder="Please enter"
                  classes="w-[520px]"
                />
              </div>
            )}
            <div className="flex flex-col gap-1">
              <p className="font-questrial text-base font-normal">
                Scheduled Shipping date
              </p>
              <CustomDatePicker
                value={shippingScheduledDate}
                textFieldStyles={{ width: "100%" }}
                onChange={(e) => onSelectEstimationDate(e)}
              />
            </div>
            <Divider />
            <div className="flex flex-row justify-between">
              <p>Shipping rate</p>
              {totalShippingAmount === 0 ? (
                <p>-</p>
              ) : (
                <p className="font-montserrat text-lg font-semibold">
                  {totalShippingAmount}
                </p>
              )}
            </div>
            <div className="flex max-h-[50px]  flex-row justify-end gap-3">
              <button
                className="rounded-md border border-solid border-dark-25 bg-white flex w-[160px] py-4 px-2 justify-center items-center"
                onClick={() => handleStep(1)}
              >
                Back
              </button>

              {selectedButton === "email" ? (
                <DropdownButton
                  button={{
                    text: "  Create  ",
                    returnedValue: "checkout",
                  }}
                  menuItems={[
                    {
                      text: "Create and send",
                      returnedValue: "checkout",
                    },
                    {
                      text: "Download as .pdf",
                      returnedValue: "checkout",
                    },
                  ]}
                  onClick={createOrder}
                />
              ) : (
                <button
                  className="bg-green-700 text-white md:w-[160px] h-11 px-4 py-2 rounded-md disabled:bg-gray-500 disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={!validatePackageinfo()}
                  onClick={createOrder}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LabelModal;
